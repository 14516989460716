import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 80, color: "rgba(0, 64, 255, 0.53)" }} spin />
const Spinner = (props) => {
  return (
    <div style={{ position: "absolute", left: 0, top: 0, background: "rgba(255, 255, 255, 0)", width: "100%", height: "100%" }}>
      <div className="container-fluid">
        <div className="position-fixed top-50 start-50 bottom-50 translate-middle spinnerCustom">
          <Spin indicator={antIcon} />
        </div>
      </div>
    </div>
  )
};

export default Spinner;
