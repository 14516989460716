import React from 'react';
import { MainContext } from 'src/Context';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';

class Open extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
    const { dataProps } = this.props
  // console.log(dataProps)
  this.setState({
    dataProps
  })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { dataProps } = this.state

    if (dataProps) {
      const data = dataProps.data && dataProps.data.length > 0 ? dataProps.data : []
      return(
        <span style={{ ...dataProps.styleCol }}>
          {data.map((el, i) => {
            console.log(el)
            return(
              <Card key={i} style={{ ...dataProps.styleProps }}>
                {dataProps.styleProps.header &&
                  <Card.Header style={{ ...dataProps.styleProps.header }}>
                    {dataProps.styleProps.header ? el.header : ""}
                  </Card.Header>
                }
                {dataProps.styleProps.body &&
                  <Card.Body style={{ ...dataProps.styleProps.body }}>
                    {dataProps.styleProps.body ? el.body : ""}
                  </Card.Body>
                }
                {dataProps.styleProps.footer &&
                  <Card.Footer style={{ ...dataProps.styleProps.footer }}>
                    {dataProps.styleProps.footer ? el.footer : ""}
                  </Card.Footer>
                }
              </Card>
            )
          })}
        </span>
      )
    }else { return <span>no props</span> }
  }
}

export default Open;
