import React from 'react';
import { MainContext } from 'src/Context';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
// import { Open, Planned, Ongoing, Done } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import * as comp from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import { ItemCatalog } from "src/utils/functions/filterFunction.js"

class OperationsBoardCard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount = async() => {
    this._isMounted = true;
    const { dataProps } = this.props
    const { authToken } = this.context.state
    // const tool = this.getName(operationInfo.tool._id)
    // const tool = this.getName(operationInfo.tool._id)
    const operationInfo = dataProps.param.operation
    this.setState({
      ...dataProps,
      tool: await ItemCatalog("ToolsCatalog", authToken, "param.name AS name", operationInfo.tool._id)
    })
    // console.log(await ItemCatalog("ToolsCatalog", authToken, "param.name AS name", "4b9b69aa-0da8-43c6-9d25-f92f0d633c48"))
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setData = (state) => {
    console.log(state)
  }

  getName = async(id) => {
    const { authToken } = this.context.state
    const n = await ItemCatalog("ToolsCatalog", authToken, "param.name AS name", id)
    console.log(n)
    return n
  }

  render = () => {
    const { param, _state, tool, inputValue } = this.state
    // console.log(this.state)
    if (param && param.operation) {
      const p = param
      const products = _state.products
      // console.log(products._id)
      // console.log(p.productId)
      const productInfo = products.find((el, i) => el._id === p.productId)
      // console.log(operationInfo.tool._id)
      // console.log(tool)
      // console.dir(tool);
      return(
        <Col style={{ color: "", padding: 0, borderRight: "1px solid #e7e7e7"}} md={3}>
          <center>
            <Card style={{ border: 0, cursor: "pointer" }} onClick={() => this.setData(this.state)}>
              <Card.Header style={{ border: 0 }}>
                {param.operation.operationName}
              </Card.Header>
              <Card.Body>
                <div>product info:{"\xa0"} {productInfo.product}</div>
                <div>tool:{"\xa0"} {tool.name}</div>

              </Card.Body>
            </Card>
          </center>
        </Col>
      )
    }else {
      return  <div>missing props !!!</div>
    }
  }
}

export default OperationsBoardCard;
