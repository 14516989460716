import React, { Fragment } from 'react'
import { MainContext } from 'src/Context';
import { Row, Col, Card } from 'react-bootstrap';
import { ItemCatalog } from "src/utils/functions/filterFunction.js"
import { singleRow, colorCircle } from "src/layouts/widgets/CustomLayout/index"

class BasicInfo extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }

  componentDidMount() {
    this._isMounted = true
    this.getData()
  }

  getData = async() => {
    const { dataProps } = this.state
    if (dataProps && dataProps.data && dataProps.data.length > 0) {
      this.setState({ items: dataProps.data })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { lang, dateTimeFormatter } = this.context.state
    const { product, workorder, items } = this.state
    // console.log(this.state)
    const left = { width: "50%", span: { float: "right", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%", fontWeight: "bold" }  }
    const right = { width: "50%", span: { float: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" } }
    if (items && items.length > 0) {
      return (
        <div>
          {items.map((el, i) => {
            return(
              <Fragment key={i}>
                {singleRow({ left, right, leftTitle: lang[el.label] ? lang[el.label] : el.label, rightTitle: el.value })}
              </Fragment>
            )
          })}
        </div>
      )
    }else return <></>
  }
}

export default BasicInfo;
