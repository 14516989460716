/* eslint-disable no-unused-vars */
const mainButtonStyle = {
  width: "100%",
  height: 120,
  fontSize: 35,
  border: "1px solid #e7e7e7",
  color: "#ffffff",
  float: "right",
  textAlign: "center"
}

const buttonStyle = {
  ...mainButtonStyle,
  width: 200,
  height: 100,
}

const buttonMiniStyle = {
  // ...mainButtonStyle,
  width: 150,
  height: 60,
}

const buttonTable = {
  // ...mainButtonStyle,
  // width: 60,
  // height: 60,
}

export {
  mainButtonStyle,
  buttonStyle,
  buttonMiniStyle
}

// import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';
