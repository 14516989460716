import React from 'react';
import { MainContext } from 'src/Context';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';

class Ongoing extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
    const { dataProps } = this.props
    const { querySelect, authToken } = this.context.state
    const findWo = dataProps.WorkOrder.filter((f) => f.status === dataProps.woId)
    this.setState({ dataProps: { ...dataProps, data: findWo } })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { dataProps } = this.state
    const { dateFormat, lang, dateTime } = this.context.state
    if (dataProps) {
      const data = dataProps.data && dataProps.data.length > 0 ? dataProps.data : []
      return(
        <span style={{ ...dataProps.styleCol }}>
          {data.map((el, i) => {
            // console.log(el)
            return(
              <Card key={i} style={{ ...dataProps.styleProps }}>
                {dataProps.styleProps.header &&
                  <Card.Header style={{ ...dataProps.styleProps.header }}>
                    {dataProps.styleProps.header ? (
                       <div>{el.workOrderName}</div>
                    ) : "missing - header"}
                  </Card.Header>
                }
                {dataProps.styleProps.body &&
                  <Card.Body style={{ ...dataProps.styleProps.body }}>
                    {dataProps.styleProps.body ? (
                      <Row>
                          <Col md={4}>
                            <span style={{ float: "right" }}>
                              <div>{lang["priority"] ? lang["priority"] : "priority"}:</div>
                              <div>{lang["comment"] ? lang["comment"] : "comment"}:</div>
                              <div>{lang["deadline"] ? lang["deadline"] : "deadline"}:</div>
                            </span>
                          </Col>
                          <Col md={8}>
                            <span style={{ textAlign: "left" }}>
                              <div>{el.priority}</div>
                              <div>{el.comment}</div>
                              <div>{Date.now() < el.deadline ? dateFormat(el.deadline) : <span style={{ color: "red" }}>{dateFormat(el.deadline)} ({lang["missed"] ? lang["missed"] : "missed"})</span>}</div>
                            </span>
                          </Col>
                      </Row>
                    ) : "missing - body"}
                  </Card.Body>
                }
                {dataProps.styleProps.footer &&
                  <Card.Footer style={{ ...dataProps.styleProps.footer }}>
                    {dataProps.styleProps.footer ? (
                      <div>{el.footer}</div>
                    ) : "missing - footer"}
                  </Card.Footer>
                }
              </Card>
            )
          })}
        </span>
      )
    }else { return <span>no props</span> }
  }
}

export default Ongoing;
