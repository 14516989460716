import React, { useContext, useState, useEffect, useRef } from 'react'
import { Tooltip, Image } from 'antd';
import { Card, Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
// import { formatValue } from "src/utils/functions/filterFunction"
import { Infos } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { colorCircle } from "src/layouts/widgets/CustomLayout/index"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { content } from "src/utils/functions/core"

const InfoCardMo = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang } = context.state
  // const [ result, setResult ] = useState()
  const [item, setItem] = useState(props?.dataProps ?? [])
  const { assets, clientUrl, layout } = content()
  useEffect(() => {
    return () => { mounted.current = false }
  }, [item])

  const user = props?.dataProps?._Users?.find((f) => f._id === props?.dataProps?.references?.userId?.$ref)
  const _user = user?.parameters?.firstName ? (user?.parameters?.firstName + " " + user?.parameters?.lastName) : "no assignee"
  const duration = dateTimeFormatter("duration", props?.dataProps?.parameters?.mos_duration, "dd:hh:mm")
  const startDate = dateTimeFormatter("date", props?.dataProps?.parameters?.mos_initialTimestamp, "hh:mm dd.mm.yyyy")
  const endDate = dateTimeFormatter("date", props?.dataProps?.parameters?.mos_operationEndTimestamp, "hh:mm dd.mm.yyyy")
  const type = lang[props?.dataProps?.parameters?.mos_type] ? lang[props?.dataProps?.parameters?.mos_type] : props?.dataProps?.parameters?.mos_type
  const asset = assets.find((f) => f?._id === props?.dataProps?.references?.assetDefId?.$ref)
  // const asset = assets?.find((f) => f._id === item?.references?.assetDefId?.$ref)

  const info = [
    { label: lang["assignee"] ? lang["assignee"] : "assignee", value: _user },
    { label: lang["duration"] ? lang["duration"] : "duration", value: duration },
    { label: lang["startDate"] ? lang["startDate"] : "startDate", value: startDate },
    { label: lang["endDate"] ? lang["endDate"] : "endDate", value: endDate },
    { label: lang["type"] ? lang["type"] : "type", value: type },
  ]

  return (
    <Card>
      <Card.Header style={{ borderRadius: 0 }}>
        <center>
          <Tooltip placement="left" title={item?.param?.deliveryStatus?.label}>
            <span>
              <span style={{ float: "left" }}>{props?.dataProps?.parameters?.mos_maintenanceName}</span>
              <span style={{ margin: "auto" }}>
{/*
  <Image
    preview={false}
    height={20}
    fallback={clientUrl + "/media/users/" + layout.defaultImg}
    src={clientUrl + "/media/users/" + asset?.param?.asset_image}
    />
  */}
              </span>
              <span style={{ float: "right" }}>{asset.label}</span>
            </span>
          </Tooltip>
        </center>
      </Card.Header>
      <Card.Body style={{ position: "relative" }}>
        <Row>
          <Col md={3}>
            <Image
              preview={false}
              height={60}
              fallback={clientUrl + "/media/users/" + layout.defaultImg}
              src={clientUrl + "/media/users/" + asset?.param?.asset_image}
              />
          </Col>
          <Col md={9}>
            <Infos dataProps={info} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default InfoCardMo;
