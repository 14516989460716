/* eslint-disable use-isnan */
const dateTimeFormatter = (option, number, format) => {
  let text = ""
  if (typeof number === "undefined" || number === null || number === NaN) {
    return ""
  } else {
    switch (option) {
      case "duration":
        switch (format) {
          case "hh":
            const duration0 = Math.floor(number / 3600)
            return duration0
          case "hh:mm:ss":
            const duration1 = Math.floor(number / 3600) +
              ":" +
              ("0" + (Math.floor(number / 60) % 60)).slice(-2) +
              ":" +
              ("0" + (number % 60)).slice(-2)
            return duration1
          case "dd:hh:mm:ss":
            const day = (Math.floor(number / 86400) === 0) ? ("") : (Math.floor(number / 86400) + "d ")
            const duration2 = day +
              (Math.floor(number / 3600) - (Math.floor(number / 86400) * 24)) +
              ":" +
              ("0" + (Math.floor(number / 60) % 60)).slice(-2) +
              ":" +
              ("0" + (number % 60)).slice(-2)
            return duration2
          case "hh:mm":
            const duration3 = Math.floor(number / 3600) +
              ":" +
              ("0" + (Math.round(number / 60) % 60)).slice(-2)
            return duration3
          case "dd:hh:mm":
            const day1 = (Math.floor(number / 86400) === 0) ? ("") : (Math.floor(number / 86400) + "d ")
            const duration4 = day1 +
              (Math.floor(number / 3600) - (Math.floor(number / 86400) * 24)) +
              ":" +
              ("0" + (Math.round(number / 60) % 60)).slice(-2)
            return duration4
          case "dd":
            const day5 = (Math.floor(number / 86400) === 0) ? ("") : (Math.floor(number / 86400) + "d ")
            const duration5 = day5
            return duration5
          case "mm:ss":
            const duration6 = (Math.floor(number / 60)) +
              ":" +
              ("0" + (number % 60)).slice(-2)
            return duration6
          case "dd:hhh:mmm":
            const day7 = (Math.floor(number / 86400) === 0) ? ("") : (Math.floor(number / 86400) + "d ")
            const duration7 = day7 +
              (Math.floor(number / 3600) - (Math.floor(number / 86400) * 24)) +
              "h:" +
              ("0" + (Math.round(number / 60) % 60)).slice(-2) + "m"
            return duration7
          case "mm":
            const duration8 = (Math.round(number / 60) % 60)
            return duration8
          default:
            return "Wrong format"
        }
      case "date":
        switch (format) {
          case "hh:mm":
            let time0 = (number === 0) ? "0:00" : new Date(number).toLocaleTimeString().slice(0, -3)
            // console.log(time0)
            return (time0)
          case "dd.mm.yyyy hh:mm:ss":
            let day1 = (new Date(number).getDate()).toString()
            let month1 = (new Date(number).getMonth() + 1).toString()
            let year1 = new Date(number).getFullYear().toString()
            let time1 = new Date(number).toLocaleTimeString()
            return (day1 + "." + month1 + "." + year1 + " " + time1)
          case "dd.mm.yyyy hh:mm":
            let day2 = (new Date(number).getDate()).toString()
            let month2 = (new Date(number).getMonth() + 1).toString()
            let year2 = new Date(number).getFullYear().toString()
            let time2 = new Date(number).toLocaleTimeString().slice(0, -3)
            return (day2 + "." + month2 + "." + year2 + " " + time2)
          case "hh:mm:ss dd.mm.yyyy":
            let day3 = (new Date(number).getDate()).toString()
            let month3 = (new Date(number).getMonth() + 1).toString()
            let year3 = new Date(number).getFullYear().toString()
            let time3 = new Date(number).toLocaleTimeString()
            return (time3 + " " + day3 + "." + month3 + "." + year3)
          case "hh:mm dd.mm.yyyy":
            let day4 = (new Date(number).getDate()).toString()
            let month4 = (new Date(number).getMonth() + 1).toString()
            let year4 = new Date(number).getFullYear().toString()
            let time4 = new Date(number).toLocaleTimeString().slice(0, -3)
            return (time4 + " " + day4 + "." + month4 + "." + year4)
          case "dd.mm.yyyy":
            let day5 = (new Date(number).getDate()).toString()
            let month5 = (new Date(number).getMonth() + 1).toString()
            let year5 = new Date(number).getFullYear().toString()
            return (day5 + "." + month5 + "." + year5)
          case "dd.mm hh:mm":
            let day6 = (new Date(number).getDate()).toString()
            let month6 = (new Date(number).getMonth() + 1).toString()
            let time6 = new Date(number).toLocaleTimeString().slice(0, -3)
            return (day6 + "." + month6 + " " + time6)
          case "dd.mm":
            let day7 = (new Date(number).getDate()).toString()
            let month7 = (new Date(number).getMonth() + 1).toString()
            return (day7 + "." + month7)
          case "mm.yyyy":
            let month8 = (new Date(number).getMonth() + 1).toString()
            let year8 = new Date(number).getFullYear().toString()
            return (month8 + "." + year8)
          case "mm":
            let time9 = new Date(number).toLocaleTimeString().slice(0, -3).substring(3)
            return (time9)
          case "dd.mm hh:mm:ss":
            let day10 = (new Date(number).getDate()).toString()
            let month10 = (new Date(number).getMonth() + 1).toString()
            let time10 = new Date(number).toLocaleTimeString()
            return (day10 + "." + month10 + " " + time10)
          default:
            return "Wrong format"
        }
      default:
        text = "Wrong first input"
        return text
    }
  }
}

const time = (timestamp) => {
  if (timestamp) {
    var d = new Date(timestamp);
    var s = String(d.getSeconds()).padStart(2, '0');
    var m = String(d.getMinutes()).padStart(2, '0');
    var h = String(d.getHours()).padStart(2, '0');
    return (h + ':' + m + ':' + s)
  } else {
    return "no data"
  }
}

const timeHM = (timestamp) => {
  if (timestamp) {
    var d = new Date(timestamp);
    // var s = String(d.getSeconds())
    var m = String(d.getMinutes()).padStart(2, '0');
    var h = String(d.getHours())
    return (h + ':' + m)
  } else {
    return "no data"
  }
}

const date = (timestamp) => {
  if (timestamp) {
    var d = new Date(timestamp);
    var day = String(d.getDate()).padStart(2, '0');
    var month = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = d.getFullYear();
    return (day + '.' + month + '.' + year)
  } else {
    return ""
  }
}

const dateTime = (timestamp) => {
  if (timestamp) {
    var d = new Date(timestamp);
    var day = String(d.getDate()).padStart(2, '0');
    var month = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = d.getFullYear();
    var hour = String(d.getHours()).padStart(2, '0');
    var minutes = String(d.getMinutes()).padStart(2, '0');
    var seconds = String(d.getSeconds()).padStart(2, '0');
    return (day + '.' + month + '.' + year + ' - ' + hour + ':' + minutes + ':' + seconds)
  } else {
    return ""
  }
}

const getTimestamp = (date) => {
  if (date) {
    let timestamp = Date.parse(date);
    return timestamp
  } else {
    return ""
  }
}

const fontSizeDynamic = (size) => {
  if (size) {
    let sz = size < 1 ?
      `${'calc( ' + size * 10 + 'px + 0 * ((100vw - 320px) / 680))'}` :
      `${'calc( ' + size + 'px + 6 * ((100vw - 320px) / 680))'}`
    return sz
  } else {
    return "no data"
  }
}

const months = () => {
  return [
    {
      Label: "January",
      value: "January",
      _value: 1
    },
    {
      Label: "February",
      value: "February",
      _value: 2
    },
    {
      Label: "March",
      value: "March",
      _value: 3
    },
    {
      Label: "April",
      value: "April",
      _value: 4
    },
    {
      Label: "May",
      value: "May",
      _value: 5
    },
    {
      Label: "June",
      value: "June",
      _value: 6
    },
    {
      Label: "July",
      value: "July",
      _value: 7
    },
    {
      Label: "August",
      value: "August",
      _value: 8
    },
    {
      Label: "September",
      value: "September",
      _value: 9
    },
    {
      Label: "October",
      value: "October",
      _value: 10
    },
    {
      Label: "November",
      value: "November",
      _value: 11
    },
    {
      Label: "December",
      value: "December",
      _value: 12
    }
  ]
}

const destructUrl = (url) => {
  return Object.fromEntries(new URLSearchParams(url))
}

const clipboard = (data) => {
  var temp = document.createElement("textarea");
  document.body.appendChild(temp);
  temp.value = data;
  temp.select();
  document.execCommand("copy");
  document.body.removeChild(temp);
}

const secFormat = (x) => {
  return Math.floor(x / 1000)
}

const addRemoveHoursToDate = (date, hours, s) => {
  if (s === "+") {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  } else if (s === "-") {
    return new Date(new Date(date).setHours(date.getHours() - hours));
  }
}

const minutesBetween = (date1, date2) => {
  var difference = Math.abs(date1.getTime() - date2.getTime());
  var minutesDifference = Math.floor(difference / 1000 / 60);
  return minutesDifference;
}

const hoursBetween = (date1, date2) => {
  var difference = Math.abs(date1.getTime() - date2.getTime());
  var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  return hoursDifference;
}

const daysBetween = (date1, date2) => {
  var difference = Math.abs(date1.getTime() - date2.getTime());
  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  return daysDifference;
}

const weeksBetween = (date1, date2) => {
  var difference = Math.abs(date1.getTime() - date2.getTime());
  var weeksDifference = Math.floor(difference / 1000 / 60 / 60 / 24 / 7);
  return weeksDifference;
}

const monthsBetween = (date1, date2) => {
  var months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
}

const timeStartEnd = (s, e) => {
  const start = new Date(s);
  const end = new Date(e);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  return {
    start,
    end,
    hoursBetween: hoursBetween(start, end),
    rangeTime: [getTimestamp(start), getTimestamp(end)]
  };
}

const dayStartEnd = (inputDate) => {
  const date = new Date(inputDate);
  const start = new Date(date);
  const end = new Date(date);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  return {
    start,
    end,
    hoursBetween: hoursBetween(start, end),
    rangeTime: [getTimestamp(start), getTimestamp(end)]
  };
}

const weekStartEnd = (inputDate) => {
  const _start = dayStartEnd(inputDate)?.start
  const date = new Date(_start);
  const dayOfWeek = date.getDay();
  const start = new Date(date);
  start.setDate(date.getDate() - dayOfWeek);
  const end = new Date(start);
  end.setDate(start.getDate() + 6);
  return {
    start,
    end,
    hoursBetween: hoursBetween(start, end),
    daysBetween: daysBetween(start, end),
    rangeTime: [getTimestamp(start), getTimestamp(end)]
  };
}

const monthStartEnd = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const start = new Date(year, month, 1);
  const nextMonth = new Date(year, month + 1, 1);
  const end = new Date(nextMonth - 1);
  return {
    start,
    end,
    hoursBetween: hoursBetween(start, end),
    daysBetween: daysBetween(start, end),
    weeksBetween: weeksBetween(start, end),
    rangeTime: [getTimestamp(start), getTimestamp(end)]
  };
}

const yearStartEnd = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const start = new Date(year, 0, 1);
  const end = new Date(year, 11, 31);
  return {
    start,
    end,
    hoursBetween: hoursBetween(start, end),
    daysBetween: daysBetween(start, end),
    weeksBetween: weeksBetween(start, end),
    monthsBetween: monthsBetween(start, end),
    rangeTime: [getTimestamp(start), getTimestamp(end)]
  };
}

const customStartEnd = (s, e) => {
  const start = new Date(s);
  const end = new Date(e);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);
  return {
    start,
    end,
    hoursBetween: hoursBetween(start, end),
    daysBetween: daysBetween(start, end),
    weeksBetween: weeksBetween(start, end),
    monthsBetween: monthsBetween(start, end),
    rangeTime: [getTimestamp(start), getTimestamp(end)]
  };
}

module.exports = { addRemoveHoursToDate, minutesBetween, hoursBetween, timeStartEnd, dayStartEnd, weekStartEnd, monthStartEnd, yearStartEnd, customStartEnd, time, date, dateTime, getTimestamp, fontSizeDynamic, months, destructUrl, clipboard, secFormat, dateTimeFormatter, timeHM }
