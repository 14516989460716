/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
// import { Row, Col } from 'react-bootstrap';
import { Table, Button, Popconfirm } from 'antd';
import { MainContext } from 'src/Context';
import { content } from "src/utils/functions/core"
// import MaterialModal from "./materialModal"
import { pagination } from "src/utils/functions/specStyle"
// import { sortNumAlpObj } from "src/utils/functions/filterFunction"
// import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { allinPut, msg, allinOne, openMessageV2, sortNumAlpObj } from "src/utils/functions/filterFunction"
import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';
// import { dateTimeFormatter } from "src/utils/functions/formater"
// import { workProgress } from "src/layouts/widgets/CustomLayout/index"
import { prepareColumns } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"

const App = (props) => {
  // console.log(props)
  const { auth_token } = content()
  const mounted = useRef(true);
  const context = useContext(MainContext);
  // const requiredMaterials = props?.item?.param?.requiredMaterials.map((el, i) => ({ ...el, key: i + 1 }))
  const [item, setItem] = useState(props?.item ?? [])
  const [materialModal, setMaterialModal] = useState()
  const [ material, setMaterial ] = useState(false)
  const [state, setState] = useState({
    dataTable: [],
    columns: []
  })

  const getData = async () => {
    // console.log(props)
    // const ids = props?.item?.param?.requiredMaterials.map((el, i) => (el?.warehouseCode))
    const id = props?.item?._id
    if (mounted.current) {
      const result = await allinOne("Operation006", { id, type: "Operation" })
      // console.log(result)
      if (result?.success) {
        return result?.r
      }
    }
  }

  const { lang } = context.state
  const { style } = context

  const removeMaterial = (e) => {
    setMaterial(e)
  }

  const cancel = (e) => {
    console.log(e)
  }

  useEffect(() => {
    if (material) {
      // const newDataTable = state?.dataTable.filter((f) => f?.warehouseCode !== material?.warehouseCode)
      const _newDataTable = state?.dataTable.map((el) => {
        if (el?.warehouseCode === material?.warehouseCode) {
          return({
            ...el,
            activeStatus: true,
            archiveStatus: true
          })
        }else {
          return(el)
        }
      })
      // console.log(newDataTable)
      const newDataTable = _newDataTable.filter((f) => f?.activeStatus && !f?.archiveStatus)
      setState({ ...state, newDataTable, dataTable: newDataTable })
      saveMaterial(newDataTable)
      setMaterial(false)
    }
  }, [material])

  const f = async () => {
    const d = await getData()
    if (d && mounted.current) {
      const _requiredMaterials = d?.param?.requiredMaterials?.map((el, i) => ({ ...el, key: i + 1 }))
      const requiredMaterials = _requiredMaterials.filter((f) => f?.activeStatus && !f?.archiveStatus)
      // console.log(requiredMaterials)
      setState({
        ...state,
        operation: d,
        dataTable: requiredMaterials,
        columns: [
          {
            title: "#",
            dataIndex: 'key',
            key: 'key',
            width: 30,
            align: "center",
            render: (text, record) => <span>{text}</span>,
          },
          {
            title: lang?.["code"] ?? "code",
            dataIndex: 'warehouseCode',
            key: 'warehouseCode',
            width: 200,
            // align: "center",
            sorter: (a, b) => sortNumAlpObj(a, b, "warehouseCode"),
            render: (text, record) => <span>{text}</span>,
          },
          {
            title: lang?.["Material"] ?? "Material",
            dataIndex: 'name',
            key: 'name',
            // width: 30,
            // align: "center",
            sorter: (a, b) => sortNumAlpObj(a, b, "name"),
            render: (text, record) => <span>{text}</span>,
          },
          // {
          //   title: lang?.["status"] ?? "status",
          //   dataIndex: 'status',
          //   key: 'status',
          //   // width: 30,
          //   // align: "center",
          //   sorter: (a, b) => sortNumAlpObj(a, b, "status"),
          //   render: (text, record) => <span>{text}</span>,
          // },
          // {
          //   title: lang?.["warehouse"] ?? "warehouse",
          //   dataIndex: 'wareHouseName',
          //   key: 'wareHouseName',
          //   // width: 30,
          //   // align: "center",
          //   sorter: (a, b) => sortNumAlpObj(a, b, "wareHouseName"),
          //   render: (text, record) => {
          //     // console.log(record)
          //     return <span>{text}</span>
          //   },
          // },
          {
            title: lang?.["stock"] ?? "stock",
            dataIndex: 'quantity',
            key: 'quantity',
            // width: 30,
            // align: "center",
            sorter: (a, b) => sortNumAlpObj(a, b, "quantity"),
            render: (text, record) => <span>{text}</span>,
          },
          {
            title: lang?.["requiredQuantity"] ?? "requiredQuantity",
            dataIndex: 'requiredQuantity',
            key: 'requiredQuantity',
            width: 200,
            // align: "center",
            sorter: (a, b) => sortNumAlpObj(a, b, "requiredQuantity"),
            render: (text, record) => <span>{text}</span>,
          },
          {
            title: lang?.["materialStatus"] ?? "materialStatus",
            dataIndex: 'materialStatus',
            key: 'materialStatus',
            // width: 30,
            // align: "center",
            sorter: (a, b) => sortNumAlpObj(a, b, "materialStatus"),
            render: (text, record) => <span>{lang?.[text] ?? text}</span>,
          },
          {
            title: lang?.["workOrders"] ?? "workOrders",
            dataIndex: 'orders',
            key: 'orders',
            // width: 30,
            // align: "center",
            sorter: (a, b) => sortNumAlpObj(a, b, "orders"),
            render: (text, record) => <span>{text}</span>,
          },
          // {
          //   title: lang?.["manualAdd"] ?? "manualAdd",
          //   dataIndex: 'manualAdd',
          //   key: 'orders',
          //   // width: 30,
          //   // align: "center",
          //   sorter: (a, b) => sortNumAlpObj(a, b, "orders"),
          //   render: (text, record) => {
          //     return
          //   },
          // },
          {
            title: '#',
            dataIndex: 'action',
            render: (_, record) => {
              return(
                <Popconfirm
                  title={lang?.["confirmDelete"] ?? "confirmDelete"}
                  description=""
                  onConfirm={() => removeMaterial(record)}
                  onCancel={cancel}
                  cancelText={lang?.["btn_cancel"] ?? "btn_cancel"}
                  okText={lang?.["cofirm"] ?? "cofirm"}
                  >
                  <Button
                    type="primary"
                    danger={true}
                    ghost={false}
                    // disabled={!record?.manualAdd}
                    >
                    {lang?.["remove"] ?? "remove"}
                  </Button>
                </Popconfirm>
              )
            }
          },
        ]
      })
    }
  }


  // {
  //   "I2ORatio": 2,
  //   "name": "KB230 900X1375 - UNI PLATO LIDL W. H. (SIJAJ LAK), FSC MIX CREDIT do 1.6.2024",
  //   "requiredQuantity": 5000,
  //   "warehouseCode": "10011923",
  //   "key": 1
  // }


  const materialModalCallback = (_e) => {
    // console.log(e)
    const e = _e?.item
    // console.log({
    //   e,
    //   dataTable: state?.dataTable
    // })

    const d = state?.dataTable.map((el) => {
      return({
        warehouseCode: el?.warehouseCode,
        requiredQuantity: el?.requiredQuantity,
        name: el?.name,
        i2ORatio: el?.i2ORatio,
        takeOverQuantity: el?.takeOverQuantity,
        orders: el?.orders,
        materialStatus: el?.materialStatus,
        stockType: "material",
        manualAdd: el?.manualAdd,
        activeStatus: el?.activeStatus,
        archiveStatus: el?.archiveStatus
      })
    })
    // activeStatus
    // archiveStatus

    // console.log({
    //   _e
    // })
    if (e) {
      const obj = {
        I2ORatio: _e?.I2ORatio,
        name: e?.param?.material,
        requiredQuantity: _e?.requiredQuantity,
        warehouseCode: e?.param?.code,
        materialStatus: _e?.materialStatus,
        wareHouseName: e?.param?.wareHouseName,
        stockType: "material",
        manualAdd: true,
        activeStatus: true,
        archiveStatus: false
      }

      const newDataTable = [...d, obj].map((el, i) => ({ ...el, key: i + 1 }))
      // console.log(newDataTable)
      mounted.current = false
      setState({
        ...state,
        newDataTable,
        dataTable: newDataTable
      })
      saveMaterial(newDataTable)
    }
  }

  const addMaterial = async () => {
    const m = await import("./materialModal")
    const Component = m?.default
    let open = true
    setMaterialModal(<Component key={JSON.stringify(Date.now())} open={open} materialModalCallback={materialModalCallback} {...props} />)
  }

  const saveMaterial = async (_newDataTable) => {
    const id = props?.item?._id
    const newDataTable = _newDataTable.map((el) => {
      delete el.key
      // delete el.manualAdd
      return el
    })
    const result = await allinPut("updateRequiredMaterials", { id, requiredMaterials: newDataTable })
    if (result.success) {
      msg("success", (lang?.["saved"] ?? "saved"), 2)
    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [state?.dataTable])

  // console.log(props?.tabName)
  const cols = props?.children?.tabs?.[props?.tabName]
  // console.log(props?.item.param)
  const _param = props?.item.param
  // const _param = paramDestruct(result?.workorder?.param)
  const columns = prepareColumns(cols, _param, lang)
  const title = lang?.[props?.tabName] ?? props?.tabName
  const _item = { name: item?.param?.operation?.operationName}
  const tableProps = {
    pagination
  }
  return (
    <div style={style.OperationManipulateMo}>
      {/*
      <TitleInfo styles={props?.children?.styles?.TitleInfo} dataProps={_item} />
      <Divider />
      */}
      <div style={{ height: 140 }}>
        <span
          style={{
            display: 'inline-block',
            // width: 'calc(50% - 8px)',
            margin: '0 8px',
            float: "inline-end"
          }}>
          <Button
            style={buttonStyle}
            type="primary"
            onClick={addMaterial}>
            {lang?.["Add"] ?? "Add"}
          </Button>
        </span>
        {/*
          <span
          style={{
          display: 'inline-block',
          // width: 'calc(50% - 8px)',
          margin: '0 8px',
          float: "inline-end"
          }}>
          <Button
          style={buttonStyle}
          type="primary"
          onClick={saveMaterial}>
          {lang?.["btn_save"] ?? "btn_save"}
          </Button>
          </span>
          */}
      </div>
      <div>
        <Table
          {...tableProps}
          bordered
          style={{ padding: 0 }}
          className="table-striped-rows"
          columns={state?.columns}
          dataSource={state?.dataTable}
        // rowSelection={rowSelection}
        // pagination={pagination}
        />
      </div>
      {materialModal}
    </div>
  )
}

export default App;
// <InfoColumns styles={props?.children?.styles?.InfoColumns} key={JSON.stringify(columns)} dataProps={columns} />
