import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';

class WoDetails extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {}
  }


  componentDidMount() { this.getData() }

  getData = () => {
    const { dataProps } = this.props
    this.setState({ data: dataProps.data })
  }
  // POST -- update event data
  handleTiming = (type) => {
    const { authToken, dateTime } = this.context.state
    const { data } = this.state
    const now = Date.now()
    // TODO:
    // - save startDate to ???
    const param = {
      url: "/select_catalog",
      token: authToken,
      type: "",
      [type]: Date.now(),
      ["format_" + type]: dateTime(Date.now())
    }
    this.setState({
      param,
      buttonFill: type === "startWork" ? true : false,
      data: {
        ...data,
        ...param
      }
    })
    // console.log(param)



    // let seconds = 0
    // setInterval(() => {
    //   seconds++
    //   console.log(seconds)
    // }, 1000)
  }

  render() {
    const { lang, layout } = this.context.state;
    const { colSize } = this.props;
    const { param, data, buttonFill } = this.state
    // console.log(buttonFill)
    return (
      <Col md={colSize}>
        <Row>
          <div>
            for operation board
          </div>
        </Row>
        <Row>
          <Col md={6}>
            <center>
              <Button
                className={buttonFill ? "blinkDiv" : ""}
                ghost={buttonFill ? false : true}
                style={{ width: "40%", height: 150, fontSize: 25 }}
                type="primary"
                onClick={() => buttonFill ? this.handleTiming("endWork") : this.handleTiming("startWork")}
                danger={buttonFill ? true : false}>
                {buttonFill ? lang["stop"] ? lang["stop"] : "stop" : lang["start"] ? lang["start"] : "start"}
              </Button>
            </center>
          </Col>
          <Col md={6}>
            {/*
              <center>
              <Button
              ghost
              style={{ width: "40%", height: 150, fontSize: 25 }}
              type="primary" danger
              onClick={() => this.handleTiming("endWork")}>
              {lang["stop"] ? lang["stop"] : "stop"}
              </Button>
              </center>
              */}
          </Col>
        </Row>
        <Row style={{ minHeight: 100, padding: "10px 0px 0px 10px" }}>
          info Operation => WorkOrder
           <div>
             <pre>{JSON.stringify(param, null, 2) }</pre>
           </div>
           <div style={{ height: 100, overflowX: "scroll" }}>
             <pre>{JSON.stringify(data, null, 2) }</pre>
           </div>
        </Row>
      </Col>
    )
  }
}


export default WoDetails
