import React from 'react';
import { MainContext } from 'src/Context';

class Empty extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    return(
      <div>
        Empty
      </div>
    )
  }
}

export default Empty;
