import React from 'react';
import { MainContext } from '../../Context';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';

class SocketPage extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props)
    this.state = {
      weldingNumber_PLC: 0
    }
  }

  componentDidMount() { }

  componentDidUpdate() {
    const { userConfig, socket, socketConn, socketDisconect, sessionRoom, userIdTemp, authToken } = this.context.state;
    const { contextCall, toastify } = this.context;
    const { user } = userConfig
    if (socket && user) {
      const userId = user.userId
      socketConn.on(userId, (data) => {
        if (data.trigger) {
          if (data.disconnect) {
            toastify(data.type, data.msg + (data._msg ? data._msg : ""), {
              closeButton: false,
              position: "top-center",
              autoClose: data.expired,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setTimeout(() => {
              toast.dismiss()
              toast.clearWaitingQueue()
              localStorage.clear()
              window.location.reload()
            }, 5000);
          } else {
            // toastify(data.type, data.msg + (data._msg ? data._msg : ""), {
            //   closeButton: false,
            //   position: "top-center",
            //   autoClose: data.expired,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // })
          }
        }
      })

      // for room join
      socketConn.emit('join', { userId, userIdTemp, sessionRoom, authToken }, (error) => { if (error) { console.log(error) } })
      // wait for data from socket
      socketConn.on("socketInfo", (el) => {
        // console.log(el)
        contextCall("socketInfo", el)
        localStorage.setItem("socketInfo", JSON.stringify(el))
        // eslint-disable-next-line
        el.subscribedTopics.map((tp) => {
          // console.log(tp)
          // socketConn.on(tp.topic, (data) => {
          //   console.log([tp.topic, data])
          //   const findSpecAsset = data.data.assetId === "8a7727df-1a1b-4c74-8e98-4674c8e6c7e3" ? true : false
          //   const findAsset = user.userPermissions.assets.find(elId => elId === data.data.assetId)
          //   // 8a7727df-1a1b-4c74-8e98-4674c8e6c7e3
          //   if (data.topic === "cui_notification") {
          //     // this.notificationWarning(data)
          //     // this.getCountNotifi()
          //   }else if (data.topic === "pressData") {
          //     // this.pressData(data)
          //   }else {
          //     // if (findAsset) { contextCall(data.topic, data) }
          //   }
          //
          //   // NOTE for TESTING ONLY
          //   if (findSpecAsset) {
          //     this.pressData(data)
          //   }
          // })
        })
      })

      // socketConn.on("stateReload", (stat) => {
      //   if (stat.goTrigger) {
      //     // contextCall("hfweldingData", typeof  stat.hftesting === "undefined" ? {} :  stat.hftesting)
      //     // contextCall("hfweldingData", objhfweldingData)
      //     contextCall("userDataContextSocket", stat.data)
      //     contextCall("text", stat.data.text)
      //     contextCall("stateReload", true)
      //   }
      // })
      contextCall("socket", false)
    }

    if (socketDisconect) {
      const { userIdTemp, socketInfo } = this.context.state;
      const socketInfoObj = {
        socketId: socketInfo.socketId,
        userIdTemp,
        userId: user.userId,
        user: user.firstName
      }
      socketConn.emit("userDisconnect", socketInfoObj)
      contextCall("logOut", true)
      contextCall("userConfig", null)
      const falseTriger = ["socketDisconect", "authToken", "user"]
      falseTriger.forEach((el, i) => { contextCall(el, false) })
      toast.dismiss()
      toast.clearWaitingQueue()
      localStorage.clear()
      // TODO disable in future
      window.location.reload()
    }
  }
  render() { return (<> </>) }
}

export default withRouter(SocketPage);
