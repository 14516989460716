import React from 'react';
import { Redirect, withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';

class FooterCard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    // this.props.callback("lkhčjkhklj")
    return(
       <div onClick={() => this.props.callback("FooterCard", "value")}>
         FooterCard
       </div>
    )
  }
}

export default withRouter(FooterCard);
