/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Tabs } from 'antd';
// import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
// import { content } from "src/utils/functions/core"
import { MoCalendarView, MoBoardView } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'

const setDataAllMo = (_WorkflowCatalogPB, _MOTask) => {
  let _board = []
  _WorkflowCatalogPB.param.steps.forEach((el, i) => {
    const findStatus = _MOTask.filter((f, j) => (f?.parameters?.mos_taskStatus) === Number(el.id))
    if (findStatus) { _board.push({ ...el, items: findStatus }) } else { _board.push(el) }
  });
  return _board
}

const MaintenanceOperations = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const [result, setResult] = useState()
  const [items, setItems] = useState()
  const [trigger, setTrigger] = useState()
  const [activeKey, setActiveKey] = useState(props?.dataProps?.activeKey ?? "1")
  const [prop, setProp] = useState(props)
  const { authToken } = context.state

  const getData = async (props) => {
    // const { auth_token } = content()
    if (mounted.current) {
      const assetId = props?.dataProps?.props?._id
      // console.log(assetId)
      // const result = await allinOne("MaintenanceData", { assetId, _id: props?.children?.wofId }, authToken)
      // if (result.success) {
      //
      //   // const _result = checkPromisStatus(result)
      //   // const _Operation001 = checkPromisStatus(Operation001)
      //   // const _WorkflowCatalog = checkPromisStatus(WorkflowCatalog)
      //   // const _ToolsCatalog = checkPromisStatus(ToolsCatalog)
      //   // const _Users = checkPromisStatus(Users)
      //   // const board = setDataAllMo(result._WorkflowCatalog, result._MOTask)
      //   // const all = { ...result, board }
      //   // return all
      // }
      const [MOTask, WorkflowCatalogPB, Users] = await Promise.allSettled([
        allinOne(assetId ? "MOTask_001" : "MOTask_002", { id: assetId }, authToken),
        allinOne("WorkflowCatalogPB", { _id: props.children.wofId }, authToken),
        allinOne("Users", {}, authToken),
      ])
      if (MOTask && WorkflowCatalogPB && Users) {
        const _MOTask = checkPromisStatus(MOTask)
        const _WorkflowCatalogPB = checkPromisStatus(WorkflowCatalogPB)
        const _Users = checkPromisStatus(Users)
        const board = setDataAllMo(_WorkflowCatalogPB, _MOTask)
        const all = {
          _MOTask,
          _WorkflowCatalogPB,
          _Users,
          board
        }
        // console.log(all)
        return all
      }
    }
  }

  const previousValues = useRef({ trigger });

  useEffect(() => {
    const fetchData = async (prop) => {
      const d = await getData(prop)
      setResult(d)
      if (d) {
        setItems([
          {
            label: "Maintenance Operation",
            children: <MoBoardView key={Date.now()} callback={callback} dataProps={d} />,
            key: '1'
          },
          {
            label: "Calendar view",
            children: <MoCalendarView key={Date.now()} callback={callback} dataProps={d} />,
            key: '2'
          },
        ])
      }
    }
    fetchData(prop)

    if (previousValues.current.trigger !== trigger) {
      fetchData(prop)
    }
    const callback = (e) => {
      fetchData(prop)
    }

    return () => { mounted.current = false }
  }, [prop])
  // console.log(props)
  const onChange = (key) => {
    mounted.current = true
    props.setNewData(key)
    setActiveKey(key)
    // setResult(key)
    // setProp({
    //   ...prop,
    //   timestamp: Date.now()
    // })
    // setTrigger(key)
  }

  return (
    <Tabs
      // key={indexKey}
      size={"large"}
      activeKey={activeKey}

      onChange={onChange}
      type="card"
      items={items}
    />
  )
}

export default MaintenanceOperations;
