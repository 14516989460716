import React from 'react'
import { MainContext } from "../../Context";
import { withRouter } from "react-router-dom";
import widgets from "../../layouts/widgets"
import PlaceholderCard from '../../layouts/coreComponents/PlaceholderCard';

class ContentLayout extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    const { layoutData } = this.props
    const { contextCall } = this.context
    const { layoutDataContext } = this.context.state
    contextCall("layoutDataContext", layoutData)
    if (window.location.pathname === "/dashboard") {
      localStorage.setItem("layoutDataContext", JSON.stringify(layoutData))
      if (layoutData.widgets[0][0][0].props.showHeaderFooter) {
        contextCall("showHeaderFooter", layoutData.widgets[0][0][0].props.showHeaderFooter)
      }
    }else {
      if (localStorage.getItem("layoutDataContext")) {
        contextCall("layoutDataContext", JSON.parse(localStorage.getItem("layoutDataContext")))
        if (JSON.parse(localStorage.getItem("layoutDataContext")).widgets[0][0][0].props.showHeaderFooter) {
          contextCall("showHeaderFooter", JSON.parse(localStorage.getItem("layoutDataContext")).widgets[0][0][0].props.showHeaderFooter)
        }
      }
    }
    contextCall("pathNameActive", layoutData.pageName)
    // console.log(layoutDataContext && layoutDataContext.widgets[0][0][0].props.showHeaderFooter)
    // let showHeaderFooter = layoutDataContext ? layoutDataContext.widgets[0][0][0].props.showHeaderFooter : showHeaderFooter
  }

  render() {
    const { layoutData, history } = this.props
    return (
      <div>
        {layoutData.widgets.length !== 1
          ? (<div>
            {layoutData.widgets.map((tab, i) =>
              <div style={mainContentLayout} tab={`Tab ${i + 1}`} key={i}>
                {tab.map((row, i) => (
                  <div key={i}>
                    <div className="row">
                      {row.map((col, j) => {
                        const Component = widgets[col.component]
                        return (<Component
                          parentState={this.state}
                          history={history}
                          key={j}
                          dataProps={col.props}
                          colSize={col.size} />)
                        })}
                      </div>
                      <br />
                    </div>
                  ))}
                </div>)}
              </div>) : layoutData.widgets[0].map((row, index) => (
                <div style={mainContentLayout} key={index}>
                  <div className="row">
                    {row.map((col, j) => {
                      const Component = widgets[col.component] || PlaceholderCard
                      return (
                        <Component
                          key={j}
                          history={history}
                          children={layoutData.children}
                          dataProps={col.props}
                          colSize={col.size}
                          />
                      )
                    }
                  )}
                </div>
                <br />
              </div>
            )
          )}
        </div>
      )
    }
  }

  const mainContentLayout = {
    paddingTop:    5,
    paddingBottom: 5,
    paddingLeft:   5,
    paddingRight:  5,
  }

  export default withRouter(ContentLayout);
