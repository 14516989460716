import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
import { MultiColPb } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'

class ProductBoard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }

  componentDidMount() {
    this._isMounted = true;
    const { dataProps } = this.state
    if (dataProps.children && dataProps.children.debug) { console.log(this.state) }
    this.getData()
  }

  getData = () => {
    this._isMounted = true
    // const { querySelect, authToken } = this.context.state
    // const { children } = this.state
    // console.log(children)
    // querySelect({
    //   url: "/select_item_catalog",
    //   token: authToken,
    //   type: "WorkflowCatalog",
    //   keys: "i4c.*",
    //   id: children.wofId
    //   // keys: "_id, activeStatus, description, material, selectedAmount, amountText, code",
    //   // id: data.validationId,
    //   // extraQuery: 'AND `material`.`code` = ' + '"' + data.validationRef.code + '"'
    // }).then(res => {
    //   if (this._isMounted && res) {
    //     this.setState({
    //       steps: res.param.steps
    //     })
    //     // this.context.contextCall("spinnerCustom", false)
    //   }
    // })
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ title: "" })
  }

  render() {
    const { indexKey } = this.state
    // console.log(this.state)
    return (
      <MultiColPb key={indexKey} dataProps={{ ...this.state }} />
    )
  }
}

export default withRouter(ProductBoard);
