import React from 'react';
import { MainContext } from 'src/Context';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
// import { Open, Planned, Ongoing, Done } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import * as comp from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import { MultiCol } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import { DrawerCatalog, Test } from 'src/layouts/widgets/Companies/i4c_client/components/Drawer/layouts'

class WoBoard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  callback = (e, v) => {
    // console.log(e)
    this.setState({
      showModal: e.showModal,
      woDetails: e
    })
  }

  render () {
    const { dataProps } = this.props
    const { showModal, woDetails } = this.state
    // console.log(dataProps)
    return(
      <div style={{ padding: "5px 0px 0px 0px", width: "100%" }}>
        <Row>
          {dataProps && dataProps.boards && dataProps.boards.length > 0 &&
            dataProps.boards.map((el, i) => {
              // console.log(el)
              const Component = comp[el.comp ? el.comp : "Empty"]
              if (el.comp !== "Empty") {
                return(
                  <Col key={i} style={{ color: "", padding: 0, borderRight: "1px solid #e7e7e7"}} md={3}>
                    <center>
                      <Card style={{ border: 0 }}>
                        <Card.Header style={{ border: 0 }}>
                          {el ? el.comp : "props missing !!!"}
                        </Card.Header>
                        {/*
                          <MultiCol />
                        <Component callback={this.callback} dataProps={el.props ? el.props : false}/>
                        */}
                      </Card>
                    </center>
                  </Col>
                )
              }
            })
          }
        </Row>
        <DrawerCatalog callback={this.callback} dataProps={{ showModal: showModal, ...woDetails }}/>
        {/*
          <Test callback={this.callback} />
          */}
      </div>
    )
  }
}

export default WoBoard;
