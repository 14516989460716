import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button, Input } from 'antd';
import { Card, Col, Row  } from 'react-bootstrap';

class Search extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {}
  }


  componentDidMount() { this.getData() }

  getData = () => {}


  onChangeInput = (type, e) => {
    // console.log({[type]: e.target.value})
    // this.props.callback({ type , data: e.target.value })
    this.setState({ [type]: e.target.value })
    this.context.contextCall(type, e.target.value)
  }

  render() {
    const { lang, layout } = this.context.state;
    const { colSize } = this.props;
    const { inputValue } = this.state
    // console.log(this.props)
    return (
      <Col md={colSize}>
        <Row>
          <Col md={6}>
            <span style={{ fontSize: 30 }}>
            Please choose device
          </span>
          </Col>
          <Col md={6}>
            <Input
              value={inputValue}
              style={{ borderRadius: 0, paddingLeft: 10, height: 50, fontSize: 25 }}
              onChange={(e) => this.onChangeInput("inputSearch", e)}
              />
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Search
