import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';

class Empty extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {}
  }


  componentDidMount() { this.getData() }

  getData = () => {}
  // POST -- update event data

  render() {
    const { lang, layout } = this.context.state;
    const { colSize } = this.props;
    // console.log(this.state)
    return (
      <Col md={colSize}>
        Empty
      </Col>
    )
  }
}

export default Empty
