/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Card, Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip, Tabs, Image, Divider } from 'antd';
import { MainContext } from 'src/Context';
import { allinOne } from "src/utils/functions/filterFunction"
import { pagination } from "src/utils/functions/specStyle"
import { content } from "src/utils/functions/core"
import Spinner from "src/layouts/components/spinner"

const App = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang } = context.state
  const [state, setState] = useState(false)
  const [selectedKey, setSelectedKey] = useState("1")
  const [warehouse, renderWarehouse] = useState(<Spinner />)
  const [reservations, renderReservations] = useState(<Spinner />)
  const [searchEngine, renderSearchEngine] = useState()
  const [groupWarehouseSearchEngine, renderGroupWarehouseSearchEngine] = useState()
  const [groupLocationSearchEngine, renderGroupLocationSearchEngine] = useState()
  const [confirmButton, renderConfirmButton] = useState()
  const [filter, setFilter] = useState()
  const [showDetailsItem, renderShowDetailsItem] = useState()
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [item, setItem] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const lblWarehouse = (lang?.["stock"] ?? "stock")
  const lblReservations = (lang?.["reservations"] ?? "reservations")

  const getData = async () => {
    if (mounted.current) {
      const statusId = "eea744d7-0c70-4b92-a6a6-587008f19145" // new
      // const statusId = "a241e538-78cb-405c-a76d-aa01b67a2928" // planned
      const result = await allinOne("prepareWh003", { statusId })
      if (result.success) {
        return result
      }
    }
  }

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const warehouseCallback = async (e) => {
    setIsModalOpen(true);
    setItem(e)
  }

  const reservationsCallback = (e) => {
    console.log(e)
  }

  const confirmSearch = (e) => {
    const keys = Object.keys(state)
    let obj = {}
    let _keys = []
    keys.forEach((el, i) => {
      let s = el
      if (el.slice(0, 4) === "temp") {
        obj[el.slice(5)] = state[el]
      }
    });
    setFilter(obj)
  }

  const selectCallback = (e) => {
    if (e) {
      const materials = (e && e.length) ? state?._materials.filter((f) => f?.wareHouseName === e) : state?._materials
      setFilter({ temp_materials: materials })
    } else {
      setState({
        ...state,
        material: state?._material,
        operations: state?._operations
        // state?.[key]
      })
      setFilter(false)
    }
  }

  const modalCallback = (e) => {
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onUpdate = () => {
    setIsModalOpen(false);
    mounted.current = true
    f()
  };

  const renderComponents = async () => {
    const w = await import('./Warehouse')
    const r = await import('./Reservations')
    const sdi = await import('src/layouts/widgets/Companies/i4c_V2/components/Generic/ShowDetailsItem')
    let placeholderInput
    let placeholderSelect
    let options = []
    if (selectedKey === "1") {
      placeholderInput = (lang?.["searchMaterial"] ?? "searchMaterial")
      options = state?.groupWarehouse
    } else if (selectedKey === "2") {
      placeholderInput = (lang?.["searchOperation"] ?? "searchOperation")
      options = []
    }
    const inputProps = {
      style: {
        width: 300,
        marginBottom: 5,
        borderRadius: 0,
        marginRight: 5
      },
      placeholder: placeholderInput
    }
    const selectPropsWarehouse = {
      style: {
        width: 300,
        marginBottom: 5,
        marginRight: 5
      },
      allowClear: true,
      showSearch: true,
      placeholder: lang?.["selectGroupWarehouse"] ?? "selectGroupWarehouse",
      options
    }
    const selectPropsLocation = {
      style: {
        width: 300,
        marginBottom: 5
      },
      allowClear: true,
      showSearch: true,
      placeholder: lang?.["selectLocation"] ?? "selectLocation",
      options: []
    }
    const tableProps = {
      // style: {
      //   width: 300,
      //   marginBottom: 5
      // },
      scroll: {
        // x: 1500,
        y: (windowHeight - 320),
      },
      bordered: true,
      rowClassName: "editable-row",
      pagination
    }

    const modalProps = {
      open: isModalOpen,
      onOk: handleOk,
      onCancel: handleCancel,
      destroyOnClose: true,
      width: "auto",
      okButtonProps: {
        style: { display: 'none' }
        // disabled: true,
      },
      cancelButtonProps: {
        style: { display: 'none' }
        // disabled: true,
      },
      item
    }

    const Warehouse = w?.default
    const Reservations = r?.default
    const ShowDetailsItem = sdi?.default
    const materials = { ...props, items: state?.materials }
    const operations = { ...props, items: state?.operations, workflow: state?.workflow?.param?.steps.map((el) => (el?.id)) } // operations
    renderWarehouse(<Warehouse warehouseCallback={warehouseCallback} tableProps={tableProps} />)
    renderShowDetailsItem(<ShowDetailsItem key={JSON.stringify(modalProps)} modalCallback={modalCallback} {...modalProps} items={materials} groupWarehouse={options} onUpdate={onUpdate} />)
    renderReservations(<Reservations key={JSON.stringify(state?.operations)} reservations={reservationsCallback} {...operations} tableProps={tableProps} />)
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      const materials = d?.materials
      const operations = d?.operations
      setState({
        materials,
        _materials: materials,
        operations,
        _operations: operations,
        groupWarehouse: d?.groupWarehouse,
        workflow: d?.workflow,
        ...filter
      })
    }
  }

  const clickWH = async (el) => { }

  useEffect(() => {
    if (state) {
      renderComponents()
    }
    return () => { mounted.current = false }
  }, [state, isModalOpen, selectedKey, windowHeight])

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    if (filter) {
      mounted.current = true
      f()
    }
    return () => { mounted.current = false }
  }, [filter])

  const items = [
    {
      label: lblReservations,
      key: "1",
      children: reservations,
    },
    {
      label: lblWarehouse,
      key: "2",
      children: warehouse,
    },
  ]

  const onChange = (key) => {
    setSelectedKey(key);
    setState({
      ...state,
      materials: state?._materials,
      operations: state?._operations
    })
  };

  return (
    <div>
      <div>{searchEngine}{groupWarehouseSearchEngine}{groupLocationSearchEngine}{confirmButton}</div>
      <Tabs
        key={selectedKey}
        onChange={onChange}
        activeKey={selectedKey}
        destroyInactiveTabPane={true}
        type="card"
        items={items}
      />
      {showDetailsItem}
    </div>
  )
}

export default App;
