/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext, useEffect } from 'react'
import { MainContext } from 'src/Context';
import { allinOne } from "src/utils/functions/filterFunction"
import { content } from "src/utils/functions/core"
import { dayStartEnd, weekStartEnd, monthStartEnd, yearStartEnd } from "src/utils/functions/formater"
import { spinning } from "src/layouts/widgets/CustomLayout";
import { Bar } from '@ant-design/plots';
import { dateTimeFormatter } from "src/utils/functions/formater"
import ReactApexChart from 'react-apexcharts'

const App = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { auth_token, user, assets, layout } = content()
  const [isLoading, setIsLoading] = useState()
  const [state, setState] = useState({
    options: {},
    series: []
  })
  const { lang } = context.state
  // console.log(content())
  const get = props?.children?.fetch?.get
  const userId = user?.userId
  const type = props?.children?.fetch?.type
  const asset = props?.dataProps?.props ?? false
  const getData = async () => {
    if (mounted.current) {
      setIsLoading(true)
      const result = await allinOne(get, { userId, type, assetId: asset?._id }, auth_token)
      // console.log(result)
      // if (debug) {
      //   console.log({ result })
      // }
      if (result.success) {
        return result
      }
    }
  }

  // console.log({
  //   dayStartEnd: dayStartEnd(new Date()),
  //   weekStartEnd: weekStartEnd(new Date()),
  //   dayStartEnd: dayStartEnd(new Date()),
  //   monthStartEnd: monthStartEnd(new Date()),
  //   yearStartEnd: yearStartEnd(new Date())
  // })

  const f = async () => {
    const d = await getData()
    if (d) {
      // console.log(d)
      setState({
        ...state,
        ...d,
        series: d?.series,
        options: {
          chart: {
            height: 450,
            type: 'rangeBar',
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: false,
                selection: false,
                zoom: false, // | '<img src="/media/users/default.png" width="100" >',
                zoomin: false, // | '<img src="/media/users/default.png" width="100" >',
                zoomout: false,
                pan: false,
                reset: false, // | '<img src="/media/users/default.png" width="100">',
                customIcons: []
              },
            },
            events: {
              click: (event, chartContext, config) => {
                const a = config?.config?.series?.[0]?.data[config?.dataPointIndex]
                const asset = assets.find((f) => f?._id === a?.id)
                const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
                const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
                const p = { ...asset, sequence, redirect, assets }
                if (redirect) {
                  props?.callback(p)
                }
              }
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '80%'
            }
          },
          dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
              var label = opts.w.globals.labels[opts.dataPointIndex]
              var start = dateTimeFormatter("date", val[0], "hh:mm")
              var end = dateTimeFormatter("date", val[1], "hh:mm")
              // var diff = b.diff(a, 'days')
              return start + " - " + end
            },
            style: {
              colors: ['#f3f4f5']
            }
          },
          xaxis: {
            show: true,
            type: 'datetime',
            min: state?.start,
            max: state?.end,
            labels: {
              showDuplicates: false,
              formatter: (value) => {
                // console.log(value)
                // return "lkjlkj"
                return dateTimeFormatter("date", value, "hh:mm")
              }
            }
          },
          // xaxis: {
          //   type: 'datetime'
          // },
          stroke: {
            width: 1
          },
          fill: {
            type: 'solid',
            opacity: 0.6
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              return (dateTimeFormatter("date", w.config.series[seriesIndex].data[dataPointIndex].y[0], "hh:mm") + " - " + dateTimeFormatter("date", w.config.series[seriesIndex].data[dataPointIndex].y[1], "hh:mm"))
            }
          },
        }

      })
    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ReactApexChart options={state.options} series={state.series} type="rangeBar" height={450} />
    </div>
  )
}
export default App;
