import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
// import { Image } from 'antd';
// import * as comp from 'antd';

class Text extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }

  componentDidMount() {
    this._isMounted = true;
    this.getData()
  }

  getData = () => {
    if (this._isMounted) {
      this.setState({
        title: "text"
      })

    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ title: "" })
  }

  render() {
    // console.log(this.state)
    return (
      <div></div>
    )
  }
}

export default withRouter(Text);
