/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import { MainContext } from 'src/Context';
import { Row, Col, Card } from 'react-bootstrap';
import { Slider, List, ConfigProvider, Input } from 'antd';
import { msg, removeDuplicate } from "src/utils/functions/filterFunction"
import { dateTimeFormatter } from "src/utils/functions/formater"
import Drawer from '@mui/material/Drawer';
import InfoTable from './infoTable';

// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const reformat = (props, e) => {
  const start = props?.timestamp?.start
  const end = props?.timestamp?.end
  const f = (end - start) * (e / 100)
  // console.log(f)
  const startP = (start + f)
  return startP //dateTimeFormatter("date", startP, "hh:mm")
}

const eventByGroup = (e) => {
  let groups = []
  let events = []
  e.forEach((el, i) => {
    groups.push(el?.value?.code)
  });
  const g = removeDuplicate(groups)
  for (var i = 0; i < g.length; i++) {
    // eslint-disable-next-line no-loop-func
    const f = e.filter((f) => f?.value?.code === g[i])
    events.push({
      [g[i]]: f
    })
  }
  return { groups: g, events }
}

const getGroupsName = () => {

}

const App = (props) => {
  // console.log(props)
  const eventGroupes = eventByGroup(props?.buttons)
  const g = props?.kartemCodeGroup
  const groups = eventGroupes?.groups.map((el, i) => ({ label: g?.[el], value: el }))
  // console.log(groups)
  // const mounted = useRef(true);
  const context = useContext(MainContext);
  // const [result, setResult] = useState()
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState()

  const start = dateTimeFormatter("date", props?.timestamp?.start, "hh:mm")
  const end = dateTimeFormatter("date", props?.timestamp?.end, "hh:mm")
  const marks = {
    0: start,
    // 13: 1697602045034.07,
    // 31: 1697602404861.09,
    // 49: 1697602764688.11,
    100: {
      style: {
        color: '#f50',
      },
      label: end,
    },
  }
  const [state, setState] = useState({
    timeLinedetails: [],
    ...props,
    _buttons: props?.buttons,
    marks,
    _marks: marks,
    finish: false,
    groups
  })

  const { lang } = context.state
  const deleteMarks = (el) => {
    const _watchMarks = Object.keys(state?.marks)
    if (_watchMarks.length === 3) {
      setState({ ...state, marks, finish: false })
    } else {
      const property = _watchMarks.at((state?.procent < 100) ? -2 : -1)
      delete state.marks[property];

      if (_watchMarks && _watchMarks.length > 2) {
        const marks = state.marks
        // console.log(marks)
        if (!state?.marks?.["100"]?.label) {
          marks["100"] = { style: { color: '#f50', }, label: end }
        }
        setState({ ...state, marks, finish: false, procent: _watchMarks.at(-3) })
      } else {
        msg("warning", (lang?.["nothingToDelete"] ?? "nothingToDelete"), 2)
      }
    }
  }
  const title = (el) => {
    return (
      <span style={{ fontSize: 18, marginTop: 10 }}>
        <div>{dateTimeFormatter("date", state?.startP, "hh:mm")}</div>
        <div style={{ fontWeight: "bold" }}>{el?.name}</div>
      </span>
    )
  }

  const handleTiming = (el) => {
    if (state?.procent) {
      const marks = state.marks
      const _watchMarks = Object.keys(marks)
      const findPrevEnd = _watchMarks.filter((f) => Number(f) < state?.procent)
      const obj = findPrevEnd.pop()
      const arr = Object.keys(marks).slice(0, -1)
      const ifExist = arr.find((f) => Number(f) > state?.procent)
      if (!ifExist) {
        marks[state?.procent] = {
          style: {},
          value: [obj, state?.procent],
          // label: title(el),
          label: dateTimeFormatter("date", state?.startP, "hh:mm"),
          // _label: dateTimeFormatter("date", state?.startP, "hh:mm"),
          end: state?.startP,
          start: (state?.marks?.[obj] && state?.marks?.[obj]?.end) ?? props?.timestamp?.start,
          _start: (state?.marks?.[obj] && state?.marks?.[obj]?.end) ? dateTimeFormatter("date", state?.marks?.[obj]?.end, "hh:mm") : dateTimeFormatter("date", state?.timestamp?.start, "hh:mm"),
          _end: dateTimeFormatter("date", state?.startP, "hh:mm"),
          event: el?.value
          // label: <strong>{end}</strong>,
        }
        setState({
          ...state,
          marks,
          // _marks,
          defaultValue: state?.procent,
          finish: (state?.procent === 100),
          buttons: state?._buttons,
        })
        setOpen(false)
      } else {
        msg("warning", (lang?.["notAllowed"] ?? "notAllowed"), 2)
      }
    } else {
      msg("warning", (lang?.["noMarks"] ?? "noMarks"), 2)
    }
  }

  const handleClick = (el) => {
    switch (el?.value?.type) {
      case "close": props?.handleClick(el, false); break;
      case "deleteMarks": deleteMarks(el); break;
      case "finish": state?.finish ? handleFinish(el) : msg("warning", (lang?.["missingMarks"] ?? "missingMarks"), 1); break;
      default: handleTiming(el); break;
    }
  }

  const handleFinish = () => {
    setState({ ...state, buttons: state?._buttons })
    props?.prepareEvents({
      marks: state?.marks,
      timestamp: state?.timestamp,
    })
  }

  const handleSearch = (e) => {
    const buttons = (e && e.length > 0) ? state?._buttons.filter((el) =>
      el.name.toLowerCase().includes(e.toLowerCase()) ||
      el?.value?.code?.includes(e.toLowerCase()) ||
      el?.value?.subCode?.includes(e.toLowerCase())
    ) : state?._buttons
    setState({ ...state, buttons, defaultSelectValue: null })
  }

  const handleSelect = (e) => {
    const buttons = (e && e.length > 0) ? state?._buttons.filter((el) => el?.value?.code === e) : state?._buttons
    setState({ ...state, buttons, defaultSelectValue: e })
  }


  const _title = (item) => {
    // console.log(window.location.origin)
    return <h3>{item?.name}</h3>
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  // console.log(state?.groups)
  const _s = { width: "90%", borderRadius: 0, margin: "0px 24px 0px 24px" }
  const _i = { width: "90%", borderRadius: 0, margin: "10px 24px 10px 24px", height: 50, fontSize: 20 }
  // state.extraButtons = state?.finish ?
  // console.log(state?.extraButtons)

  // const handleClickF = (el) => {
  //   console.log(el)
  //   if (el?.value?.type === "Close") {
  //     handleClick(el)
  //   }else if (el?.value?.type === "delete") {
  //     handleClick(el)
  //   }else if (el?.value?.type === "finish") {
  //     if (state?.finish) {
  //       msg("warning", (lang?.["missingMarks"] ?? "missingMarks"), 1)
  //     }else {
  //       handleClick(el)
  //     }
  //   }
  // }

  const stickyHeader = (header, body) => {
    const keys = Object.keys(state?.marks)
    return (
      <div className="placeholder-content001">
        <section className="sticky-content">
          <Row style={{ fontSize: 20 }}>
            <Col md={4}>
              <span style={{ float: "left" }}>
                <span>{props?.asset?.asset_name}</span>
              </span>
            </Col>
            <Col md={8}>
              <span>
                <span style={{ float: "left" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {lang?.["start"] ?? "start"}:{'\xa0'}
                  </span>
                  {dateTimeFormatter("date", props?.timestamp?.start, "dd.mm.yyyy hh:mm")}
                </span>
              </span>{'\xa0'}
              <span style={{ float: "right" }}>
                <span style={{ fontWeight: "bold" }}>
                  {lang?.["end"] ?? "end"}:{'\xa0'}
                </span>
                {dateTimeFormatter("date", props?.timestamp?.end, "dd.mm.yyyy hh:mm")}
              </span>
            </Col>
          </Row>
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <ConfigProvider theme={{ ...props?.sliderProps }}>
              <Slider
                key={JSON.stringify({ ...state?.marks })}
                defaultValue={[state?.defaultValue]}
                marks={state?.marks}
                tooltip={{
                  formatter: (e) => {
                    const startP = dateTimeFormatter("date", reformat(props, e), "hh:mm")
                    return <Card style={{ width: 150, color: "#000000", margin: 0, fontSize: 25, textAlign: "center" }}>{startP}</Card>
                  },
                }}
                onAfterChange={(e) => {
                  const startP = reformat(props, e)
                  setOpen(true)
                  setState({ ...state, startP, procent: e })
                }}
                onChange={(e) => {
                  // console.log(e)
                  // const startP = reformat(props, e)
                  // setState({ ...state, startP, procent: e })
                }}
                disabled={state?.finish}
              />
            </ConfigProvider>
          </div>
        </section>
        <div className="placeholder-title">
          {/*
          {state?.finish &&
            <Card
              style={{
                background: "#00ff38",
                fontSize: 30,
                fontWeight: "bold",
                width: 300,
                padding: 10,
                border: "none",
                textAlign: "center"
              }}
              onClick={() => handleFinish()}>
              {lang?.["finish"] ?? "finish"}
            </Card>
          }

          background: (el?.value?.type === "finish" && state?.finish) ? "green" : "red"
            */}
          <Row style={{ minHeight: 100, padding: 0 }}>
            <Col md={9} style={{ padding: 0 }}>
              <InfoTable {...state?.marks} />
            </Col>
            <Col md={3}>
              {state?.extraButtons.map((el, i) => {
                // console.log([el?.props?.style, state?.finish])
                // console.log(((el?.value?.type === "finish") && state?.finish))
                const finish = ((el?.value?.type === "finish") && state?.finish)
                const _style = el?.props?.style
                const style = {
                  ..._style,
                  background: finish ? "#acf15e" : _style?.background,
                  color: finish ? "#000000" : _style?.color,
                  marginBottom: 10
                }
                // style.background = "red"//((el?.value?.type === "finish") && state?.finish) ? "#acf15e" : "#de9595"
                // console.log(style)
                return(
                   <div
                     style={style}
                     onClick={() => handleClick(el)}
                     // onClick={() => !state?.finish ? msg("warning", (lang?.["missingMarks"] ?? "missingMarks"), 1) : handleClick(el)} {...el?.props}
                     >
                     {lang?.[el.name] ?? el.name}
                   </div>
                )
              })}
            </Col>
            <Drawer
              anchor={"right"}
              open={open}
              onClose={() => {
                setOpen(!open)
                setState({ ...state, buttons: state?._buttons })
              }}
            >
              <div style={{ maxWidth: 500, margin: 5 }}>
                <Card
                  track
                  style={{
                    width: 450,
                    height: 50,
                    marginLeft: 24,
                    borderRadius: 0,
                    cursor: "pointer",
                    textAlign: "center",
                    fontSize: 25
                  }}
                  onClick={() => setOpen(!open)}>
                  {lang?.["close"] ?? "close"}
                </Card>
                <Select
                  key={JSON.stringify(state?.defaultSelectValue)}
                  style={{
                    width: 450,
                    marginLeft: 24,
                    borderRadius: 0
                  }}
                  defaultValue={state?.defaultSelectValue}
                  onOpen={() => state?.defaultSelectValue && setState({ ...state, buttons: state?._buttons, defaultSelectValue: null })}
                  onChange={(e, i) => handleSelect(i?.props?.value)}
                >
                  {state.groups.map((el, i) => {
                    return <MenuItem value={el?.value}>{el?.label}</MenuItem>
                  })}
                </Select>
                <Input style={_i} onChange={(e) => handleSearch(e.target.value)} />
                <List
                  key={JSON.stringify({ ...state?.buttons })}
                  grid={{ gutter: 10, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                  dataSource={state?.buttons}
                  // dataSource={[...state?.buttons, ...state?.buttons, ...state?.buttons]}
                  renderItem={(el) => (
                    <List.Item>
                      <Card
                        track
                        style={{ ...el?.props?.style, cursor: "pointer" }}
                        key={JSON.stringify(el?.props)}
                        onClick={() => handleClick(el)}
                      >
                        {lang?.[el.name] ?? el.name}
                      </Card>
                    </List.Item>
                  )}
                />
              </div>
            </Drawer>
          </Row>
        </div>
      </div>
    )
  }

  const _watchMarks = Object.keys(state?.marks)
  return (
    <div style={{ padding: 10 }}>
      {stickyHeader()}
    </div>
  )
}
export default App;
