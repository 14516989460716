import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
import * as comp from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'

const InfoColumns = (props) => {
  const context = useContext(MainContext);
  const [ columns, setColumns ] = useState(props?.dataProps)
  const { lang } = context.state
  const styles = props?.styles
  return (
    <Row style={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
      {columns.map((el, i) => {
        const Component = comp[comp[el?.comp] ? el?.comp : "Empty"]
        return (
          <Col key={i} style={{ padding: 0, overflow: "hidden", textOverflow: "ellipsis", marginRight: 5, minWidth: 400 }}>
            <Component key={i} dataProps={el?.info} />
          </Col>
        )
      })}
    </Row>
  )
}

export default InfoColumns;
