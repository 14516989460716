/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react'
import { Form, InputNumber, Button, ConfigProvider, Divider, Checkbox } from 'antd';
import { MainContext } from 'src/Context';
// import { Row, Col } from 'react-bootstrap';
import { allinPut, msg, allinOne } from "src/utils/functions/filterFunction"
import { content } from "src/utils/functions/core"
import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
// import { dateTimeFormatter } from "src/utils/functions/formater"
import { prepareColumns } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"
// import FeedbackTable from "./FeedbackTable"
import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';
import Spinner from "src/layouts/components/spinner"
// const { TextArea } = Input;

const App = (props) => {
  // console.log(props)
  const context = useContext(MainContext);
  const { lang } = context.state
  const { style } = context
  const [children, setChildren] = useState(<Spinner />)

  // const [requiredMaterials, setRequiredMaterials] = useState(props?.item?.param?.requiredMaterials)
  // const [_requiredMaterials, _setRequiredMaterials] = useState(props?.item?.param?.requiredMaterials)
  const [requiredMaterials, setRequiredMaterials] = useState([])
  const [_requiredMaterials, _setRequiredMaterials] = useState([])
  const [userInputMaterials, setUserInputMaterials] = useState(props?.item?.param?.userInputMaterials)
  const [operation, setOperation] = useState()
  const operationId = props?.item?._id
  const [state, setState] = useState()

  const labelGoodItems = "goodItems"
  const labelBadItems = "badItems"
  const quickNotes = "quickNotes"
  // const amountOfMaterial = "amountOfMaterial"
  // const materialUsed = "materialUsed"

  const p = operation?.param

  // console.log( props)
  const onFinish = async (values) => {
    // "PI"-OrderNo-OpNo-OperationName
    const timestampNow = Date.now()
    const OrderNo = operation?.param?.erp_OrderNo
    const OpNo = operation?.param?.erp_opc_OpNo
    const OperationName = operation?.param?.operation?.operationName
// console.log(merged)
    const _req = _requiredMaterials.map((el) => ({
      consumption: el?.consumption,
      damaged: el?.damaged,
      i2ORatio: el?.i2ORatio,
      materialStatus: el?.materialStatus,
      name: el?.name,
      orders: el?.orders,
      requiredQuantity: el?.requiredQuantity,
      takeOverQuantity: el?.takeOverQuantity,
      warehouseCode: el?.warehouseCode,
    }))


    // console.log(_req)
    const _v = {
      ...values,
      timestampNow,
      ReportTimestamp: timestampNow,
      requiredMaterials: _req,
      tempPI: "PI-" + OrderNo + "-" + OpNo + "-" + OperationName
    }
    // console.log(values)
    const updatedOperation = {
      ...operation,
      param: _v
    }
    // console.log(_param)
    const id = operationId
    setOperation(updatedOperation)
    const result = await allinPut("updateItemsOperation", { id, ..._v })
    // console.log(result)
    if (result.success) {
      // props.item.param = _param
      props?.operationN({ activeKey: "2", props })
      msg("success", (lang?.["saved"] ?? "saved"), 2)
    }
  };

  const getData = async () => {
    // console.log(props)
    const id = operationId
    const ids = operation?.param?.requiredMaterials.map((el, i) => (el?.warehouseCode))
    const result = await allinOne("prepareFeedbackTab", { ids, id })
    if (result?.success) {
      return result
    }
  }

  const tableValues = async (e) => {
    _setRequiredMaterials(e)
  }

  const f = async () => {
    const c = await import("./FeedbackTable")
    const d = await getData()
    if (d) {
      // console.log(d)
      const Component = c?.default
      const _props = {
        ...props,
        ...d
      }
      setState({
        validate: true,
        initialValues: {
          goodItems: d?.operation?.param?.goodItems ?? 0,
          badItems: d?.operation?.param?.badItems ?? 0,
          quickNotes: d?.operation?.param?.quickNotes ?? "",
          materialUsed: d?.operation?.param?.materialUsed ?? 0,
          PartiallyFinished: d?.operation?.param?.PartiallyFinished
        }
      })
      const rm = d?.operation?.param?.requiredMaterials ? d?.operation?.param?.requiredMaterials : []
      setRequiredMaterials(rm)
      _setRequiredMaterials(rm)
      setOperation(d?.operation)
      setChildren(<Component tableValues={tableValues} {..._props} />)
    }
  }

  useEffect(() => {
    f()
  }, [props])

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const checkValues = (e) => {
    setState({ ...state, validate: (state?.validate.length === 0) })
  }

  // const cols = props?.children?.tabs?.[props?.tabName]
  // // console.log(props?.item.param)
  // const _param = props?.item.param
  // // const _param = paramDestruct(result?.workorder?.param)
  // const columns = prepareColumns(cols, _param, lang)
  const inputStyle = { width: 200 }

  const quickNotesLabel = "lbl_" + quickNotes
  // const _item = { name: p?.operation?.operationName }
  const title = lang?.[props?.tabName] ?? props?.tabName
  // console.log(operation?.param?.operation?.operationName)

  const _item = { name: props?.item?.param?.operation?.operationName }
  return (
    <div style={style.OperationManipulateMo}>
      {/*
      <TitleInfo styles={props?.children?.styles?.TitleInfo} dataProps={_item} />
      <Divider />
      */}
      <ConfigProvider theme={{
        token: { fontSize: 20, borderRadius: 0 },
        components: {
          Form: {
            itemMarginBottom: 20
          },
        },
      }}>
        <Form
          key={JSON.stringify(state?.initialValues)}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ ...state?.initialValues, remember: true }}>
          <Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                // width: 'calc(50% - 8px)',
                margin: '0 10px',
              }}>
              <Form.Item
                name={labelGoodItems}
                label={lang?.[labelGoodItems] ?? labelGoodItems}
                rules={[{ required: false }]}>
                <InputNumber style={inputStyle} size="large" onChange={(e) => checkValues(e)} />
              </Form.Item>
              <Form.Item
                name={labelBadItems}
                label={lang?.[labelBadItems] ?? labelBadItems}
                rules={[{ required: false }]}>
                <InputNumber style={inputStyle} size="large" onChange={(e) => checkValues(e)} />
              </Form.Item>
              {/*
                <Form.Item
                label={lang?.["PartiallyFinished"] ?? "PartiallyFinished"}
                name="PartiallyFinished"
                valuePropName="checked">
                <Checkbox />
                </Form.Item>
              */}
            </Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                // width: 'calc(50% - 8px)',
                margin: '0 8px',
                float: "inline-end"
              }}>
              <Button
                style={buttonStyle}
                type="primary"
                htmlType="submit">
                {lang?.["btn_save"] ?? "btn_save"}
              </Button>
            </Form.Item>
          </Form.Item>
        </Form>
      </ConfigProvider>
      {children}
      {/*
      <Divider />
      <div style={{ flexGrow: 1 }}>
        <InfoColumns styles={props?.children?.styles?.InfoColumns} key={JSON.stringify({ ...columns })} dataProps={columns} />
      </div>
      */}
    </div>
  )
}

export default App;
