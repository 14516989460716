/*
* import images
* import images from '../assets/img/images';
* call image: images.name
*/

const images = {
  companyLogo: require('./logo.png').default,
  companyLogoSmall: require('./logo_small.png').default,
  hbot_logo: require('./hbot_logo.png').default,
}

export default images;
