import React, { useContext, Fragment } from 'react'
import { Tooltip } from 'antd';
import { MainContext } from 'src/Context';

const style1 = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  fontWeight: "bold",
  fontSize: 18
}

const style2 = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // maxWidth: "100%",
  fontWeight: "normal",
  paddingLeft: 5,
  fontSize: 18
}

// const style3 = {
//   marginBottom: 5
// }

const getValues = (_v, placement) => {
  const v = _v ? JSON.parse(_v) : []
  return (
    <>
      {v.map((el, i) => {
        const _i = i + 1
        return (
          <Tooltip placement={placement} title={el?.operationName}>
            {_i}: {el?.operationName}
          </Tooltip>
        )
      })}
    </>
  )
}

const App = (props) => {
  // console.log(props)
  const context = useContext(MainContext);
  const { lang } = context.state
  const text = props?.dataProps ?? []
  const placement = "right"

  const title = (el) => {
    return (
      <h5
        style={{
          background: "#FAFAFA",
          // background: "red",
          padding: "5px 0 5px 5px",
          marginRight: 15
          // transform: "translate(-50%, -50%)"
          // borderBottom: "1px solid #F0F0F0"
        }}>
        {lang?.[el?.label] ?? el?.label}
      </h5>

    )
  }
  return (
    <>
      {text.map((el, i) => {
        // console.log(el)
        if (i === 0) {
          return(
            <div key={JSON.stringify(i)} >
              {title(el)}
            </div>
          )
        }else {
          const length = text?.length
          const num = i + 1
          // console.log([length, num])
          return (
            <div key={JSON.stringify(i)} style={{ ...style1, marginBottom: ((length === num) ? 10 : ""), marginRight: 15, padding: "0 0 0 5px", }}>
              <Tooltip placement={placement} title={el?.tooltipValue ? el?.tooltipValue : el?.label}>
                {lang?.[el?.label] ?? el?.label}:
              </Tooltip>
              <span style={style2}>
                {el?.values ? (getValues(el?.values, placement)) : (
                  <Tooltip placement={placement} title={el?.tooltipValue ? el?.tooltipValue : el?.value}>
                    {lang?.[el?.value] ?? el?.value}
                  </Tooltip>
                )
              }
            </span>
          </div>
        )
        }
      })}
    </>
  )
}

export default App;
