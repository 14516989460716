import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button, Modal, Input } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';

class OperationManipulate extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.dataProps,
      statusTimer: "start",
      buttonFill: false,
      showInput: false
    }
  }


  componentDidMount() { this.getData() }

  getData = () => {
    console.log(this.props)
    // const { dataProps } = this.props
    // this.setState({ data: dataProps.data })
  }

  // POST -- update event data
  handleTiming = (type) => {
    const { authToken, dateTime, pauseWork } = this.context.state
    const { data } = this.state
    const now = Date.now()
    // TODO:
    // - save startDate to ???
    // - multi pause
    // - conditons on pause
    console.log(type)
    if (type === "finishWork") {
      this.setState({
        [type]: Date.now(),
        ["format_" + type]: dateTime(Date.now()),
        statusTimer: "start",
        buttonFill: false,
        buttonStyleStatus: ""
      }, () => this.setData())
    }
    if (type === "startWork") {
      this.setState({
        [type]: Date.now(),
        ["format_" + type]: dateTime(Date.now()),
        statusTimer: "pause",
        // buttonFill: false,
      })
    }
    if (type === "pauseWork") {
      this.setState({
        [type]: Date.now(),
        ["format_" + type]: dateTime(Date.now()),
        setPauseOption: true,
        statusTimer: "resume",
        buttonStyleStatus: "blinkDivYellow"
      })
    }
    if (type === "continuetWork") {
      this.setState({
        [type]: Date.now(),
        ["format_" + type]: dateTime(Date.now()),
        statusTimer: "pause",
        buttonStyleStatus: "blinkDivRed",
        breakInput: ""
      })
    }
    if (type === "startWork") {
        this.setState({
          [type]: Date.now(),
          ["format_" + type]: dateTime(Date.now()),
          // statusTimer: type !== "finishWork" ? true : false,
          buttonFill: true,
          buttonStyleStatus: "blinkDivRed"
        })
    }


    // if (type === "pauseWork") {
    //   this.setState({
    //     setPauseOption: true
    //   })
    // }else {
    //
    //
    //   // const param = {
    //   //   url: "/select_catalog",
    //   //   token: authToken,
    //   //   type: "",
    //   //   duration: "",
    //   //   [type]: type !== "pauseWork" && Date.now(),
    //   //   ["format_" + type]: dateTime(Date.now())
    //   // }
    //
    //   this.setState({
    //     // statusTimer: type !== "finishWork" ? true : false,
    //     buttonFill: type === "startWork" ? true : false,
    //   })
    // }


    // console.log(type)
    // switch (expression) {
    //   case expression:
    //
    //     break;
    //   default:
    //
    // }


    // this.setState({
    //   statusTimer: type !== "finishWork" ? true : false,
    //   param,
    //   buttonFill: type === "startWork" ? true : false,
    //   data: {
    //     ...data,
    //     ...param
    //   }
    // })


    // console.log(param)



    // let seconds = 0
    // setInterval(() => {
    //   seconds++
    //   console.log(seconds)
    // }, 1000)
  }

  setData = () => {

  }

  handleClick = (e) => {
    const { breakInput } = this.state
    console.log(e)
    // console.log(breakInput)
    switch (e) {
      case "cancelPause": this.setState({ setPauseOption: false, buttonFill: true, buttonStyleStatus: "blinkDivRed", statusTimer: "pause" }); break;
      case "shorBreak": this.setState({ setPauseOption: false, buttonFill: true, statusTimer: "resume" }); break;
      case "lunchBreak": this.setState({ setPauseOption: false, buttonFill: true, statusTimer: "resume" }); break;
      case "other": this.setState({ showInput: true }); break;
      case "customPause": this.setState({ setPauseOption: false, showInput: false, breakInput, statusTimer: "resume" }); break;
      default: this.setState({ setPauseOption: false, buttonFill: true }); break;

    }
  }

  customPause = () => {

  }

  render() {
    const { lang, layout } = this.context.state;
    const { colSize } = this.props;
    const { param, data, buttonFill, statusTimer, setPauseOption, showInput, buttonStyleStatus, breakInput } = this.state
    // console.log(statusTimer)
    const left = () => {
      switch (statusTimer) {
        case "pause": return lang["pause"] ? lang["pause"] : "pause";
        case "start": return lang["start"] ? lang["start"] : "start";
        case "resume": return lang["resume"] ? lang["resume"] : "resume";
        default:

      }
      // return status
    }
    const right = () => {
      switch (statusTimer) {
        case "pause": return lang["finish"] ? lang["finish"] : "finish";
        case "start": return "";
        case "resume": return lang["finish"] ? lang["finish"] : "finish";
        default:

      }
      // return status
    }

    const bStyle = { width: 200, height: 200, marginRight: 20, fontSize: 25 }
    const buttons = [
      {
        name: "shortBreak",
        props: {
          ghost: true,
          type: "primary",
          style: bStyle
        },
        value: "shortBreak"
      },
      {
        name: "lunchBreak",
        props: {
          ghost: true,
          type: "primary",
          style: bStyle
        },
        value: "lunchBreak"
      },
      {
        name: "other",
        props: {
          ghost: true,
          type: "primary",
          style: bStyle
        },
        value: "other"
      },
      {
        name: "cancelPause",
        props: {
          ghost: true,
          type: "primary",
          danger: true,
          style: { width: 200, height: 200, marginRight: 20, fontSize: 25, float: "right" }
        },
        value: "cancelPause"
      },
    ]
    return (
      <Col md={colSize}>
        <Row style={{ minHeight: 100, padding: "10px 0px 0px 10px" }}>
          <p></p>
           info
        </Row>
        <Row>
          <Col md={6}>
            <center>
              <Button
                className={buttonStyleStatus}
                // className={buttonFill ? "blinkDiv" : ""}
                ghost={buttonFill ? false : true}
                style={{ width: "40%", height: 150, fontSize: 25 }}
                type="primary"
                onClick={() => buttonFill ? (statusTimer === "resume" ? this.handleTiming("continuetWork") : this.handleTiming("pauseWork")) : this.handleTiming("startWork")}
                danger={buttonFill ? true : false}>
                {left()}
              </Button>
            </center>
          </Col>
          <Col md={6}>
            <center>
              {buttonFill &&
                <Button
                  className={buttonFill ? "blinkDivRed" : ""}
                  // ghost={buttonFill ? false : true}
                  style={{ width: "40%", height: 150, fontSize: 25 }}
                  type="primary"
                  onClick={() => buttonFill ? this.handleTiming("finishWork") : this.handleTiming("finishWork")}
                  danger={buttonFill ? true : false}>
                  {right()}
                </Button>
              }
            </center>
          </Col>


          <Col md={6}>
            {/*
              <center>
              <Button
              ghost
              style={{ width: "40%", height: 150, fontSize: 25 }}
              type="primary" danger
              onClick={() => this.handleTiming("endWork")}>
              {lang["stop"] ? lang["stop"] : "stop"}
            </Button>
          </center>
          */}
        </Col>
      </Row>
      <Row style={{ minHeight: 100, padding: "10px 0px 0px 10px" }}>
        <Row>
          <p></p>
           info
        </Row>
        {/*
          info Operation => WorkOrder
          <div>
          <pre>{JSON.stringify(param, null, 2) }</pre>
        </div>
        */}
        <div style={{ height: 100 }}>
          <pre>{JSON.stringify(data, null, 2) }</pre>
        </div>
      </Row>
      <Modal
        width={"auto"}
        title=""
        onOk={this.handleOk}
        // onCancel={() => this.setState({ setPauseOption: false, showInput: false })}
        cancelButtonProps={{ style: { display: 'none', width: 200, height: 100 } }}
        okButtonProps={{ style: { display: 'none' } }}
        // cancelText={lang["cancelPause"] ? lang["cancelPause"] : "cancelPause"}
        closable={false}
        destroyOnClose={true}
        open={setPauseOption}>
        <Row>
          {buttons.map((el, i) => {
            return(
              <Button onClick={() => this.handleClick(el.value)} key={i} {...el.props}>
                {lang[el.name] ? lang[el.name] : el.name}
              </Button>
            )
          })}
        </Row>
        <p></p>
        {showInput &&
          <Row>
            <div>
              <Input onChange={(e) => this.setState({ breakInput: e.target.value })} style={{ height: 50, width: 500, fontSize: 20 }}/>
            </div>
            <p></p>
            <div>
              <Button
                style={{ ...bStyle, width: "auto" }}
                type="primary"
                ghost={true}
                onClick={() => this.handleClick("customPause")}
                disabled={(breakInput && breakInput.length) ? false : true}>
                {lang["confirmCustomInput"] ? lang["confirmCustomInput"] : "confirmCustomInput"}
              </Button>
            </div>
          </Row>
        }
      </Modal>

    </Col>
  )
}
}

export default OperationManipulate;
