import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
import { Image } from 'antd';
import * as comp from 'antd';

class HeaderCard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.dataProps
    }
  }

  componentDidMount() {
    // console.log(this.props)
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setData = () => {
    const { companyProps } = this.context.state
    // this.context.contextCall("asset", )
    // console.log(companyProps)
    this.props.callback({ type: "HeaderCard", ...companyProps, showDrawer: true })
  }

  render() {
    const { style, _asset } = this.context.state
    // console.log(_asset)
    const _image = (_asset && _asset.param && _asset.param.asset_image) ? _asset.param.asset_image : ""
    // console.log(_image)
    const _comp = [
      {
        Button: {
          props: {
            preview: false,
            style: { ...style.header.buttonCustomHeader, maxHeight: 200, maxWidth: 200 },
            fallback: "/media/users/defaultMachine.png",
            src: "/media/users/" + _image,
            // onClick: () => this.props.callback({ type: "HeaderCard", props: "assets" })
          },
          name: <Image onClick={() => this.setData()} preview={false} style={{ maxHeight: 60, maxWidth: 60 }} fallback={"/media/users/defaultMachine.png"} src={"/media/users/" + _image} />
        }
      },
      // {
      //   Button: {
      //     props: {
      //       preview: false,
      //       style: { ...style.header.buttonCustomHeader, maxHeight: 200, maxWidth: 200, marginLeft: 10 },
      //       fallback: "/media/users/defaultMachine.png",
      //       src: "/media/users/" + _image,
      //       // onClick: () => this.props.callback({ type: "HeaderCard", props: "assets" })
      //     },
      //     name: <Image onClick={() => this.setData()} preview={false} style={{ maxHeight: 60, maxWidth: 60 }} fallback={"/media/users/defaultMachine.png"} src={"/media/users/" + _image} />
      //   }
      // }
    ]
    if (_comp && _comp.length > 0) {
      return (
        <div style={{ position: "absolute", left: 5, top: 5 }}>
          {_comp.map((el, i) => {
            const _key = Object.keys(el)
            const Component = comp[_key[0]]
            const props = el[_key[0]].props
            if (Component && props) {
              return <Component {...props}>{el[_key[0]].name}</Component>
            } else return <span style={{ fontWeight: "bold" }}>no props on comp</span>
          })}
        </div>
      )
    } else return <span style={{ position: "absolute", left: 5, top: 5, fontWeight: "bold" }}>no props "antd" !!!</span>
  }
}

export default withRouter(HeaderCard);
