/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Divider, Table } from 'antd';
import { MainContext } from 'src/Context';
import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { allinOne, sortNumAlpObj } from "src/utils/functions/filterFunction"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { pagination } from "src/utils/functions/specStyle"

const InfoCardOp = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const [state, setState] = useState({
    dataTable: []
  })
  const { lang } = props?.context ? props?.context?.state : context.state
  const { style } = props?.context ? props?.context : context
  const [item, setItem] = useState(props?.item ?? [])

  // "documentation": [
  //   {
  //     "documentDescription": null,
  //     "documentName": "",
  //     "erpId": 7,
  //     "fileExt": ".jpg",
  //     "fileName": "011140196 Lepilka.jpg",
  //     "url": "\\\\192.168.1.221\\nacrti in grafike\\PDF\\011140196n\\011140196 Lepilka.jpg",
  //     "validFrom": 1587340800000
  //   }
  // ]


  const getData = async () => {
    const erpId = props?.item?.param?.erp_productId
    const result = await allinOne("getDocumentation", { type: "ProductCatalog", erpId, keys: "documentation" })
    if (result.success) {
      return result?.r
    }
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      setState({
        dataTable: d?.documentation?.map((el, i) => ({ ...el, key: i + 1 }))
      })

    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [item])

  const columns = [
    {
      title: "#",
      dataIndex: 'key',
      key: 'key',
      width: 20,
      align: "center",
    },
    {
      title: lang?.["name"] ?? "name",
      dataIndex: 'documentName',
      key: 'documentName',
      width: 200,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "documentName")
    },
    {
      title: lang?.["fileName"] ?? "fileName",
      dataIndex: 'fileName',
      key: 'fileName',
      width: 200,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "fileName")
    },
    {
      title: lang?.["link"] ?? "link",
      dataIndex: 'url',
      key: 'url',
      // width: 100,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "url"),
      render: (text, record) => <a href={text} target="_blank">{text}</a>,
    },
    {
      title: lang?.["description"] ?? "description",
      dataIndex: 'documentDescription',
      key: 'documentDescription',
      // width: 100,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "url"),
      render: (text, record) => <a href={text} target="_blank">{text}</a>,
    },
    {
      title: lang?.["validFrom"] ?? "validFrom",
      dataIndex: 'validFrom',
      key: 'validFrom',
      width: 200,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "documentName"),
      render: (text) => <span>{dateTimeFormatter("date", (text ? Number(text) : 0), "dd.mm.yyyy")}</span>
    }
  ]

  const title = lang?.[props?.tabName] ?? props?.tabName
  const _item = { name: item?.param?.operation?.operationName }
  const tableProps = {
    pagination
  }
  return (
    <div style={style.OperationManipulateMo}>
      {/*
      <TitleInfo styles={props?.children?.styles?.TitleInfo} dataProps={_item} />
      <Divider />
      */}
      <Table
        {...tableProps}
        bordered
        style={{ padding: 0 }}
        className="table-striped-rows"
        columns={columns}
        dataSource={state?.dataTable}
      // rowSelection={rowSelection}
      // pagination={pagination}
      />
    </div>
  )
}

export default InfoCardOp;
// <InfoColumns styles={props?.children?.styles?.InfoColumns} key={JSON.stringify(columns)} dataProps={columns} />
