import React, { useContext, useState, useEffect, useRef } from 'react'
import { Tooltip, Image } from 'antd';
import { Card, Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
// import { formatValue } from "src/utils/functions/filterFunction"
import { Infos } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { colorCircle, onlineStatus } from "src/layouts/widgets/CustomLayout/index"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { content } from "src/utils/functions/core"

const MoDetails = (props) => {
  const { assets, clientUrl, layout } = content()
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang } = context.state
  // const [ result, setResult ] = useState()
  // const [item, setItem] = useState(props?.dataProps ?? [])
  // useEffect(() => {
  //   return () => { mounted.current = false }
  // }, [item])
  const findAsset = assets?.find((f) => f._id === props?.dataProps?.references?.assetDefId?.$ref)
  // console.log(findAsset)
  const info = [
    { label: lang["name"] ? lang["name"] : "name", value: findAsset?.asset_name },
    { label: lang["model"] ? lang["model"] : "model", value: findAsset?.param?.asset_modelImage  },
    { label: lang["location"] ? lang["location"] : "location", value: findAsset?.param?.asset_locationName },
    { label: lang["description"] ? lang["description"] : "description", value: findAsset?.param?.asset_description },
    { label: lang["connectionStatus"] ? lang["connectionStatus"] : "connectionStatus", value: onlineStatus(Number(findAsset?.param?.asset_connectionStatus)) },
    //   { label: lang["name"] ? lang["name"] : "name", value:  },
  ]
  return (
    <Row>
      <Col md={2}>
        <Image
          preview={true}
          fallback={clientUrl + "/media/users/" + layout.defaultImg}
          src={clientUrl + "/media/users/" + findAsset?.param?.asset_image}
          />
      </Col>
      <Col md={10}>
        <Infos dataProps={info}/>
      </Col>
    </Row>
  )
}

export default MoDetails;
