import React from 'react';
import { Card } from 'react-bootstrap';

class PlaceholderCard extends React.Component {
  render() {
    return (
      <div className={`col md ${this.props.colSize}`}>
        <Card>
          <Card.Body style={placeholderStyle}>
            <h1>Placeholder</h1>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

const placeholderStyle = {
  textAlign: "center",
  padding: 50
}

export default PlaceholderCard;
