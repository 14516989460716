/* eslint-disable no-unused-vars */
import Empty from 'src/layouts/widgets/Core/Empty';
import FileNotExist from 'src/layouts/widgets/Core/FileNotExist';
// import ErrorHandler from 'src/layouts/widgets/Core/ErrorHandler';

import Dashboard from "./Companies/Dashboard";
import Settings from "./config/Settings";
import UserSettings from "./config/UserSettings";


import About from "./Core/about";
import Advanced from "../components/advanced";
import DashboardV2 from "./Companies/DashboardV2";

// Client i4_V2
import Text from "src/layouts/widgets/Companies/i4c_V2/main/Text";
import Assets from "src/layouts/widgets/Companies/i4c_V2/main/Assets";
// import AssetsPanel from "src/layouts/widgets/Companies/i4c_V2/main/AssetsPanel";
import MaintenanceOperation from "src/layouts/widgets/Companies/i4c_V2/main/MaintenanceOperation";

import WorkOrders from "src/layouts/widgets/Companies/i4c_V2/main/WorkOrders";
import OperationOrder from "src/layouts/widgets/Companies/i4c_V2/main/OperationOrder";
import Pause from "src/layouts/widgets/Companies/i4c_V2/main/Pause";
import InteruptWithHistory from "src/layouts/widgets/Companies/i4c_V2/main/InteruptWithHistory";
import ProductBoard from "src/layouts/widgets/Companies/i4c_V2/main/ProductBoard";
import WorkforcePlan from "src/layouts/widgets/Companies/i4c_V2/main/WorkforcePlan";
import Warehouse from "src/layouts/widgets/Companies/i4c_V2/main/Warehouse";
import StanjeProizvodnje from "src/layouts/widgets/Companies/i4c_V2/main/StanjeProizvodnje";

import DeviceDashboard from "src/layouts/widgets/Companies/i4c_V2/main/DeviceDashboard";


// import UsersOn from "src/layouts/widgets/Companies/i4c_V2/main/UsersOn";



// Client i4Cube pages
// import WorkOrders from "./Companies/i4c_client/Pages/WorkOrders";
//import MaintenaceOperations from "./Companies/i4c_client/Pages/MaintenaceOperations";
//import UserDashboard from "./Companies/i4c_client/Pages/UserDashboard";
//import AssetsPage from "./Companies/i4c_client/Pages/AssetsPage";
//import TabsBoards from "./Companies/i4c_client/components/TabsBoards";



const widgets = {
  Empty,
  FileNotExist,
  // Core
  Advanced,
  Dashboard,
  Settings,
  About,
  UserSettings,
  // Client i4Cube
  // WorkOrders,
  //MaintenaceOperations,
  //UserDashboard,
  //AssetsPage,


  // i4c_V2
  Text,
  Assets,
  // AssetsPanel,
  DashboardV2,
  WorkOrders,
  OperationOrder,
  MaintenanceOperation,
  Pause,
  InteruptWithHistory,
  ProductBoard,
  WorkforcePlan,
  Warehouse,
  DeviceDashboard,
  StanjeProizvodnje
  // UsersOn
};

export default widgets;
/* eslint-enable no-unused-vars */
