/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
import { Card, Row, Col } from "react-bootstrap";
import { Switch, Button, Popconfirm } from 'antd';
import Select from "react-select";
import { Footer, UserSettings } from 'src/layouts/layouts';
import { allinPut } from "src/utils/functions/filterFunction"
import { content } from "src/utils/functions/core"
import { socket } from 'src/utils/socket';

class Settings extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      title: "Settings",
      callback: this.callback
    }
  }

  onChange = (checked) => { this.context.contextCall("switchTheme", checked) }
  onChangeShowMeta = (e) => { this.context.contextCall("showHeaderFooter", e) }
  onChangeShowSimulation = (e) => { this.context.contextCall("simulationShow", e) }
  handleButton = (x, path) => { if (x) { this.props.history.push(path) } }
  onChangeFullScreen = (e) => { this.context.contextCall("fullScreen", e) }

  callback = (e) => {
    // console.log(e)
    this.setState({
      showModal: false
    })

  }

  setLanguage = async (e) => {
    const { user } = content()
    const userId = user?.userId
    const result = await allinPut("updateLanguageOnUser", { userId, lang: e })
    // this.context.setLang(e)
  }

  localStorageClear = () => {
    const { userConfig } = this.context.state
    socket.connect()
    socket.emit("lstClear", {
      userId: userConfig?.user?.userId
    })
  }

  componentWillUnmount = () => {
    socket.off("lstClear")
  }

  render() {
    const { lang, userConfig, showHeaderFooter, fullScreen } = this.context.state
    const { style } = this.context
    const { indexKey } = this.state
    const { history } = this.props
    let languages = []
    // console.log(layoutDataContext && layoutDataContext.widgets[0][0][0].props.showHeaderFooter)
    // layoutDataContext && layoutDataContext.widgets[0][0][0].props.simulation
    Object.keys(userConfig.languages).forEach((key, i) => { languages.push({ value: key, label: userConfig.languages[key].langPrefix }) });
    const buttonStyle = {
      width: 150,
      height: 50,
      fontSize: 20,
      margin: "0px 0px 10px 90px"
    }
    const developer = userConfig?.user?.developer
    // console.log(userConfig)
    return (
      <div style={style.userInterface}>
        <Button
          ghost={false}
          type="primary"
          danger={false}
          style={{ ...buttonStyle }}
          onClick={() => this.props.history.push({ pathname: "/dashboard" })}>{lang?.["Home"] ?? "Home"}
        </Button>
        <div style={style.userInterface.body}>
          <Row style={style.userInterface.first}>
            <Col>
              <Card style={style.userInterface.card}>
                <Card.Header>
                  <span>{lang?.["additionalSettings"] ?? "additionalSettings"}</span>
                </Card.Header>
                <br />
                <span>
                  <span>
                    Show Header/footer
                  </span>{'\xa0\xa0'}
                  <Switch checked={showHeaderFooter} onChange={this.onChangeShowMeta} />
                </span>
                <span>
                  <span>
                    FullScreen
                  </span>{'\xa0\xa0'}
                  <Switch checked={fullScreen} onChange={this.onChangeFullScreen} />
                </span>
              </Card>
            </Col>
            <Col>
              <Card onClick={() => this.handleButton(true, "/about")} style={style.userInterface.card}>
                <Card.Header>
                  <span>{lang?.["About"] ?? "About"}</span>
                </Card.Header>
                <br />
                <Card.Body style={style.userInterface.about}>
                  <span>
                    <span>{lang["Version"]} : {userConfig.appVersion}</span>
                  </span>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <p></p>
          <Row style={style.userInterface.second}>
            <Col>
              <Card style={style.userInterface.card}>
                <Card.Header>
                  <span>{lang?.["Other"] ?? "Other"}</span>
                </Card.Header>
                <Card.Body>
                  {/*

                    <Button onClick={() => history.push("/user_settings") }>
                      UserSettings
                    </Button>
                    */}
                  {developer &&
                    <Popconfirm
                      title="Confirm clearing local storage to ALL active users"
                      description="Are you sure, all session will be destroyed?"
                      onConfirm={this.localStorageClear}
                      onCancel={(e) => console.log(e)}>
                      <Button danger={true} type="primary">
                        Clean local storage
                      </Button>
                    </Popconfirm>
                  }
                </Card.Body>
                {/*
                    <p onClick={() => this.handleButton(true, "/listAll")}> Products/Test </p>
                    <Card.Header>
                    {lang["Switch theme"] ? lang["Switch theme"] : "Switch theme"}
                  </Card.Header>
                  <br />
                  <span>
                  <span>{lang["Dark"] ? lang["Dark"] : "Dark"}</span>{'\xa0\xa0'}
                  <Switch style={style.userInterface.switchStyle} checked={switchTheme} onChange={this.onChange} disabled/>
                  {'\xa0\xa0'}<span>{lang["Light"] ? lang["Light"] : "Light"}</span>
                </span>
                <span>IN PROGRESS...</span>
                <span style={style.userInterface.span}>3</span>
                */}

              </Card>
            </Col>
            <Col>
              <Card onClick={() => { }} style={style.userInterface.card}>
                <Card.Header>
                  <span>{lang?.["selectLanguage"] ?? "selectLanguage"}</span>
                </Card.Header>
                <br />
                <Select
                  styles={style.dropdownLang}
                  placeholder={lang["langPrefix"]}
                  options={languages}
                  onChange={(el) => this.setLanguage(el.value)}
                  isSearchable={false} />
              </Card>
            </Col>
          </Row>
        </div>
        <Footer data={this.state} />
      </div>
    )
  }
}

export default withRouter(Settings);
