import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
// import 'antd/dist/antd.css';
// import './assets/css/all.css';
import App from "./App";
import { MainProvider, MainContext } from "./Context";


ReactDOM.render(
  <MainProvider store={MainContext}>
    <App />
  </MainProvider>,
  document.getElementById("root")
);
