/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import { MainContext } from 'src/Context';
import { Drawer } from 'antd';
// import { Card, Col, Row } from 'react-bootstrap';
import { renderIconV2 } from "src/utils/functions/filterFunction.js"
import * as comp from './layouts';

const DrawerCatalog = (props) => {
  const [show, setShow] = useState(true);
  const { dataProps, callback } = props
  const context = useContext(MainContext);
  const { lang, renderIcon, undefObjHandler } = context.state
  const Component = (props && props.dataProps && props.dataProps.drawerComp) ? (comp[props.dataProps.drawerComp] ? comp[props.dataProps.drawerComp] : comp["Empty"]) : comp["Empty"]
  const title = (p) => {
    if ((p && p.dataProps.props) && (p.dataProps.props.title || props.dataProps.props.titleComp)) {
      if (props.dataProps.props.title) {
        return props.dataProps.props.title
      } else if (props.dataProps.props.titleComp) {
        // const _Component = comp[_comp]
        const _comp = props.dataProps.props.titleComp ? props.dataProps.props.titleComp : "Empty"
        const Component = comp[comp[_comp] ? _comp : "Empty"]
        return <Component key={dataProps.showModal} callback={callback} dataProps={{ ...props.dataProps }} />
      }
    } else {
      return lang[p.dataProps.comp] ? lang[p.dataProps.comp] : p.dataProps.comp
    }
  }

  const _showDrawer = (x, id) => {
    setShow(x)
    callback({ type: "drawerComp", id })
  }
  const _dataProps = {
    ...dataProps,
    showModal: dataProps.showModal ? dataProps.showModal : context.state.showDrawer
  }
  // console.log(props)
  const _callback = (e) => { props.callback({ type: "DrawerCatalog", ...e }) }
  const id = undefObjHandler(dataProps, "_event") ? dataProps._event.asset._id : false
  return (
    <>
      {
        dataProps && dataProps.showModal &&
        <Drawer
          key={undefObjHandler(dataProps, "item") ? dataProps.item._id : id}
          headerStyle={{ display: "none" }}
          title={title(props)}
          // width={2000}
          height={"85%"}
          placement="bottom"
          // closable={false}
          destroyOnClose={true}
          onClose={() => callback({ ..._dataProps })}
          // onClose={() => callback({ ..._dataProps }, context.contextCall("showDrawer", false))}
          open={show}>
          <div
            onClick={() => _showDrawer(false, (undefObjHandler(dataProps, "item") ? dataProps.item._id : id))}
            style={{ width: "100%", cursor: "pointer", height: 30 }}>
            <span style={{ float: "right" }}>{renderIconV2("IcNavArrowDown", 40, "", "")}</span>
          </div>
          <Component key={Math.random()} callback={_callback} dataProps={{ ...props.dataProps }} />
        </Drawer>
      }
    </>
  )
}

export default DrawerCatalog;
// if (dataProps.item) {
//   console.log(dataProps.item._id)
// } else return <></>
