import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from '../../Context';
import { Input, Button, Modal } from 'antd';
import { Row, Col } from "react-bootstrap";

class Advanced extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {
      showModalPass: true
    }
    this.textSent = this.textSent.bind(this)
    this.passHandle = this.passHandle.bind(this)
    this.count = 0
  }

  textSent = (event) => {
    this.setState({
      text: event.target.value
    })
  }

  textSentOnClick = () => {
    const { socketConn } = this.context.state
    const { text } = this.state
    socketConn.emit("triggerState", {
      stateReload: true,
      action: "textSent",
      text
    })
    this.setState({ text: "" })
  }

  passHandle = (event) => {
    this.setState({
      pass: event.target.value
    })
  }

  componentDidUpdate(){
    this.count = this.count + 1
    // const { userDataContextSocket, stateReload } = this.context.state
    // const { contextCall } = this.context

    // NOTE in future ...
    // if (stateReload) {
    //   this.setState({
    //     [this.count]: {
    //       [userDataContextSocket.data.user.userName]: {
    //         pathname: userDataContextSocket.data,
    //         dateTime: new Date().getTime()
    //       }
    //     }
    //   })
    //   contextCall("stateReload", false)
    // }
  }

  callSocketDemo = (type) => {
    const { socketConn } = this.context.state
    socketConn.emit("triggerState", {
      stateReload: true,
      action: "textSent",
      hftesting: {
        pressure: JSON.parse((Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) * (Math.floor(Math.random() * 11) / 100)).toFixed(1)),
        weldingTime: JSON.parse((Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) * (Math.floor(Math.random() * 11) / 100)).toFixed(1)),
        power: JSON.parse((Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) * (Math.floor(Math.random() * 11) / 100)).toFixed(1)),
        coolingTime: JSON.parse((Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) * (Math.floor(Math.random() * 11) / 100)).toFixed(1)),
        energy: JSON.parse((Math.floor(Math.random() * 11) + Math.floor(Math.random() * 11) * (Math.floor(Math.random() * 11) / 100)).toFixed(1)),
      }
    })
    this.setState({ text: "" })
  }

  render () {
    const { text, pass, showModalPass } = this.state
    const handleOk = () => {
      if (pass === "*****") {
        this.setState({ showModalPass: false })
      }else {
        this.setState({ showModalPass: true })
      }
    };

    const handleCancel = () => {
      this.props.history.push({ pathname: "/dashboard" })
    };
    return(
       <div>
      <Row>
        <Modal
          title="Enter password"
          visible={showModalPass}
          onOk={handleOk}
          cancelText={'Home'}
          onCancel={handleCancel}>
          <Input type="password" onChange={this.passHandle}/>
        </Modal>
        <Col md={4}>
          <Row>
            <label>Send message to all clients:</label>
            <Col md={10}>
              <Input
                value={text}
                onKeyPress={(event) => event.key === "Enter" ? this.textSentOnClick() : {}}
                onChange={this.textSent}/>
            </Col>
            <Col md={2}>
              <Button onClick={() => this.textSentOnClick()}>
                Send
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={4}></Col>
        <Col md={4}></Col>
      </Row>
      <br /><br /><br /><br />
      <Row>
        <Col md={4}>
          {/*
            <Button type="primary" onClick={() => this.callSocketDemo("hftesting")}>
            hf TESTING/WELDING
            </Button>
          */}
        </Col>
        <Col md={4}>
        </Col>
        <Col md={4}></Col>
      </Row>
    </div>
    )
  }
}

export default withRouter(Advanced);
