/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { dateTimeFormatter } from "src/utils/functions/formater"
import ReactApexChart from "react-apexcharts";

const App = (props) => {
  const timestamp = props?.timestamp
  const events = props?.events ?? []
  const [chartConfig, setChartConfig] = useState({
    type: "rangeBar",
    height: 250,
    options: {
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
          rangeBarGroupRows: true
        }
      },
      grid: {
        show: false,
      },
      stroke: {
        width: 1
      },
      fill: {
        type: 'solid',
        opacity: 0.6
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center'
      },
      chart: {
        id: 'chartShift',
        type: 'rangeBar',
        height: "auto",
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false, // | '<img src="/media/users/default.png" width="100" >',
            zoomin: false, // | '<img src="/media/users/default.png" width="100" >',
            zoomout: false,
            pan: false,
            reset: false, // | '<img src="/media/users/default.png" width="100">',
            customIcons: []
          },
        },
        selection: {
          type: 'x',
          fill: {
            color: '#24292e',
            opacity: 0.1
          },
          stroke: {
            width: 1,
            dashArray: 3,
            color: '#24292e',
            opacity: 0.4
          },
          xaxis: {
            min: undefined,
            max: undefined
          },
          yaxis: {
            min: undefined,
            max: undefined
          }
        },
        events: {
          click: (event, chartContext, config) => {
            const timestamp = {
              start: Number(event?.target?.dataset?.rangeY1),
              end: Number(event?.target?.dataset?.rangeY2)
            }
            const findEvents = events.filter((f) => f?.startDate >= Number(event?.target?.dataset?.rangeY1) && f?.endDate <= Number(event?.target?.dataset?.rangeY2))
            if (findEvents?.length > 0) {
              props?.openIdleTime({ drawer: true, timestamp, findEvents })
            } else {
              props?.handleIdleTime({ drawer: true, timestamp })
            }
          }
        },
      },
      colors: ['#dc3545', '#28a745', '#ffc107', '#FF7F7F'],
      xaxis: {
        show: false,
        type: 'datetime',
        min: Number(timestamp?.[0]),
        max: Number(timestamp?.[1]),
        labels: {
          showDuplicates: false,
          formatter: (value) => {
            return dateTimeFormatter("date", value, "dd.mm hh:mm")
          }
        }
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      yaxis: {
        show: false
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return (dateTimeFormatter("date", w.config.series[seriesIndex].data[dataPointIndex].y[0], "dd.mm hh:mm") + " - " + dateTimeFormatter("date", w.config.series[seriesIndex].data[dataPointIndex].y[1], "dd.mm hh:mm"))
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          const findValue = w.config.series[seriesIndex].data.find((f) => JSON.stringify(f.y) === JSON.stringify(value))
          return findValue?.e
        },
        offsetX: 0,
      },
    },
    series: props?.series,
  })
  return <ReactApexChart  {...chartConfig} />
}
export default App;
