import React from 'react';
import { MainContext } from 'src/Context';
import { Row, Col, Card, Tabs, Tab, Button } from 'react-bootstrap';
import { Input } from 'antd';
// import { Open, Planned, Ongoing, Done } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import * as comp from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import { MultiCol } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import { Operation, DrawerCatalog } from 'src/layouts/widgets/Companies/i4c_client/components/Drawer/layouts'

class OperationsBoard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {
      ...this.props.dataProps,
      // indexKey: Math.random()
      // showModal: false
    }
  }

  componentDidMount(){
    this._isMounted = true
    // this.getData()
    // console.log(Math.random())
    const { socketConn } = this.context.state
    socketConn.on("stateReload", (data) => {
      const { assetId } = this.state
      // console.log(data.goTrigger)
      if (data.goTrigger) {
        // console.log(data.data.data.assetId)
        console.log([assetId, data.data.data.assetId])
        if (assetId === data.data.data.assetId) {
          this.props.callback({ from: "OperationsBoard" })
        }
        // this.context.contextCall("triggerContext", true)
      }
    })
  }

  componentWillUnmount() {
    // const { socketConn } = this.context.state
    // const { dataProps } = this.props
    // socketConn.off(dataProps.topic)
    this._isMounted = false
  }

  getData = () => {
    const { dataProps } = this.props
    // console.log(dataProps)
    // console.log(this.context.state.assetId)
    // const { querySelect, authToken } = this.context.state
    // querySelect({
    //   url: "/select_catalog",
    //   token: authToken,
    //   type: "Product",
    //   // keys: "i4c.*",
    //   keys: "_id, selectedProduct.product AS product ",
    //   // id: data.validationId,
    //   // extraQuery: 'AND `material`.`code` = ' + '"' + data.validationRef.code + '"'
    // }).then(res => {
    //   if (this._isMounted && res) {
    //     // this.context.contextCall("spinnerCustom", false)
    //     const data = dataProps.boards
    //     console.log(data)
    //     this.setState({
    //       ...data,
    //       indexKey: Math.random()
    //       // _dataProps: dataProps,
    //       // products: res
    //     })
    //   }
    // })

    // if (this._isMounted) {
    //   // this.context.contextCall("spinnerCustom", false)
    //   const data = dataProps.boards
    //   console.log(data)
    //   this.setState({
    //     ...data,
    //     indexKey: Math.random()
    //     // _dataProps: dataProps,
    //     // products: res
    //   })
    // }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChangeInput = (type, e) => {
  }

  callback = (e) => {
    // console.log(e)

    // if (e && e.type) {
    //   // console.log(e)
    //   switch (e.type) {
    //
    //     case "drawer": this.setState({ ...e.value  }); break;
    //     default:
    //
    //   }
    //
    // }
    if (e.props) {
      this.setState({
        showModal: false
      })
    }else {
      const { socketConn } = this.context.state
      const { assetId } = this.state
      socketConn.emit("triggerState", {
        stateReload: true,
        data: {
          assetId
        }
      })
      this.props.callback({ ...e, from: "OperationsBoard" })
    }
  }

  render () {
    const { showModal, indexKey } = this.state
    const { lang } = this.context.state
    // console.log(this.state)
    return(
      <div style={{ padding: "5px 0px 0px 0px", width: "100%"}}>
        <Row>
          <MultiCol key={indexKey} callback={this.callback} dataProps={{ ...this.state }}/>
          <DrawerCatalog key={indexKey} callback={this.callback} dataProps={{ showModal: showModal, comp: "Operations", props: this.state }}/>
          <Button
            style={{ width: 250, height: 80, position: "fixed", left: 10, bottom: 10 }}
            type="primary"
            // ghost={true}
            onClick={() => this.setState({ showModal: !showModal })}>
            {lang["showOperations"] ? lang["showOperations"] : "showOperations"}
          </Button>
        </Row>
      </div>
    )
  }
}

export default OperationsBoard;
