import React from 'react'
import { MainContext } from 'src/Context';
import { Row, Col, Card } from 'react-bootstrap';
import { ItemCatalog, renderIconV2 } from "src/utils/functions/filterFunction.js"
import { singleRow, colorCircle } from "src/layouts/widgets/CustomLayout/index"

class TitleInfo extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }

  componentDidMount() {
    this._isMounted = true
    this.getData()
  }

  getData = async() => {
    const { dataProps } = this.state
    if (dataProps) {
      this.setState({ item: dataProps })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { lang, dateTimeFormatter } = this.context.state
    const { item, items } = this.state
    const styles = this?.props?.styles
    if (item) {
      return (
        <Row>
          <Col md={4}>
            {(item.deliveryStatus && item.deliveryStatus.value) && colorCircle(item.deliveryStatus.value)}
          </Col>
          <Col md={4}>
            <center style={styles}>
              {/*<Card.Header style={{ borderRadius: 0, background: (item.param && item.param.startWork) ? "rgb(10, 0, 208, 0.20)" : (item.param.completed ? "rgb(82, 195, 0)" : "") }}>*/}
              {lang[item.name] ? lang[item.name] : item.name}
            </center>
          </Col>
          <Col md={4}></Col>
        </Row>

      )
    }else return <></>
  }
}

export default TitleInfo;
