/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
// import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip, Tabs } from 'antd';
// import { Row, Col } from 'react-bootstrap';
// import { MainContext } from 'src/Context';
import { allinOne } from "src/utils/functions/filterFunction"
// import { content } from "src/utils/functions/core"
import { PoBoardView } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import Spinner from "src/layouts/components/spinner"

const setDataAll = (WorkflowCatalog, tasks) => {
  let _board = []
  // console.log(tasks)
  WorkflowCatalog?.param?.steps.forEach((el, i) => {
    // console.log(el)
    const findStatus = tasks?.filter((f, j) => (f?.param?.status?.status) === el.id)
    if (findStatus) { _board.push({ ...el, items: findStatus }) } else { _board.push(el) }
  });
  return _board
}

const WorkOrders = (props) => {
  const mounted = useRef(true);
  // const context = useContext(MainContext);
  const [result, setResult] = useState()
  // const [items, setItems] = useState()
  // const [trigger, setTrigger] = useState()
  // const [activeKey, setActiveKey] = useState(props?.dataProps?.activeKey ?? "1")
  // const [prop, setProp] = useState(props)
  const [children, setChildren] = useState(<Spinner />)
  // const [comp, setComp] = useState()
  // const { authToken, dateTime, layout, userConfig } = context.state
  // const { user } = userConfig

  const getData = async () => {
    if (mounted.current) {
      const assetId = props?.dataProps?.props?._id
      const result = await allinOne("WorkOrdersData", { assetId, _id: props?.children?.wofId })
      // console.log(result)
      if (result.success) {
        const board = setDataAll(result._WorkflowCatalog, result._WorkOrders)
        const all = { ...result, board }
        return all
      }
    }
  }


  useEffect(() => {
    const f = async () => {
      const d = await getData()
      setResult(d)
      if (d) {
        setChildren(<PoBoardView key={Date.now()} children={props?.children} callback={callback} dataProps={d} />)
      }
    }
    f()
    const callback = (e) => { f() }
    return () => {
      mounted.current = false
      setChildren(<Spinner />)
    }
  }, [])

  return (<>{children}</>)
}

export default WorkOrders;
