import React from "react";
import { MainContext } from "../Context";
import { withRouter, Redirect } from "react-router-dom";
import { Button } from "react-bootstrap";
// import Fullscreen from "react-full-screen";
import { toast } from 'react-toastify';
import { Input, Form } from 'antd';
// import Select from "react-select";
// import { TiBackspaceOutline } from 'react-icons-ng/ti';
// import RollbackOutlined from '@ant-design/icons/RollbackOutlined';
import Socket from "../utils/Socket.io";
import KeyPad from "src/layouts/widgets/Core/KeyPad";

// eslint-disable-next-line
import { isMobile, browserName, BrowserView, MobileView, isBrowser, deviceType } from 'react-device-detect';

import images from '../assets/img/images';

class Login extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      pin: "",
      fullscreen: false,
      logOut: false,
      data: []
    };
    this.handleSubmit = this.handleSubmit.bind(this)
    this.Socket = new Socket()
    this.inputPass = React.createRef(null);
  }

  componentDidMount(){
    if (this.inputPass.current) {
       this.inputPass.current.focus();
     }
  }

  callToast = (warning, text, position) => {
    const audio = new Audio("/media/sounds/justlikethat.mp3")
    const playPromise = audio.play();
    toast[warning](text, {
      position: `${"top-" + position}`,
      pauseOnFocusLoss: false,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      onOpen: () => {
        if (playPromise !== undefined) {
          playPromise
          .then(el => audio.play())
          .catch(error => console.log(error));
        }
      }
    });
  }

  handleSubmit = (e) => {
    const { pin } = this.state;
    if (pin === "") { this.callToast("warn", "Can not be empty", "center") }
    const user = {
      user: "all",
      pin: pin
    }
    this.authLogin(user)
    .then(el => {
      // console.log(el)
      if (el.auth_token) {
        this.setUserTrack(el)
        this.context.setUserSettings(el)
        this.context.contextCall("userConfig", el)
        this.context.contextCall("socket", true)
        this.context.contextCall("userState", true)
        this.context.contextCall("authToken", el.auth_token)
        // this.context.contextCall("layoutConfig", el.layoutConfig)

        const endPoint = window.origin
        this.Socket.connect(endPoint, el.auth_token)
        this.context.contextCall("socketConn", this.Socket)

        const _el = {
          ...el,
          clientUrl: el?.layout?.clientUrl ? el?.layout?.clientUrl : window.location.origin.slice(0, -1) + "1"
        }
        // localStorage.setItem("layoutConfig", JSON.stringify(el.layoutConfig))
        localStorage.setItem('body', JSON.stringify(_el))
        localStorage.setItem('authToken', el.auth_token)
        localStorage.setItem('socketConn', this.Socket)
        localStorage.setItem("userConfig", JSON.stringify(el))
        localStorage.setItem("socket", true)
        localStorage.setItem('timeNow', new Date().getTime())
        localStorage.setItem("firstTime", true)
        // this.checkCookieExists(el)
        this.props.history.push("/dashboard")
      }
      if (!el.success) {
        this.callToast("error", "Wrong PIN", "center")
      }
    })
  };

  pressbutton = (press) => {
    // eslint-disable-next-line
    const audio = new Audio("/media/sounds/justlikethat.mp3")
    switch (press) {
      case press: this.setState((prevState) => { return { pin: prevState.pin + press }}); /*audio.play();*/ break;
      default: break;
    }
  }

  setUserTrack = (data) => {
    // console.log(data)
    const { host, hostname, href, origin, pathname, port, protocol } = window.location
    const { queryPut } = this.context.state
    const id = data.user.userId
    const body = {
      userId: id,
      token: data?.auth_token,
      terminal: true,
      // expired: localStorageExpired,
      firstName: data?.user?.firstName,
      lastName: data?.user?.lastName,
      meta: {
        host, hostname, href, origin, pathname, port, protocol,
        browser: navigator?.userAgent
      }
    }
    // console.log(body)
    queryPut({
      url: "/userTrack",
      token: data.auth_token,
      type: "UserTrack",
      // keys: "i4c.*",
      body
    }).then(res => {
      if (res) {
        // console.log({
        //   login: true,
        //   user: data.user.firstName,
        // })
      }
    })
  }

  authLogin = async (user) => {
    return fetch("/loginPin", {
      method: "POST",
      mode: 'cors',
      credentials: 'same-origin',
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(user),
    })
    .then((res) => { return res.json()})
    .catch((error) => console.log("error"))
  }

  checkCookieExists(el) {
    if (document.cookie.split(';').some((item) => item.trim().startsWith('user='))) {
      var user = this.getCookie("user");
    }
  }

  getCookie(username) {
    let name = username + "=";
    let spli = document.cookie.split(';');
    for(var j = 0; j < spli.length; j++) {
      let char = spli[j];
      while (char.charAt(0) === ' ') {
        char = char.substring(1);
      }
      if (char.indexOf(name) === 0) {
        return char.substring(name.length, char.length);
      }
    }
    return "";
  }

  render() {
    // if (localStorage.getItem("userConfig")) { return <Redirect to={'/dashboard'}/> }
    const { lang, userConfig } = this.context.state;
    const { style } = this.context
    const { pin } = this.state;
    const pressbutton = (e) => {
      this.pressbutton(e)
    }
    const backState = (e) => {
      this.setState((prevState) => { return { pin: prevState.pin.slice(0, -1)}})
    }
    const handleSubmit = (e) => {
      this.handleSubmit()
    }

    if (!userConfig) {
      return (
        <>
        <div style={style.login}>
          <header style={style.login.header}>
            <h3 style={style.login.header}><img src={images.companyLogoSmall} alt="noImg" /></h3>
            <p>{lang["Please enter PIN"] ? lang["Please enter PIN"] : "Please enter PIN"}</p>
          </header>

          <div style={style.login.rowNumPad} className="row">
            <div className="col-md-12">
              <Form style={style.login.rowNumPad.form}>
                <Input.Password
                  ref={this.inputPass}
                  placeholder={lang["Input PIN"] ? lang["Input PIN"] : "Input PIN"}
                  style={style.login.rowNumPad.input}
                  value={pin}
                  onChange={(e) => this.setState({ pin: e.target.value })}
                  onKeyDown={e => e.key === "Enter" ? this.handleSubmit() : null }
                  autoComplete="off"/>
              </Form>
            </div>
            <div className="col-md-4">
              <span style={style.login.rowNumPad.full.text}>
              </span>
            </div>
          </div>
          <br />
            <KeyPad
              pressbutton={pressbutton}
              backState={backState}
              handleSubmit={handleSubmit}
              style={style}
              labelButton={"login"}
              />
        </div>

        </>
    )
  }else {
    if (userConfig) { return <Redirect to={'/dashboard'} /> }
      return (<></>)
    }
  }
}

export default withRouter(Login);
