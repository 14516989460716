/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react'
import { Steps, Divider } from 'antd';
import Input from 'antd/es/input-number'

import { MainContext } from 'src/Context';
// import { sortNumAlpObj } from "src/utils/functions/filterFunction"
// import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { allinOne } from "src/utils/functions/filterFunction"
import Spinner from "src/layouts/components/spinner"
import { content } from "src/utils/functions/core"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { colorPrio, colorCircleNoSize } from "src/layouts/widgets/CustomLayout/index"
// import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';

const App = (props) => {
  // console.log(props)
  const context = useContext(MainContext);
  const { user, assets } = content()
  const [steps, setSteps] = useState([])
  const [children, setChildren] = useState(<Spinner />)
  const [ info, setInfo ] = useState()
  const { style } = context
  const { lang } = context.state
  const productId = props?.item?.param?.productId
  const getData = async () => {
    const result = await allinOne("ProductOperationsFlow", { id: productId })
    // console.log(result)
    if (result) {
      return result
    }
  }
// console.log(Input)
  const f = async () => {
    const d = await getData()
    if (d) {
      const i = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/InfoTextV2")
      const InfoTextV2 = i?.default
      // setSteps(d)
      let number = 0
      const step = d?.operations?.map((el1, i) => {
        const asset = assets.find(asset => asset._id === el1?.param?.assetId)
        if (el1?.param?.endDate) {
          number = i
        }
        return ({
          data: el1,
          title: el1?.param?.operation?.operationName,
          // subTitle: dateTimeFormatter("duration", el1?.param?.duration ? el1?.param?.duration : 0, "dd:hh:mm:ss"),
          description: asset?.asset_name + " - " + (lang?.[el1?.param?.statusHistory?.[0]?.name] ?? el1?.param?.statusHistory?.[0]?.name)
        })
      })
      const items = [
        {
          label: "product",
          value: d?.product?.selectedProduct?.product
        },
        {
          label: "erpId",
          value: d?.product?.selectedProduct?.erpId
        },
        {
          label: "quantity",
          value: d?.product?.amount
        },
        {
          label: "description",
          value: d?.product?.selectedProduct?.description
        },
        {
          label: "status",
          value: d?.product?.selectedProduct?.status?.statusName
        },
        {
          label: "deliveryStatus",
          value: <>{d?.product?.selectedProduct?.deliveryStatus?.label}  {colorCircleNoSize(d?.product?.selectedProduct?.deliveryStatus?.label, 20)}</>
        },
        // {
        //   label: "description",
        //   value: d?.product?.description
        // }
      ]
      // console.log(d?.product)
      setChildren(<Steps items={step} current={number} />)
      setInfo(<InfoTextV2 items={items}/>)
    }
  }

  useEffect(() => {
    f()
  }, [])

  return (
    <div style={style.OperationManipulateMo}>
      {children}
      <Divider />
      {info}
    </div>
  )
}

export default App;
