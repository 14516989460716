import React, { useContext, useState, useEffect, useRef } from 'react'
import { Tooltip } from 'antd';
import { Card } from 'react-bootstrap';
import { MainContext } from 'src/Context';
// import { formatValue } from "src/utils/functions/filterFunction"
// import { Infos } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { colorCircle, colorCircleNoSize, spinning } from "src/layouts/widgets/CustomLayout/index"
import { dateTimeFormatter } from "src/utils/functions/formater"
import Spinner from "src/layouts/components/spinner"
import { prepareColumn } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"


const InfoCardPo = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang } = context.state
  const [ state, setState ] = useState({
    // header: <Spinner />,
    // body: <Spinner />
  })
  const [item, setItem] = useState(props?.dataProps ?? [])


  useEffect(() => {
    const f = async () => {
      if (mounted.current) {
        const h = await import ('src/layouts/widgets/Companies/i4c_V2/components/Generic/HeaderCardText')
        const b = await import ('src/layouts/widgets/Companies/i4c_V2/components/Generic/CardText')
        const cardInfo = props?.dataProps?.children?.cardInfo
        const p = props?.dataProps?.param
        p.person = p?.person?.label
        p.amount = (p?.products && p?.products?.length > 0) ? p?.products.reduce((accumulator, object) => {
          return accumulator + object?.amount;
        }, 0) : 0
        const i = prepareColumn(p, cardInfo, lang)
        const body = (i)
        const header = [
          {
            label: colorCircleNoSize(item?.param?.deliveryStatus?.label),
            tooltip: item?.param?.deliveryStatus?.label,
            value: item?.param?.name,
          }
        ]

        const HeaderContent = h?.default
        const BodyContent = b?.default
        // console.log(BodyContent)
        setState({
          header: <HeaderContent p={header}/>,
          body: <BodyContent p={body}/>
        })
      }
    }
    f()
    return () => {
      mounted.current = false
      setState(null)
    }
    }, [])

    return (
      <Card>
        <Card.Header style={{ borderRadius: 0 }}>
          {state?.header}
        </Card.Header>
        <Card.Body style={{ position: "relative", padding: "10px 10px 10px 10px" }}>
          {state?.body}
        </Card.Body>
      </Card>
    )
}

export default InfoCardPo;
