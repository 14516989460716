import React from 'react';
class NoAsset extends React.Component {
  render() {
    return (
       <div style={{ textAlign: "center", paddingTop: 100 }}>
         <h3>No asset selected</h3>
       </div>
    )
  }
}
export default NoAsset
