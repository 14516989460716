import React from 'react'
import { MainContext } from 'src/Context';
import { Row, Col, Card } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { InfoCardPb, DrawerCatalog } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { allinOne, promiseToast, checkPromisStatus } from "src/utils/functions/filterFunction.js"

class MultiColPb extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this._isMounted = true
    this.getData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getData = async (props) => {
    const { querySelect, authToken } = this.context.state
    const { dataProps } = this.state
    if (dataProps && dataProps.children && dataProps.children.wofId) {

// console.log(this.state)
      // const Operation = await allinOne("Operation002", {}, authToken)
      const [Products, WorkflowCatalogPB] = await Promise.allSettled([
        // allinOne("Operations", {}, authToken),
        // allinOne("WorkOrders", {}, authToken),
        allinOne("getProductsBoard", {}, authToken),
        allinOne("WorkflowCatalogPB", { _id: dataProps.children.wofId }, authToken),
        // allinOne("getProductsBoard", {}, authToken),
        // allinOne("WorkflowCatalogPB", { _id: dataProps.children.wofId }, authToken),
      ])
      // console.log(
      //   checkPromisStatus(Operations),
      //   checkPromisStatus(WorkOrder),
      //   checkPromisStatus(Products),
      //   checkPromisStatus(WorkflowCatalogPB)
      // )
      // console.log(checkPromisStatus(getProductsBoard))
// console.log(checkPromisStatus(Products))
      this.setState(Products && WorkflowCatalogPB && {
        // Operations: checkPromisStatus(Operations),
        // WorkOrder: checkPromisStatus(WorkOrders),
        Products: checkPromisStatus(Products),
        OPWorkflow: checkPromisStatus(WorkflowCatalogPB),
      }, () => this.setData())



      // Promise.all([OPWorkflow, WorkOrder]).then((res) => {
      //   if (res.includes(undefined)) {
      //     console.log("undefined")
      //   } else {
      //     if (res) {
      //       // eslint-disable-next-line
      //       res.map((el, i) => {
      //         console.log(el)
      //         const key = Object.keys(el)[0]
      //         // console.log(key)
      //         if (key) {
      //           this.setState({
      //             [key]: el[key],
      //           }, () => this.setData())
      //         }
      //       })
      //     }
      //   }
      // })
    } else {
      this.setState({ board: "Missing" })
    }
    // if (dataProps && dataProps.dataProps && dataProps.dataProps.props) {
    //   // const assetId = dataProps.dataProps.props._id
    //
    // } else {
    //   console.log("missing worklow id")
    // }
  }

  setData = () => {
    const { OPWorkflow, WorkOrder, Operations, Products } = this.state
    const { undefObjHandler } = this.context.state
    if (OPWorkflow && Products) {
      // const [Operations] = await Promise.allSettled([
      //   allinOne("Operations", { id:  }, authToken),
      // ])
      // console.log(OPWorkflow)
      // const _Operation = Operation.filter((f, i) => f.param.assetId === assetId)
      // console.log(_Operation)
      let _board = []
      // console.log(OPWorkflow)
      // console.log(Products)
      undefObjHandler(OPWorkflow, "steps") && OPWorkflow.param.steps.forEach((el, i) => {
        // console.log(checkPromisStatus(Operations))
        // const findStatusOp = Operations.filter((f, j) => f.param.workOrderId === )
        // const findStatusOp = Operations.filter((f, j) => (f.param.status && f.param.status._id) === el.id)


        const findStatusP = Products.filter((f, j) => (f.selectedProduct.status && f.selectedProduct.status.status) === el.id)

        // const findStatusOp = Operations.filter((f, j) => console.log(f.param.productId))

        // console.log(findStatusP)

          // .selectedProduct.status

        // const findProduct = Products.filter((f, j) => console.log(f))
        // console.log(el)
        if (findStatusP) { _board.push({ ...el, items: findStatusP }) } else { _board.push(el) }
        // console.log(el)
        // const _Operations = Operations.filter((f) => f.param.productId === )
        // const _Products = Products.filter((f) => console.log(f._id) )
        // f.param.productId ===
        // f.param.status._id
        // _board.push(el)
      });
      // console.log(_board)
      this.setState({
        _board,
        // indexKey: Date.now(),
        // assetId
      })
    } else {
      console.log("missing worklow")
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    // console.log({source, destination, droppableSource, droppableDestination})
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  onDragEnd = (result) => {
    const { source, destination } = result;
    const { _board } = this.state
    if (!destination) { return }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    // console.log(operation)
    if (sInd === dInd) {
      const items = this.reorder(_board[sInd].items, source.index, destination.index);
      const newBoard = [..._board];
      const reorder = items.map((el, i) => {
        return ({ ...el, param: { ...el.param, status: { ...el.param.status, _i: i } }, })
      })
      newBoard[sInd].items = reorder;
      if (this._isMounted) { this.setState({ _board: newBoard }) }
      // const { queryPost, authToken } = this.context.state
      this.setDrag(reorder)
    } else {
      const result = this.move(_board[sInd].items, _board[dInd].items, source, destination);
      let newBoard = [..._board];
      if (newBoard[sInd].allowBackStep === true || sInd <= dInd) {
        newBoard[sInd].items = result[sInd].map((el, i) => { return ({ ...el, param: { ...el.param, status: { ...el.param.status, _id: _board[sInd].id } } }) });
        newBoard[dInd].items = result[dInd].map((el, i) => { return ({ ...el, param: { ...el.param, status: { ...el.param.status, _id: _board[dInd].id } } }) });
        if (this._isMounted) { this.setState({ _board: newBoard }) }
        // const { queryPost, authToken } = this.context.state
        let _items = []
        newBoard.forEach((el, i) => {
          if (el.items.length > 0) {
            el.items.forEach((item, j) => {
              _items.push(item)
            })
          }
        })
        this.setDrag(_items)
      }
    }
  }

  openOp = async (item) => {
    // console.log(item)
    const { authToken } = this.context.state
    const [OperationsProduct, workflowsProduct] = await Promise.allSettled([
      allinOne("OperationsProduct", { id: item._id }, authToken),
      allinOne("workflowsProduct", { id: "2f0cb05a-59c6-40ae-a0cd-c1cc51249a77" }, authToken),
    ])
    if (OperationsProduct && workflowsProduct) {
      this.setState({
        indexKey: Math.random(),
        showModal: true,
        drawerComp: "OperationManipulatePb",
        item: {
          ...item,
          operation: checkPromisStatus(OperationsProduct),
          workflowsProduct: checkPromisStatus(workflowsProduct)
        }
      })
    }
  }

  callback = () => {

  }

  render() {
    const { lang, undefObjHandler } = this.context.state
    const { _board, showModal, indexKey, OPWorkflow } = this.state
    // console.log(this.state)
    // const getItemStyle = (isDragging, draggableStyle) => ({
    //   // some basic styles to make the items look a bit nicer
    //   // userSelect: "none",
    //   // padding: 16,
    //   marginBottom: 5,
    //   // width: 200,
    //   // margin: `0 0 ${8}px 0`,
    //   // borderStyle: "solid",
    //   // borderWidth: "1px",
    //   // change background colour if dragging
    //   background: isDragging ? "lightgreen" : "white",
    //   // styles we need to apply on draggables
    //   ...draggableStyle
    // });
    // const getListStyle = isDraggingOver => ({
    //   background: isDraggingOver ? "lightblue" : "",
    //   // padding: 8,
    //   // marginRight: 8,
    //   width: "auto",
    //   // minHeight: 690
    // });
    // console.log(_board)
    if (_board && _board.length > 0) {
      return (
        <Row>
          <DragDropContext onDragEnd={this.onDragEnd}>
            {_board.map((el, ind) => {
              const lengthInd = _board.length - 1
              const count = OPWorkflow ? OPWorkflow.param.maxCount : false
              return (
                <Col key={ind} style={{ color: "", padding: 0, borderRight: ind < lengthInd && "1px solid #e7e7e7", maxWidth: "33.3%" }}>
                  <Card style={{ border: 0, borderRadius: 0 }}>
                    <center>
                      <Card.Header style={{ border: 0, borderRadius: 0, background: undefObjHandler(el, "color") ? el.kpi.color : "", color: "rgb(255, 255, 255)" }}>
                        {el ? el.name : "props missing !!!"}
                        <span style={{ float: "right" }}>
                          {(count && count > 0) ? (el.items ? (((count > el.items.length) ? el.items.length : count) + "/" + el.items.length) : "") : el.items ? el.items.length : ""}
                        </span>
                      </Card.Header>
                      {el.items && el.items.length > 0 && el.items.map((pr, j) => {
                        if (j < count) {
                          return(
                            <div key={j} style={{ borderRadius: 0, padding: 0 }} onClick={() => this.openOp(pr)}>
                              <InfoCardPb callback={this.callback} dataProps={{ ...pr }} />
                            </div>
                          )
                        }else if (count === 0 || !count) {
                          return(
                            <div key={j} style={{ borderRadius: 0, padding: 0 }} onClick={() => this.openOp(pr)}>
                              <InfoCardPb callback={this.callback} dataProps={{ ...pr }} />
                            </div>
                          )
                        }
                      })}
                    </center>
                  </Card>
                </Col>
              )
            })}
          </DragDropContext>
          {showModal && <DrawerCatalog key={indexKey} callback={this.callback} dataProps={{ ...this.state }} />}
        </Row>
      )
    } else return <div></div>
  }
}

export default MultiColPb;
