import React from 'react';
import { MainContext } from '../../Context';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';

class Logout extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () { return ( <> </> ) }
}

export default withRouter(Logout);
