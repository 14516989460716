import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const EditorInfo = (props) => {
  const context = useContext(MainContext);
  const [ info, setInfo ] = useState(props?.dataProps)

  const editorState = (info) => {
    const blocksFromHtml = htmlToDraft(info);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState)
  }
// console.log(info)
  return (
    <div style={{ padding: 10 }}>
      <Editor
        editorStyle={{ cursor: "pointer", overflow: "hidden" }}
        readOnly
        editorState={editorState(info)}
        toolbarHidden
        />
    </div>
  )
}

export default EditorInfo;
