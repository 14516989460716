import { Col, Row } from 'react-bootstrap';
import { Button, Input, Steps, Divider, Tabs } from 'antd';
import { singleRow, colorCircle } from "src/layouts/widgets/CustomLayout/index"
import * as comp from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { dateTimeFormatter } from "src/utils/functions/formater.js"
import { formatValue, undefObjHandler, filterObjArr } from "src/utils/functions/filterFunction.js"

const ProductInfo = (item, lang, WorkOrder) => {
  const s = item.selectedProduct
  const product = s && s.product
  const description = s && s.description
  const label = s && s.deliveryStatus.label


  const deliveryStatus = () => <span> {label} {colorCircle(label)} </span>
const w = WorkOrder
// console.log(w)
const keys = undefObjHandler(item, "selectedProduct") && Object.keys(item.selectedProduct)
const showKeys = ["product", "deliveryStatus", "description"]
const res = keys.map((el, i) => ({ label: el, value: formatValue(item.selectedProduct[el]) }))
const filter = keys.map((el, i) => showKeys.find((f) => f === el) && ({ label: lang[el] ? lang[el] : el, value: formatValue(item.selectedProduct[el]) }))
const _filter = filter.filter((el) => el && (el))
// const filter = keys.map((el, i) => {
//   const _showKeys = showKeys.find((f) => f === el)
//   if (_showKeys) {
//     return formatValue(item.selectedProduct[el])
//   }
// })

// console.log(filterObjArr(lang, item.selectedProduct, "selectedProduct", ["product", "deliveryStatus", "description"]))
// console.log(keys)
// console.log(filter)
// showKeys.find((f) => f === el)
// const _res = res.map((el) => showKeys.filter((f) => el === f))
// console.log(_res)
// ({ label: lang[el] ? lang[el] : el, value: formatValue(item.selectedProduct[el]) })
// console.log(_filter)
const columns = [
  {
    key: 1,
    title: lang["product"] ? lang["product"] : "product",
    comp: 'BasicInfo',
    style: { fontSize: 20 },
    // data: _filter
    data: [
      { label: lang["name"] ? lang["name"] : "name", value: product },
      { label: lang["description"] ? lang["description"] : "description", value: description },
      { label: lang["deliveryStatus"] ? lang["deliveryStatus"] : "deliveryStatus", value: deliveryStatus(item) },
    ]
  },
  {
    key: 2,
    // title: lang["OperationInfo"] ? lang["OperationInfo"] : "OperationInfo",
    comp: 'BasicInfo',
    style: { fontSize: 20 },
    data: [
      { label: lang["workOrder"] ? lang["workOrder"] : "workOrder", value: w && w.param.name },
      { label: lang["orderNumber"] ? lang["orderNumber"] : "orderNumber", value: w && w.param.orderNumber },
      { label: lang["dueDate"] ? lang["dueDate"] : "dueDate", value: w && dateTimeFormatter("date", w.param.dueDate, "dd.mm.yyyy hh:mm:ss") },
    ]
  },
  // {
  //   key: 3,
  //   // title: lang["CurrentStatus"] ? lang["CurrentStatus"] : "CurrentStatus",
  //   comp: 'BasicInfo',
  //   style: { fontSize: 20 },
  //   // "description": "stest",
  //   // "dueDate": 1681976670000,
  //   // "name": "stest",
  //   // "orderNumber": "stest",
  //   data: [
  //     { label: "label", value: "value" },
  //     { label: "label", value: "value" },
  //   ]
  // },
  // {
  //   key: 4,
  //   // title: lang["validationStep"] ? lang["validationStep"] : "validationStep",
  //   // comp: 'ValidationInfo',
  //   comp: 'BasicInfo',
  //   style: { fontSize: 20 },
  //   data: [
  //     { label: "label", value: "value" },
  //     { label: "label", value: "value" },
  //   ]
  // },
]
return(
  <Row>
    {columns.map((el, i) => {
      const Component = comp[comp[el.comp] ? el.comp : "Empty"]
      return (
        <Col style={{ padding: 0, fontSize: 20 }} key={i}>
          <Component key={Math.random()} dataProps={{ ...el }} />
        </Col>
      )
    })}
  </Row>
)
}

const ProductionProcedure = (item, undefObjHandler, lang, OPWorkflow) => {
  const stepsProduction = undefObjHandler(item, "productionProcedure") ? item?.selectedProduct?.productionProcedure?.steps: false
  const operations = item.operation ? item.operation : false
  const left = { width: "50%", span: { float: "right", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%", fontWeight: "bold" }  }
  const right = { width: "50%", span: { float: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" } }
  const findStatus = (OPWorkflow && OPWorkflow?.param?.steps?.length > 0) && OPWorkflow?.param?.steps.find((f) => f.id === item?.selectedProduct?.status?.status)
  const _item = operations && operations.map((el) => ({ description: lang[el.name] ? lang[el.name] : el.name,  title: el?.param?.operation?.operationName }))
  // {OPWorkflow && OPWorkflow.param.steps.length > 0 && OPWorkflow.param.steps.map((el, i) => {
    //   console.log(el.id, item.selectedProduct.status.status)
    //
    // })}
    console.log(_item)
    return(
      <div>
        <Row>
          <span></span>
          <Steps
            progressDot
            current={(findStatus.key - 1)}
            status="error"
            items={_item}
            />
        </Row>
      </div>
    )
  }

  const ValidationProcedure = (item, undefObjHandler, lang) => {
    // const stepsProduction = undefObjHandler(item, "productionProcedure") ? item.selectedProduct.productionProcedure.steps: false
    const stepsValidation = undefObjHandler(item, "validationProcedure") ? item?.selectedProduct?.validationProcedure?.steps : false
    const operations = item.operation ? item.operation : false
    const left = { width: "50%", span: { float: "right", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%", fontWeight: "bold" }  }
    const right = { width: "50%", span: { float: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" } }
    // console.log(operations)
    return(
      <div>
        {operations && operations.map((el, i) => {
          const columns = item?.workflowsProduct?.columns.map((co, j) => ({ ...co, key: j }))
          const findSteps = columns.find((f) => f.id === item?.selectedProduct?.status?.status)
          const _item = item.workflowsProduct.columns.map((el) => ({ description: lang[el.name] ? lang[el.name] : el.name,  }))
          const findCurrent = item?.workflowsProduct?.columns?.filter

          return (
            <Row key={i}>
              <span>{el?.param?.operation?.operationName}</span>
              <Steps
                progressDot
                current={(findSteps?.key - 1)}
                status="error"
                items={_item}
                />
            </Row>
          )
        })}
      </div>
    )
  }

  export {
    ProductInfo,
    ProductionProcedure,
    ValidationProcedure
  }
