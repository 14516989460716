/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Select, Button } from 'antd'
import { withRouter, useLocation } from "react-router-dom";
import { Card, Col, Row } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DrawerComp } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { MainContext } from 'src/Context';
import { allinPut, msg, allinOne, destructUrl, urlString, sortingAsc } from "src/utils/functions/filterFunction"
import { setLocal, isLocal } from "src/utils/functions/core"
import { spinning } from "src/layouts/widgets/CustomLayout/index"
import { socket } from 'src/utils/socket';
import { checkStatus, setWfTasks, reorder, move } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"


import InfoCardOp from 'src/layouts/widgets/Companies/i4c_V2/components/OpV2/InfoCardOp';

const OpBoardView = (props) => {
  const mounted = useRef(true);
  const location = useLocation()
  const context = useContext(MainContext);
  const { secFormat, userConfig, authToken, lang, assetId } = context.state
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
// console.log(props)
  const { user } = userConfig
  const [board, setBoard] = useState([])
  const [items, setItems] = useState()
  const [_items, _setItems] = useState()
  const [_count, _setCount] = useState(0)
  const [prop, setProp] = useState()
  const [clear, setClear] = useState(false)
  const [ onPause, setOnPause ] = useState()
  const op = props?.dataProps?._Operation001
  const [options, setOptions] = useState([
    { label: lang?.["notReady"] ?? "notReady", value: "notReady" },
    { label: lang?.["ready"] ?? "ready", value: "ready" },
    { label: lang?.["inPreparation"] ?? "inPreparation", value: "inPreparation" }
  ])
  const [tasks, setTasks] = useState(op)
  const [_tasks, _setTasks] = useState(op)
  const [search, setSearch] = useState(false)
  const [_search, _setSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [forceSearch, setForceSearch] = useState(false)
  const [inputValue, setInputValue] = useState()
  const [_inputValue, _setInputValue] = useState()
  const [selectValue, setSelectValue] = useState()
  const [ projectOptions, setProjectOptions ] = useState([])
  const [ selectProjectValue, setSelectProjectValue ] = useState()
  const [ input, setInput ] = useState()
  const [ selectedSearch, setSelectedSearch ] = useState(false)
  const [ usersOnAsset, setUsersOnAsset ] = useState()
  // return Object.values(obj).some(value => (typeof value === "string") ? value.toLowerCase().includes(search.toLowerCase()) : null);
  // const [boardMove, setBoardMove] = useState()
  // console.log(props)
  const get = props?.children?.get ? props?.children?.get : "Operation007"
  const [_ids, _setIds] = useState()
  const getData = async () => {
    if (true) {
      const obj = destructUrl(location.search)
      const result = await allinOne(get, { assetId, ...obj })
      if (result) {
        return result
      }
    }
  }

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  // console.log(windowHeight)
  const f = async () => {
    // if (mounted.current) {
    // TODO need solution

    if (true) {
      const wf = props?.dataProps?._WorkflowCatalog
      const _d = await getData()
      const d = await setWfTasks(wf, _d?.operations)
      // console.log(props.dataProps._Products)
      const sorted = sortingAsc(_d?.bo, ["label"])
      // console.log(_d)
      setProjectOptions(sorted)
      setUsersOnAsset(_d?.usersOnAsset)
      // const search = _urlParam?.search
      // if (search) {
      //   const obj = Object.keys(_urlParam)
      //   const property = obj?.[0]
      //   _setInputValue({ type: property, value: search })
      //   setInputValue(search)
      // } else {
      //   // console.log(d)
      //   // console.log(d)
      //   // setLocal("boardOp", d)
      // }
      setBoard(d)
      let count = 0
      d.forEach((el, i) => {
        count = (count < el.items.length) ? el.items.length : count
        _setCount(count)
      });
    }
    setClear(false)
  }
  // console.log(props)
  const socketOn = () => {
    socket.connect()
    socket.on("operationStatus", (el) => {
      // console.log(el)
      const _urlParam = {
        ...destructUrl(window.location.search),
        tr: Date.now()
      }
      const search = urlString(_urlParam)
      props.history.push({ search })
      setClear(true)
    })
    socket.on("operationBoardRefresh", (el) => {
      setTimeout(() => {
        f()
      }, 2000)
    })
  }

  useEffect(() => {
    f()
    socketOn()
    return () => {
      mounted.current = false
      // socket.off("sentToTopic")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataProps])

  // NOTE temporary solution
  const checkKeyValue = (e) => {
    if (e?.statusHistory) {
      return e?.statusHistory
    } else {
      return e?.statusHistory
    }
  }

  const getStatusHistory = (r, el, status) => {
    const _user = {
      _id: user.userId,
      user: user.firstName + " " + user.lastName
    }
    if (r.destination.index === r.source.index && r.destination.droppableId === r.source.droppableId) {
      console.log("same position")
    } else {
      let _statusHistory = []
      const now = Date.now()
      if (el.param.statusHistory && el.param.statusHistory.length > 0) {
        _statusHistory.push({
          startDate: now,
          _id: status.id,
          name: status.name,
          user: _user,
          statusType: status.kpi.value,
          duration: 0
        })
        el.param.statusHistory.forEach((el, i) => {
          _statusHistory.push({
            ...el,
            endDate: (i === 0) ? now : (el.endDate ? el.endDate : 0),
            duration: (i === 0) ? secFormat(now - el.startDate) : el.duration
          })
        })
      } else {
        _statusHistory.push({
          startDate: now,
          _id: status.id,
          name: status.name,
          user: _user,
          statusType: status.kpi.value,
          duration: 0
        })
      }
      return _statusHistory
    }
  }

  const previousOperation = (el) => {
    return (el?.param?.prevOperations && el?.param?.prevOperations?.length > 0) ? {
      ...el?.param?.prevOperations?.[0]
    } : null
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) { return }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    let prepareNextOperation = {}
    if (sInd === dInd) {
      const items = reorder(board[sInd].items, source.index, destination.index);
      const newBoard = [...board];
      const reorder1 = items.map((el, i) => {
        return ({ ...el, param: { ...el.param } })
      })
      newBoard[sInd].items = reorder1;
      setDrag(reorder1)
    } else {
      const _result = move(board[sInd].items, board[dInd].items, source, destination, draggableId, board, props, lang);
      let newBoard = [...board];
      if (_result) {
        if (newBoard[dInd].allowForwardStep === true) {
          if (newBoard[sInd].allowBackStep === true || sInd <= dInd && _result) {
            newBoard[sInd].items = _result[sInd].map((el, i) => {
              return ({
                ...el,
                param: {
                  ...el.param,
                  status: {
                    ...el.param.status,
                    _id: board[sInd].id
                  },
                }
              })
            });

            newBoard[dInd].items = _result[dInd].map((el, i) => {
              const now = Date.now()
              const last = dInd + 1
              const findId = result.draggableId === el._id
              const s = getStatusHistory(result, el, board[dInd]).reduce((acc, curr) => acc + curr.duration, 0)
              const statusId = newBoard[dInd]?.id
              const startPreperationId = props?.children?.startPreperationId
              const startProductionId = props?.children?.startProductionId
              const stopProductionId = props?.children?.stopProductionId
              const actualSetupStartDate = el?.param?.actualSetupStartDate
              const actualProductionStartDate = el?.param?.actualProductionStartDate
              const actualSetupEndDate = el?.param?.actualSetupEndDate
              const actualProductionEndDate = el?.param?.actualProductionEndDate
              const setupDuration = el?.param?.setupDuration
              const productionDuration = el?.param?.productionDuration
              const _actualSetupStartDate = (statusId === startPreperationId) ? now : (actualSetupStartDate ? actualSetupStartDate : null)
              const _actualSetupEndDate = (statusId === startProductionId) ? now : (actualSetupEndDate ? actualSetupEndDate : null)
              const _actualProductionStartDate = (statusId === startProductionId) ? now : (actualProductionStartDate ? actualProductionStartDate : null)
              const _actualProductionEndDate = (statusId === stopProductionId) ? now : (actualProductionEndDate ? actualProductionEndDate : null)

              const round = value => {
                return (Math.floor(Number(value) / 1000))
              }
              return ({
                ...el,
                param: {
                  ...el.param,
                  status: {
                    ...el.param.status,
                    _id: board[dInd].id
                  },
                  statusHistory: (el._id === result.draggableId) ? getStatusHistory(result, el, board[dInd]) : el.param.statusHistory,
                  endDate: (last === board.length && findId) ? now : el.param.endDate,
                  duration: s,
                  // duration
                  setupDuration: setupDuration ? setupDuration : (_actualSetupEndDate && _actualSetupStartDate) ? round(_actualSetupEndDate - _actualSetupStartDate) : null,
                  // actualDuration: actualDuration ? actualDuration : (_actualProductionEndDate && _actualProductionStartDate) ? (_actualProductionEndDate - _actualProductionStartDate) : null,
                  productionDuration: productionDuration ? productionDuration : (_actualProductionEndDate && _actualProductionStartDate) ? round(_actualProductionEndDate - _actualProductionStartDate) : null,
                  // actual setup
                  actualSetupStartDate: _actualSetupStartDate,
                  actualSetupEndDate: _actualSetupEndDate,
                  // actual production
                  actualProductionStartDate: _actualProductionStartDate,
                  actualProductionEndDate: _actualProductionEndDate,
                }
              })
            });
            let _items = []
            newBoard.forEach((el, i) => {
              if (el.items.length > 0) {
                el.items.forEach((item, j) => {
                  _items.push(item)
                })
              }
            })
            setDrag(_items, result)
          }
        } else {
          msg("info", (lang?.["plaeseUseDrawer"] ?? "pleaseUseDrawer"), 2)
        }
      }
    }
  }

  const socketCall = () => {
    socket.connect();
    socket.emit("sentToTopic", {
      topic: "operationStatus",
      trigger: true,
      data: "items",
      exceptions: "operationStatus"
    })
  }

  const setDrag = async (items, _result) => {
    const _board = board.map((el) => ({ id: el?.id, kpi: el?.kpi, name: el?.name, kpi: el?.kpi }))
    const item = items.find((f) => f._id === _result?.draggableId)
    const _children = {
      eventConfigType: props?.children?.eventConfigType,
      finishStatusId: props?.children?.finishStatusId,
      startPlannedId: props?.children?.startPlannedId,
      startPreperationId: props?.children?.startPreperationId,
      startProductionId: props?.children?.startProductionId,
      stopProductionId: props?.children?.stopProductionId,
    }
    // console.log(props)
    const kpis = props?.dataProps?.board?.map((el) => (el?.kpi))
    const assetId = props?.dataProps?.assetId
    const currentStatus = board.find((el) => (el.key === (Number(_result?.destination?.droppableId))))
    const findNextStatus = board.find((el) => (el.key === (Number(_result?.destination?.droppableId) + 1)))
    const result = await allinPut("UpdateItemParamStatus", {
      assetId,
      kpis,
      id: item?._id,
      param: item?.param,
      nextOperation: item?.param?.nextOperation,
      currentStatus,
      findNextStatus,
      finishStatusId: props?.children?.finishStatusId,
      children: _children,
      board: board.map((el) => ({ id: el?.id, kpi: el?.kpi, name: el?.name })) // send only necesary values
    })
    if (result?.success) {
      const updatedItems = items.map((el, i) => {
        if (item?._id === el?._id) {
          el.param.workers = result?._updateUserTime?.workers
          return el
        } else {
          return el
        }
      });
      _setItems(updatedItems)
      socketCall()
    }
  }

  useEffect(() => {
    if (_items) {
      const it_ems = async () => {
        const wf = props?.dataProps?._WorkflowCatalog
        const d = await setWfTasks(wf, _items)
        setBoard(d)
        _setItems(null)
      }
      it_ems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_items])

  const callback = () => {

  }
  // console.log(element?.style?.height)
  const getListStyle = (isDraggingOver, count) => {
    const element = document.getElementById('element');
    return ({
      background: isDraggingOver ? "lightblue" : "",
      width: "auto",
      // maxWidth: 200,
      // height: "auto",
      height: (windowHeight - 220),//(element?.style?.height),
      // height: (200 * _count) + 10,
      overflowY: "auto"
    })
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    marginBottom: 5,
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle
  });

  const updateItem = () => socketCall()
  const drawerStyles = {
    header: {
      display: "none"
      // borderBottom: `1px solid ${token.colorPrimary}`,
    },
  };
  // console.log(props)
  const openOp = (item, el) => {
    setProp({
      placement: "bottom",
      open: true,
      styles: drawerStyles,
      height: "100%",
      destroyOnClose: true,
      comp: "OperationManipulateTabs",
      updateItem: updateItem,
      children: props?.children,
      onClose: (e) => {
        // console.log(props)
        // setIsLoading(true)
        // console.log(e)
        setProp({
          placement: "bottom",
          open: false,
          styles: drawerStyles,
          height: "100%",
          destroyOnClose: true,
        })
        props.callback(true)
      },
      item,
      status: el,
      board: props.dataProps.board,
      _WorkflowCatalogPB: props.dataProps._WorkflowCatalogPB
    })
  }

  const setIds = async (e) => {
    const t = (e && e.length > 0) ? op.filter(el =>
      ((el?.param?.operation?.operationName) ? el?.param?.operation?.operationName.toLowerCase().includes(e.toLowerCase()) : null) ||
      ((el?.param?.clientBuyer) ? el?.param?.clientBuyer.toLowerCase().includes(e.toLowerCase()) : null) ||
      ((el?.param?.erp_OrderNo) ? el?.param?.erp_OrderNo.toLowerCase().includes(e.toLowerCase()) : null) ||
      // ((el?.param?.productName) ? el?.param?.productName.toLowerCase().includes(e.toLowerCase()) : null) ||
      ((el?.param?.productName) ? el?.param?.productName.toLowerCase().includes(e.toLowerCase()) : null)
    ) : op
    const ids = await t.map((el) => (el?._id))
    return ids
  }

  const setIdsSelect = async (e) => {
    const t = (e && e.length > 0) ? op.filter(el => el?.param?.materialStatus === e) : op
    const ids = await t.map((el) => (el?._id))
    return ids
  }

  const handleSearch = async (e) => {
    // console.log(e)
    if (e) {
      _setInputValue({ type: "search", value: e })
      setSelectValue("")
      setInputValue(e)
    } else {
      _setInputValue({ type: "", value: "" })
      setSelectValue("")
      setInputValue("")
      props.history.push({ search: "" })
    }
  }

  const handleSelect = (e) => {
    _setInputValue({ type: "search", value: e })
    setSelectValue(e)
    setInputValue("")
  }

  const handleSelectProject = (e) => {
    // console.log(e)
    // _setInputValue({ type: "search", value: e })
    // setSelectProjectValue(e)
    // setInputValue("")
    // console.log(board)


  }

  const getOp = async (ids) => {
    const result = await allinOne("Operation007", { type: "Operation", ids })
    // console.log(result)
    if (result) {
      setSearch(result?.operations)
    }
  }

  const se = async (search) => {
    switch (search) {
      case "notReady": await getOp(await setIdsSelect(search)); break;
      case "inPreparation": await getOp(await setIdsSelect(search)); break;
      case "ready": await getOp(await setIdsSelect(search)); break;
      default: await getOp(await setIds(search)); break;
    }
  }

  useEffect(() => {
    const _urlParam = destructUrl(location.search)
    const search = _urlParam?.search
    if (search) {
      se(search)
    }
  }, [location.search])

  useEffect(() => {
    if (search) {
      const s = async () => {
        const wf = props?.dataProps?._WorkflowCatalog
        const d = await setWfTasks(wf, search)
        setBoard(d)
      }
      s()
    }
  }, [search])

  useEffect(() => {
    if (clear) {
      mounted.current = true
      f()
    }
  }, [clear])

  const onClickCardHeaderPause = async(item) => {
    const _onPause = item?.param?.onPause
    const id = item?._id
    // setOnPause(!item?.param?.onPause)
    // console.log({
    //   user: {
    //      _id: user?.userId,
    //      user: user?.firstName + " " + user?.lastName
    //    }
    //   // onPause: item.param.onPause,
    //   // item,
    //   // items,
    //   // _items
    // })
    const _u = {
      _id: user?.userId,
      user: user?.firstName + " " + user?.lastName
    }
    const result = await allinPut("updateOpOnPause", { id, onPause: !_onPause, user: _u })
    // console.log(result)
    setTimeout(() => {
      setClear(true)
      socketCall()
    }, 2000)

    // console.log(e)
  }

  const clickSearch = async (type) => {
    const _urlParam = {
      [_inputValue?.type]: _inputValue?.value,
      tr: Date.now()
    }
    const search = (_inputValue?.value && _inputValue?.value?.length > 0) ? urlString(_urlParam) : ""
    if (!search) {
      setClear(true)
    }
    props.history.push({ search })
  }

  const clearSearch = async () => {
    _setInputValue("")
    setSelectValue("")
    setInputValue("")
    setClear(true)
    props.history.push({ search: "" })
  }

  const s = { width: 200, borderRadius: 0, marginBottom: 10, marginRight: 10 }
  const b = { marginRight: 10 }
  const _urlParam = destructUrl(location.search)
  const checkUrl = _urlParam?.search
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      clickSearch()
    }
  };

  // useEffect(() => {
  //   // const [ selectedSearch, setSelectedSearch ] = useState(false)
  //   if (search) {
  //     console.log(search)
  //     // const v = Object.values(obj).some(value => (typeof value === "string") ? value.toLowerCase().includes(selectedSearch.toLowerCase()) : null)
  //
  //     // setSelectedSearch(false)
  //   }
  // }, [search])


  const statuses = {
    startPreperationId: props?.children?.startPreperationId,
    startProductionId: props?.children?.startProductionId,
    stopProductionId: props?.children?.stopProductionId
  }
  // console.log(props?.children)
  let date = Date.now()
  let count = 0
  let loading = false
  // console.log(props)




  const handleKeyPressButton = (e) => {
    // console.log(input)
    const _urlParam = {
      v: input
      // [_inputValue?.type]: _inputValue?.value,
      // tr: Date.now()
    }
    const search = urlString(_urlParam)
    props.history.push({ search })
  };
  const handleKeyPressEnter = (e) => {
    if (e.key === 'Enter') {
      // console.log(input)
      const _urlParam = {
        v: input
        // [_inputValue?.type]: _inputValue?.value,
        // tr: Date.now()
      }
      const search = urlString(_urlParam)
      props.history.push({ search })
    }
  };

  useEffect(() => {
    f()
  }, [window.location.search])

  return (
    <div>
        <Row>
          <div style={{ position: "sticky", top: 92, zIndex: 3, background: "#ffffff" }}>
            {/*
            <Select
              // allowClear
              style={s}
              placeholder={lang?.["status"] ?? "status"}
              options={options}
              value={selectValue}
              onKeyDown={handleKeyPressEnter}
              onChange={(e, i) => setInput(e)}
              />
            */}
            <Select
              allowClear
              style={s}
              placeholder={lang?.["project"] ?? "project"}
              options={projectOptions}
              value={selectProjectValue}
              showSearch={true}
              onKeyDown={handleKeyPressEnter}
              onChange={(e, i) => setInput(e)}
              />
            <Input
              // allowClear
              style={s}
              value={inputValue}
              placeholder={lang?.["searchOperation"] ?? "searchOperation"}
              onKeyDown={handleKeyPressEnter}
              onChange={(e) => setInput(e?.target?.value)}
              />
            <Button
              style={b}
              ghost={false}
              type="primary"
              onClick={() => handleKeyPressButton()}>
              {lang?.["search"] ?? "search"}
            </Button>
            {checkUrl &&
              <Button
                style={b}
                ghost={false}
                type="primary"
                danger={true}
                onClick={() => clearSearch()}>
                {lang?.["clearSearch"] ?? "clearSearch"}
              </Button>
            }
          </div>
          <DragDropContext key={JSON.stringify(board)} onDragEnd={onDragEnd}>
            {(board?.length > 0) && board.map((el, ind) => {
              let limit = el?.limit
              const wf = props?.dataProps?._WorkflowCatalog
              const count = wf ? wf.param.maxCount : false
              const lengthInd = board.length - 1
              const dragLimit = el?.dragLimit
              const boardLength = (100 / board?.length).toString()
              const maxWidth = boardLength + "%"
              const pauseStatus = el.items.filter((f) => f?.param?.onPause)
              el.items = el.id === props?.children?.finishStatusId ? el.items.sort(function (a, b) {return b?.param?.actualProductionEndDate - a?.param?.actualProductionEndDate}) : el.items
              if (limit) {
                // el.items = el.items.slice(0, limit)
                // console.log({
                //   length: pauseStatus?.length,
                //   limit
                // })
                el.items = el.items.slice(0, (pauseStatus ? (limit + pauseStatus?.length) : limit))
                limit = pauseStatus ? (limit + pauseStatus?.length) : 0
              }
              return (
                <Col key={ind} style={{ color: "", padding: 0, borderRight: ind < lengthInd && "1px solid #e7e7e7", maxWidth }}>
                  <Card style={{ border: 0, borderRadius: 0, position: "sticky", top: 162, zIndex: 2 }}>
                    <center>
                      <Card.Header style={{ border: 0, borderRadius: 0, background: el?.kpi?.color, color: "rgb(255, 255, 255)" }}>
                        {el ? (lang?.[el.name] ?? el.name) : "props missing !!!"}
                        <span style={{ float: "right" }}>
                          {el.items.length + "/" + ((limit > 0) ? limit : "∞")}
                        </span>
                      </Card.Header>
                    </center>
                  </Card>
                  <Droppable key={JSON.stringify(ind)} droppableId={`${ind}`}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver, el.items.length)}
                        {...provided.droppableProps}>
                        {el.items.map((item, index) => {
                          // console.log(item)
                          const onPause = item?.param?.onPause
                          return(
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              isDragDisabled={onPause}
                              index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}>

                                  <div key={JSON.stringify(item.param)} style={{ borderRadius: 0 }}>
                                    <InfoCardOp
                                      key={JSON.stringify(onPause)}
                                      onPause={onPause}
                                      onClickCardHeaderPause={onClickCardHeaderPause}
                                      onClickCardBody={() => onPause ? null : ((item.param && item.param.completed) ? null : openOp(item, el))}
                                      statuses={statuses}
                                      item={item}
                                      usersOnAsset={usersOnAsset}
                                      products={props.dataProps._Products}
                                      cardInfo={props?.children?.cardInfo}
                                      board={props?.dataProps?.board}/>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
              )
            })}
          </DragDropContext>
          <DrawerComp callback dataProps={prop} />
        </Row>
      </div>
    )
  }

  export default withRouter(OpBoardView);
  // {dragLimit ? limit : ((limit && (limit > 0)) ? (el.items ? (((limit > el.items.length) ? el.items.length : limit) + "/" + el.items.length) : "") : el.items ? el.items.length : "")}
  // if (index < limit) {
  // } else if (limit === 0 || !limit) {
  //   return filterCards(item, index, el)
  // }
