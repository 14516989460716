import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button, Badge, Card, Col, Row } from 'antd';
// import { Card, Col, Row } from 'react-bootstrap';
import { OpDetail } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'

class Operations extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.dataProps,
      hightLight: false
    }
  }


  // componentDidMount = async() => { await this.getData() }
  //
  // getData = () => {
  //   const { dataProps } = this.props
  //   this.setState({ ...dataProps })
  // }
  // POST -- update event data
  // handleTiming = (type) => {
  //
  // }

  callback = (e) => {
    // console.log(e)
    this.setState({
      indexKey: Math.random()
    })
    this.props.callback({ from: "Operations", showModal: false  })
  }


  render() {
    const { lang, layout } = this.context.state;
    const { hightLight, key, oPdetail, indexKey } = this.state
    const { Operation, OPWorkflows } = this.state.props.boards
    const _OPWorkflows = (OPWorkflows && OPWorkflows.length > 0) ? OPWorkflows[0] : false
    // console.log(this.props.history)
    return (
      <Row>
        <Col span={10} style={{ marginRight: 10 }}>
          {(Operation && Operation.length > 0 && _OPWorkflows) && Operation.map((el, i) => {
            // console.log(el)
            const title = () => { return  <span style={{ fontSize: 20 }}>{el.param.operation.operationName}</span > }
            // const setColor = (e) => {
            //   switch (expression) {
            //     case expression:
            //
            //       break;
            //     default:
            //
            //   }
            //     console.log(e)
            //   }
            const status = () => {
              if (el.param.status) {
                const _status = _OPWorkflows.param.steps.find((f) => el.param.status._id === f.id)
                let color
                if (_status) {
                  switch (_status.name) {
                    case "New": color = "rgb(255, 0, 0)"; break;
                    case "Planned": color = "rgb(255, 230, 0)"; break;
                    case "Preparation": color = "rgb(0, 10, 255)"; break;
                    case "InProduction": color = "rgb(22, 159, 0)"; break;
                    case "Disassembly": color = "rgb(255, 0, 207)"; break;
                    default:
                  }
                  return {
                    span: <span>{_status.name}</span>,
                    color: color
                  }
                }else {
                  return {
                    span: <span>no status</span>,
                    color: color
                  }
                }

              }else {
                return {
                  span: <span>no status</span>,
                  color: ""
                }
              }
            }
            const { span, color } = status() && status()
            return(
               <div key={i} style={{ padding: 10, }}>
              <Badge.Ribbon text={span} color={color} style={{ fontSize: 20 }}>
                <Card
                  style={{
                    background: (key === i) ? "rgba(94, 94, 94, 0.37)" : "",
                    cursor: "pointer",
                    fontSize: 20,
                    marginBottom: 10,
                    border: "1px solid #e7e7e7"
                  }}
                  title={title()} size="small"
                  onClick={() => this.setState({ key: i, oPdetail: el, indexKey: Math.random() })}>
                  quick info
                </Card>
              </Badge.Ribbon>
            </div>
            )
          })}
        </Col>
        <Col span={13}>
          {oPdetail && <OpDetail key={indexKey} callback={this.callback} dataProps={{ ...oPdetail, _state: this.state }}/>}
        </Col>
      </Row>
    )
  }
}

export default Operations;
