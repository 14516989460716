/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
// import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip, Tabs } from 'antd';
// import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, msg } from "src/utils/functions/filterFunction"
// import { content } from "src/utils/functions/core"
// import { OpBoardView, NoAsset } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { content } from "src/utils/functions/core"
import Spinner from "src/layouts/components/spinner"

const setDataAllMo = (WorkflowCatalog, tasks) => {
  let _board = []
  // console.log(tasks)
  if (WorkflowCatalog) {
    WorkflowCatalog?.param?.steps?.forEach((el, i) => {
      const findStatus = tasks.filter((f, j) => (f?.param?.status?._id) === el.id)
      if (findStatus) { _board.push({ ...el, items: findStatus }) } else { _board.push(el) }
    });
    return _board
  } else {
    msg("error", "Missing Workflow catalog", 10)
    return _board
  }
}

const OperationManipulateOp = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { assetId } = context.state
  const { auth_token } = content()
  const [result, setResult] = useState()
  const [children, setChildren] = useState(<Spinner />)
  // const [items, setItems] = useState()
  // const [trigger, setTrigger] = useState()
  // const [activeKey, setActiveKey] = useState(props?.dataProps?.activeKey ?? "1")
  // const [prop, setProp] = useState(props)
  const [comp, setComp] = useState()
  // const { userConfig } = context.state
  // const { user } = userConfig

  const getData = async () => {
    if (mounted.current) {
      // const assetId = props?.dataProps?.props?._id
      const result = await allinOne("OperationData", { assetId, _id: props?.children?.wofId })
      // console.log(result)
      if (result.success) {
        const board = setDataAllMo(result._WorkflowCatalog, result._Operation001)
        const all = { ...result, board, assetId }
        return all
      }
    }
  }

  // const previousValues = useRef({ trigger });
  const callback = (e) => {
    // console.log(e)
    // console.log(props)
    // console.log(e)
    if (e) {
      mounted.current = true
      f()
    }
  }

  const f = async () => {
    const d = await getData()
    // setResult(d)
    // console.log(d)
    if (d?.assetId) {
      const o = await import('src/layouts/widgets/Companies/i4c_V2/components/OpV2/OpBoardView')
      const OpBoardView = o?.default
      // const _children = d.assetId ? <OpBoardView key={JSON.stringify(d)} callback={callback} dataProps={d} children={props?.children} /> : <NoAsset />
      setChildren(<OpBoardView callback={callback} dataProps={d} children={props?.children} />)
    } else {
      const c = await import('src/layouts/widgets/Core/NoAsset')
      const NoAsset = c?.default
      setChildren(<NoAsset />)
    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [])

  // const callback = () => {
  //   setProp(props)
  // }
  // console.log(children)
  return <>{children}</>
}

export default OperationManipulateOp;
