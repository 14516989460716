import React, { useContext, useState, useEffect, useRef } from 'react';
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';
import { MainContext } from 'src/Context';
import { Row, Col } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { renderIconV2 } from "src/utils/functions/filterFunction.js";
import { InfoCardMo, DrawerComp } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts';
import { spinning } from "src/layouts/widgets/CustomLayout/index"
import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Drawer, Tabs } from 'antd';
const localizer = momentLocalizer(moment)

const prepareMoEvents = (mo, _board) => {

  // NOTE temporary solution
  const checkKeyValue = (e) => {
    if (e?.mos_statusHistory) {
      return e?.mos_statusHistory
    }else {
      return e?.mos_StatusHistory
    }
  }

const assets = localStorage.getItem("assets") ? JSON.parse(localStorage.getItem("assets")) : false
  const colorStatus = (s) => {
    switch (s) {
      case 0: return '#ffc107';
      case 1: return '#007bff';
      case 2: return '#28a745';
      case 3: return '#6c757d';
      default: return '#dc3545'
    }
  }
  const _MOTask = mo.map((el, i) => {
    const asset = assets.find((f) => f._id === el.references.assetDefId.$ref)
    const board = _board.find((f) => Number(f.id) === Number(checkKeyValue(el?.parameters)))
    // console.log(el.parameters.mos_taskStatus)
    return ({
      item: el,
      status: board,
      asset,
      title: asset.asset_name + ": " + el.parameters.mos_maintenanceName,
      start: new Date(el.parameters.mos_operationTimestamp),
      end: new Date(el.parameters.mos_operationEndTimestamp),
      type: el.parameters.mos_type,
      color: colorStatus(el.parameters.mos_taskStatus)
    })
  })
  return _MOTask
}


const MoCalendarView = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { undefObjHandler, secFormat, userConfig, lang } = context.state
  const { user } = userConfig
  const [ board, setBoard ] = useState([])
  const [ items, setItems ] = useState()
  const [ openDrawer, setOpenDrawer ] = useState(false)
  const [ prop, setProp ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ moEvents, setMoEvents ] = useState(prepareMoEvents(props?.dataProps?._MOTask, props?.dataProps?.board))
  const keys = [
    { c: "#dc3545", l: "missed" },
    { c: "#ffc107", l: "new" },
    { c: "#28a745", l: "ongoing" },
    { c: "#007bff", l: "planned" },
    { c: "#6c757d", l: "completed" }
  ]
  useEffect(() => {
    return () => { mounted.current = false }
  }, [board])


  const openOp = (item, el) => {
    setProp({
      placement: "bottom",
      open: true,
      headerStyle: { display: "none" },
      height: "85%",
      destroyOnClose: true,
      comp: "OperationManipulateMo",
      onClose: () => {
        setProp({
          placement: "bottom",
          open: false,
          headerStyle: { display: "none" },
          height: "85%",
          destroyOnClose: true,
        })
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
          props.callback("telkčlkj")
        }, 500)
      },
      item,
      status: el,
      board: props.dataProps.board,
      _WorkflowCatalogPB: props.dataProps._WorkflowCatalogPB
    })
  }

  return (
    <div>
      {isLoading && spinning(40)}
      <Calendar
        // key={indexKey}
        localizer={localizer}
        events={moEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ minHeight: 750 }}
        onSelectEvent={(e) => openOp(e.item)}
        eventPropGetter={(event, start, end, isSelected) => {
          // console.log(event, start, end, isSelected)
          return ({
            event, start, end, isSelected,
            style: { backgroundColor: event.color }
          })
        }}
        />
      <Row className="image-size p-2 text-asset-card-location">
        {keys.map((el, i) => {
          return (
            <Col key={i} md="auto">
              {renderIconV2("BiMinus", 30, el.c, lang[el.l], "top")}{" "}{lang[el.l]}
            </Col>
          )
        })}
      </Row>
      <DrawerComp dataProps={prop}/>
    </div>
  )
}

export default MoCalendarView;
