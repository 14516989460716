/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Button, Steps, Divider, message, Tabs } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { checkPromisStatus, renderIconV2, calculateSum, allinPut, msg } from "src/utils/functions/filterFunction"
// import { BasicMoInfo, OpInfoMo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { dateTimeFormatter } from "src/utils/functions/formater"
import { TitleInfo, Infos, InfoColumns, EditorInfo, BasicInfo, MoDetails } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { LoadingOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const OperationManipulateMo = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { board, status } = props?.dataProps ?? []
  const [spinner, setSpinner] = useState(false)
  const [pauseStatus, setPauseStatus] = useState(false)
  // const [buttonFill, setButtonFill] = useState()
  const [items, setItems] = useState()
  const [getStatus, setGetStatus] = useState()
  const [colorStatus, setColorStatus] = useState()
  const [activeKey, setActiveKey] = useState()
  const [item, setItem] = useState(props?.dataProps?.item)
  // const [buttonLabel, setButtonLabel] = useState()
  const [quickNotes, setQuickNotes] = useState(props?.dataProps?.item?.param?.quickNotes)
  const [param, setParam] = useState(props?.dataProps?.item?.param ?? { param: {} })
  const { lang, authToken, userConfig, secFormat } = context.state
  const { style } = context
  const { user } = userConfig

  // NOTE temporary solution
  const checkKeyValue = (e) => {
    if (e?.mos_statusHistory) {
      return e?.mos_statusHistory
    }else {
      return e?.mos_StatusHistory
    }
  }

  const setData = (_board, _item) => {
    const items = _board.map((el, i) => {
      const filterStatus = checkKeyValue(_item?.parameters).filter((f) => f._id === el.id)
      // const filterStatus = _item?.parameters?.mos_statusHistory?.filter((f) => f._id === el.id)
      return ({ title: el.name, description: dateTimeFormatter("duration", calculateSum(filterStatus, "duration"), "dd:hh:mm") })
    })
    // console.log(_board)
    const getStatus = _board.find((el) => Number(el.id) === Number(item?.parameters?.mos_taskStatus))
    const colorStatus = getStatus?.kpi?.color
    const activeKey = (getStatus.key - 1)
    // const buttonLabel = (getStatus.key - 1)
    // console.log(getStatus)
    setItem(_item)
    setItems(items)
    // setButtonLabel()
    // console.log(items)
    setGetStatus(getStatus)
    setColorStatus(colorStatus)
    setActiveKey(activeKey)
  }

  useEffect(() => {
    setData(board, item)
    return () => { mounted.current = false }
  }, [board])

  const handleTiming = async (type, e) => {
    let _statusHistory = []
    const findNextStatus = board.find((el) => (el.key === (getStatus.key + 1)))
    const findNextStatusParameters = board.find((el) => (el.key === (Number(item.parameters.mos_taskStatus) + 2)))
    // console.log(findNextStatus)
    const destination = {
      _i: 0,
      _id: findNextStatus.id
    }
    const _user = {
      _id: user.userId,
      user: user.firstName + " " + user.lastName
    }
    _statusHistory.push({
      startDate: Date.now(),
      _id: findNextStatus.id,
      name: findNextStatus.name,
      user: _user,
      statusType: findNextStatus.kpi.value,
      duration: 0
    })
    checkKeyValue(item?.parameters).forEach((el, i) => {
      // item.parameters.mos_statusHistory.forEach((el, i) => {
      _statusHistory.push({
        ...el,
        endDate: (i === 0) ? el.startDate : el.endDate,
        duration: (i === 0) ? secFormat(Date.now() - el.startDate) : el.duration,
      })
    });
    const now = Date.now()
    const parameters = {
      ...item.parameters,
      mos_endDate: (board.length === Number(findNextStatusParameters.id) + 1) ? now : false,
      mos_taskStatus: Number(findNextStatusParameters.id),
      mos_statusHistory: _statusHistory,
    }
    if (parameters) {
      const [UpdateItemParameters] = await Promise.allSettled([
        allinPut("UpdateItemParameters", { id: item._id, param, parameters }, authToken),
      ])
      if (UpdateItemParameters) {
        const newItem = { ...item, parameters }
        setItem(newItem)
        const getStatus = board.find((el) => Number(el.id) === (Number(parameters?.mos_taskStatus)))
        const colorStatus = getStatus?.kpi?.color
        const activeKey = (getStatus.key - 1)
        const items = board.map((el, i) => {
          const filterStatus = checkKeyValue(parameters).filter((f) => f._id === el.id)
          return ({ title: el.name, description: dateTimeFormatter("duration", calculateSum(filterStatus, "duration"), "dd:hh:mm") })
        })
        setItems(items)
        setGetStatus(getStatus)
        setColorStatus(colorStatus)
        setActiveKey(activeKey)
      }
    }
  }

  const left = () => {
    const findStatus = board.find((el) => (el.key === (getStatus?.key + 1)))
    if (findStatus) {
      return pauseStatus ? lang["onPause"] ? lang["onPause"] : "You are on pause" : findStatus.stepAction
    } else {
      return "finished"
    }
  }

  const updateQuickNotes = async () => {
    const param = { ...item.param, quickNotes }
    const [UpdateQuickNotes] = await Promise.allSettled([
      allinPut("UpdateQuickNotes", { id: item._id, param }, authToken),
    ])
    if (UpdateQuickNotes) {
      // console.log(checkPromisStatus(UpdateQuickNotes))
      msg("success", "Saved", 3)
      setParam(param)
    }
  }

  const operationTab = () => {
    return(
      <div style={style.OperationManipulateMo}>
        <TitleInfo key={Date.now()} dataProps={_item} />
        <Divider />
        <Steps
          style={style.OperationManipulateMo.Steps}
          progressDot
          // type="navigation"
          // size="small"
          className="OperationManipulateMoSteps"
          current={activeKey}
          items={items}
          />
        <Divider />
        <Row>
          <Col md={3}>
            <Button
              key={Math.random()}
              ghost={left() === "finished" ? false : true}
              style={{ ...style.OperationManipulateMo.TriggerButton, background: colorStatus }}
              type="primary"
              onClick={() => handleTiming("nextStatus")}
              disabled={left() === "finished" ? true : false}>
              {spinner ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : lang[left()] ? lang[left()] : left()}
            </Button>
          </Col>
          <Col md={6}>
            <TextArea
              placeholder={lang["quickNotes"] ? lang["quickNotes"] : "quickNotes"}
              value={quickNotes}
              style={{ ...style.OperationManipulateMo.textAreaOperation }}
              onChange={(e) => setQuickNotes(e.target.value)}
              />
          </Col>
          <Col md={3}>
            <Button
              ghost={true}
              style={{ width: "40%", height: 180, marginRight: 10, fontSize: 25, border: "1px solid #e7e7e7", color: "#000000", background: "#b8e986" }}
              type="primary"
              onClick={updateQuickNotes}>
              <span>
                {renderIconV2("AiOutlineSave", 24, "")}
                {'\xa0'}
                {lang["btn_save"] ? lang["btn_save"] : "btn_save"}
              </span>
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row>
          <InfoColumns dataProps={columns}/>
          <Divider />
          {/*
            <Divider />
            <Infos dataProps={info}/>
            */}
          </Row>
        </div>
      )
    }


    const columns = [
      {
        key: 1,
        title: lang["info"] ? lang["info"] : "info",
        comp: 'Infos',
        style: { fontSize: 20 },
        info: [
          { label: lang["name"] ? lang["name"] : "name", value: item?.parameters?.mos_maintenanceName },
          { label: lang["type"] ? lang["type"] : "type", value: lang[item?.parameters?.mos_type] ? lang[item?.parameters?.mos_type] : item?.parameters?.mos_type },
          { label: lang["note"] ? lang["note"] : "note", value: item?.parameters?.mos_note },
        ]
      },
      {
        key: 2,
        title: lang["info"] ? lang["info"] : "info",
        comp: 'Infos',
        style: { fontSize: 20 },
        info: [
          { label: lang["startDate"] ? lang["startDate"] : "startDate", value: dateTimeFormatter("date", item?.parameters?.mos_initialTimestamp, "hh:mm dd.mm.yyyy") },
          { label: lang["endDate"] ? lang["endDate"] : "endDate", value: dateTimeFormatter("date", item?.parameters?.mos_endDate, "hh:mm dd.mm.yyyy") },
          { label: lang["duration"] ? lang["duration"] : "duration", value: dateTimeFormatter("duration", item?.parameters?.duration, "dd:hh:mm") },
        ]
      },
    ]

    const info = [
      { label: "klnkln", value: <EditorInfo dataProps={item?.parameters?.mos_procedure}/> }
    ]
    const _item = { name: item.parameters.mos_maintenanceName }
    const itemsTabs = [
      {
        label: lang["operation"] ? lang["operation"] : "operation",
        children: operationTab(),
        key: '1',
      },
      {
        label: lang["maintenanceProcedure"] ? lang["maintenanceProcedure"] : "maintenanceProcedure",
        // children: this.ProductProductionProcedure(production, validation),
        children: <EditorInfo dataProps={item?.parameters?.mos_procedure}/>,
        key: '2',
      },
      {
        label: lang["basicInfo"] ? lang["basicInfo"] : "basicInfo",
        // children: this.ProductProductionProcedure(production, validation),
        children: <MoDetails dataProps={item}/>,
        key: '3',
      },
  ]
  const onChange = (key) => {
    // console.log(key)
  }
  return (
    <Tabs
      size={"large"}
      // activeKey={tabHighlight}
      style={{ padding: 0 }}
      onChange={onChange}
      type="card"
      items={itemsTabs}
      />
  )
}

export default OperationManipulateMo;
