import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { SyncOutlined } from '@ant-design/icons';

class Spinner extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getData = () => {
    this._isMounted = true;
    // const { dataProps } = this.props
  }

  callback = (e) => { this.props.callback(e) }

  render() {
    const { lang } = this.context.state
    const { children } = this.props
    if (children) {
      return (
        <div style={{ position: "absolute", left: 0, top: 0, background: "rgba(255, 255, 255, 0)", width: "100%", height: "100%" }}>
          <div className="container-fluid">
            <div className="position-fixed top-50 start-50 bottom-50 translate-middle spinnerCustom">
              <span>
                <SyncOutlined style={{ ...children.style }} spin />
                {'\xa0'}
              </span>
              <span style={{ ...children.style }}>
                {lang[children.body] ? lang[children.body] : children.body}
              </span>
            </div>
          </div>
        </div>
      )
    }else return <></>
  }
}

export default Spinner
