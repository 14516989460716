import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button, Image, Input, Drawer } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';

class Assets extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      itemsPage: 6,
    }
  }


  componentDidMount() { this.getData() }

  getData = () => {
    this._isMounted = true
    const { querySelect, authToken, userConfig } = this.context.state
    const { user } = userConfig
    querySelect({
      url: "/getAssetsByUser",
      token: authToken,
      id: user.userId
      // keys: "_id, activeStatus, description, material, selectedAmount, amountText, code",
      // id: data.validationId,
      // extraQuery: 'AND `material`.`code` = ' + '"' + data.validationRef.code + '"'
    }).then(res => {
      if (this._isMounted && res) {
        // console.log(res)
        this.setState({
          assets: res.assetsTree.map((el, i) => ({ ...el, value: el._id, label: el.asset_name })),
          locations: res.locations
        })
        // console.log(res)
        // this.context.contextCall("spinnerCustom", false)
      }
    })
  }

  onClickCard = (el) => {
    const { dataProps, callback } = this.props
    callback({ type: "card", showModal: false, comp: "Assets", data: el })
  }
  // POST -- update event data

  render() {
    const { lang, layout, inputSearch } = this.context.state;
    const { colSize } = this.props;
    const { assets, locations } = this.state
    // optionsTable: _optionsTable.filter(el => el._lootId.toLowerCase().includes(e.target.value.toLowerCase()))
    if (assets && assets.length > 0) {
      // console.log(inputSearch)
      const _assets = inputSearch ? assets.filter((el) => el.asset_name.toLowerCase().includes(inputSearch.toLowerCase())) : assets
      // console.log(_assets)
      return(
        <Row>
          {_assets.map((el, i) => {
            const location = locations.find((f) => el.locationId === f.locationId)
            // console.log(el)
            return(
              <Col key={i} md={3} style={{ minWidth: 380, padding: "10px 5px 10px 10px", maxWidth: 380 }}>
                <Card>
                  <Card.Header style={{ padding: "5px 5px 5px 5px", maxHeight: 100, border: 0 }}>
                    <Row style={{ width: "100%" }}>
                      <div style={{ width: "15%" }}>
                        {/*
                          <AiFillBell style={{ cursor: "pointer" }} onClick={() => this.routePush("/details_basic_info", ("/asset_details_notification?id=" + el._id))} />
                          <RiErrorWarningFill style={{ cursor: "pointer" }} onClick={() => this.routePush("/details_basic_info", ("/details_event_history?id=" + el._id))} />
                          */}
                        </div>
                        <center style={{ width: "70%" }}>
                          <div style={{ cursor: "pointer", fontSize: 22 }} onClick={() => console.log(el._id)}>{el.label}</div>
                          <div style={{ cursor: "pointer", fontSize: 18 }} onClick={() => console.log(el._id)}>{location.locationName}</div>
                          {/*
                            <center style={{ cursor: "pointer", fontSize: 12 }} onClick={() => this.routePush("/details_basic_info", ("/locations?id=" + el._id))}>{el.asset_locationName}</center>
                            <center style={{ cursor: "pointer", fontSize: 12 }} onClick={() => this.props.callback({ type: "location", value: el })}>{location.location_fullName}</center>
                            */}
                          </center>
                          <div style={{ width: "10%" }}>

                          </div>
                        </Row>
                      </Card.Header>
                      <Card.Body style={{ height: 320, marginBottom: 10 }}>
                        <center style={{ height: 200 }}>
                          <Image
                            preview={false}
                            style={{ maxHeight: 200, maxWidth: 200, cursor: "pointer" }}
                            fallback={"/media/users/defaultMachine.png"}
                            src={"/media/users/" + el.param.asset_image}
                            onClick={() => this.onClickCard(el)}/>
                        </center>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          )
        }else {
          return (
            <Col md={colSize}>
              no assets
            </Col>
          )
        }
      }
    }

    export default Assets
