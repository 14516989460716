import React from 'react';
import { MainContext } from 'src/Context';

class OpItems extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    // console.log(this.props.dataProps.findStatusOp)
    return(
      <div>
        OpItems
      </div>
    )
  }
}

export default OpItems;
