
const content = () => {
  if (localStorage.getItem("body")) {
    return JSON.parse(localStorage.getItem("body"))
  } else return false
}

const setColorEventType = (type) => {
  switch (type) {
    case "Fault": return "#dc3545";
    case "Unplanned Maintenance": return "#ffc107";
    case "Info": return "#28a745";
    // case "Unplanned Maintenance": return "#ffc107";
    case "User interruption": return "#ffc107";
    case "Warning": return "#FF7F7F";
    default: return "";
  }
}

const findShift = (now, shift) => {
  const _now = now
  // const _now = "Fri May 10 2024 05:21:57 GMT+0200 (Central European Summer Time)"
  if (shift && shift.length > 0) {

     // console.log({
     //   date: new Date(_now),
     //   hour: new Date(_now).getHours(), // if between 0 & 6
     //   dayC: new Date(_now).getDate(),
     //   dayS1: new Date(_now).setDate(new Date(_now).getDate() - 1),
     //   now,
     //   _now
     // })
    const g = shift && shift.map((el, i) => {
      let end
      const hour = new Date(_now).getHours()
      if (hour > 0 && hour < 6) {
        console.log("between")
        end = new Date(new Date(_now).setDate(new Date(_now).getDate() - 1)).setHours(el?.offset + el?.duration, 0, 0, 0)
      }else {
        end = new Date(_now).setHours(el?.offset + el?.duration, 0, 0, 0)
      }
      const start = new Date(_now).setHours(el?.offset, 0, 0, 0)
      // console.log({
      //   start,
      //   end,
      //   parseNow: Date.parse(now)
      // })
      if (Date.parse(now) >= start && Date.parse(now) < end) {
        return ({ ...el, yes: true, start, end })
      } else {
        return el
      }
    });
    const findS = g.find((el) => el.yes)
    // console.log(findS)
    return findS
  } else {
    return []
  }
}

const findShiftV2 = () => {

}

const setLocal = (type, value) => {
  localStorage.setItem(type, JSON.stringify(value))
}

const isLocal = (type) => {
  // const _type = localStorage.getItem(type)
  // return false
  try {
    return JSON.parse(localStorage.getItem(type))
  } catch (e) {
    return false
  } finally {
  }
}


export { content, setColorEventType, findShift, setLocal, isLocal }
