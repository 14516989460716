import React, { useContext, useState, useEffect } from 'react'
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
import { content } from "src/utils/functions/core"

const Interrupt = (props) => {
console.log(props)
  return (
   <div>Interrupt</div>
  )
}

export default Interrupt;
