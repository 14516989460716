import React, { useContext, useState } from 'react'
import { MainContext } from 'src/Context';
import { Drawer } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';
import * as comp from './layouts';

const callback = (e) => {
  console.log(e)
}

const DrawerCatalog = (props) => {
  const { dataProps, callback } = props
  const context = useContext(MainContext);
  const { lang } = context.state
  const handleOk = () => {}
  const Component = (props && props.dataProps && props.dataProps.comp) ? (comp[props.dataProps.comp] ? comp[props.dataProps.comp] : comp["Empty"]) : comp["Empty"]
  const title = (p) => {
    if ((p && p.dataProps.props) && (p.dataProps.props.title || props.dataProps.props.titleComp)) {
      if (props.dataProps.props.title) {
        return props.dataProps.props.title
      }else if (props.dataProps.props.titleComp) {
        // const _Component = comp[_comp]
        const _comp = props.dataProps.props.titleComp ? props.dataProps.props.titleComp : "Empty"
        const Component = comp[comp[_comp] ? _comp : "Empty"]
        return <Component key={dataProps.showModal} callback={callback} dataProps={{ ...props.dataProps }}/>
      }
    }else {
      return lang[p.dataProps.comp] ? lang[p.dataProps.comp] : p.dataProps.comp
    }
  }
  // title={props && props.dataProps && lang[props.dataProps.comp] ? lang[props.dataProps.comp] : props.dataProps.comp}
  // console.log(props)
  // console.log(dataProps)
  const _dataProps = {
    ...dataProps,
    showModal: !dataProps.showModal
  }
  return (
    <>
      {
        dataProps && dataProps.showModal &&
        <Drawer
          title={title(props)}
          // width={2000}
          height={"90%"}
          placement="bottom"
          onClose={() => callback({ ..._dataProps })}
          open={props.dataProps.showModal}>
          <Component key={dataProps.showModal} callback={callback} dataProps={{ ...props.dataProps }}/>
        </Drawer>
      }
    </>
  )
}

export default DrawerCatalog;
