import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';

class Test extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {}
  }


  componentDidMount() { this.getData() }

  getData = () => {}
  // POST -- update event data

  render() {
    const { lang, layout } = this.context.state;
    const { colSize } = this.props;
    // console.log(this.state)
    return (
      <Col md={colSize}>
        <Row style={{ minHeight: 100, padding: "10px 0px 0px 10px" }}>
          info Operation => WorkOrder
        </Row>
        <Row>
          <Col md={6}>
            <center>
              <Button
                ghost
                style={{ width: "40%", height: 150, fontSize: 25 }}
                type="primary">
                {lang["start"] ? lang["start"] : "start"}
              </Button>
            </center>
          </Col>
          <Col md={6}>
            <center>
              <Button
                ghost
                style={{ width: "40%", height: 150, fontSize: 25 }}
                type="primary" danger>
                {lang["stop"] ? lang["stop"] : "stop"}
              </Button>
            </center>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Test
