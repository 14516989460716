const querySelectCustom = (data) => {
  return fetch(data.url + "?body=" + JSON.stringify(data), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    }
  }).then(res => {
    return res.json().then((el) => {
      return el.success ? el.result : false
    })
  }).catch(err => { return err })
}

const querySelectPut = (data) => {
  return fetch(data.url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    },
    body: JSON.stringify(data)
  }).then(res => {
    return res.json().then((el) => {
      return el.success ? el.result : false
    })
  }).catch(err => { return err })
}

const querySelectPost = (data) => {
  return fetch(data.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    },
    body: JSON.stringify(data)
  }).then(res => {
    return res.json().then((el) => {
      return el.success ? el.result : false
    })
  }).catch(err => { return err })
}

const parseJson = async (res) => {
  const text = await res.text()
  try {
    const json = JSON.parse(text)
    return json
  } catch (err) {
    throw new Error("Did not receive JSON, instead received: " + text)
  }
}

const querySelect = (data) => {
  return fetch(data.url + "?body=" + JSON.stringify(data), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    }
  })
    .then(parseJson)
    .then(el => {
      return el.data
    })
  // .then(res => {
  //   return res.json().then(el => {
  //     if (el.success) {
  //       return el.data
  //     }else {
  //       return false
  //     }
  //   })
  //   // return res.json()
  // }).catch(err => { return err })

}

const queryPut = (data) => {
  return fetch(data.url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    },
    body: JSON.stringify(data)
  }).then(res => {
    return res.json().then(el => {
      if (el.success) {
        return el.data
      } else {
        return false
      }
    })
  }).catch(err => { return err })
}

const queryPost = (data) => {
  return fetch(data.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    },
    body: JSON.stringify(data)
  }).then(res => {
    return res.json().then(el => {
      if (el.success) {
        return el.data
      } else {
        return false
      }
    })
  }).catch(err => { return err })
}

const queryDelete = (data) => {
  return fetch(data.url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'authToken': data.token
    },
    body: JSON.stringify(data)
  }).then(res => {
    return res.json().then(el => {
      if (el.success) {
        return el.data
      } else {
        return false
      }
    })
  }).catch(err => { return err })
}

export { querySelect, queryPut, queryPost, queryDelete, querySelectCustom, querySelectPut, querySelectPost }
