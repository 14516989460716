import React from 'react';
import { MainContext } from 'src/Context';
import { Button, Row } from 'antd';
import { Spinner } from "src/layouts/layouts"

class OpDetail extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {
      ...this.props.dataProps,
    }
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick = (e, id) => {
    // console.log(e, id)
    this._isMounted = true
    const { queryPut, authToken, lang } = this.context.state
    const { contextCall } = this.context
    const status = {
      _id: e.id,
      _i: 0
    }
    const { toastify } = this.context
    toastify("error", "temporary disabled", {
      closeButton: false,
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // contextCall("spinnerCustom", { spin: true, text: lang["RetrievingData"], sizeSpinner: 20, sizeText: 20 })
    // queryPut({
    //   url: "/set_status_gen001",
    //   token: authToken,
    //   type: "Operation",
    //   id: id,
    //   status: status
    // }).then(res => {
    //   if (this._isMounted && res) {
    //     this.props.callback({ type: "OpDetail", value: true })
    //   }
    // })

  }

  render () {
    const { _state, _id, param } = this.state
    const wo = (_state && _state.props.boards.OPWorkflows) ? _state.props.boards.OPWorkflows.find((el) => el.param.op && el.param.isDefault) : false
    if (wo && wo.param && wo.param.steps.length > 0) {
      // console.log(this.state)
    const op = param.operation
    // console.log(op)
      return(
          <Row style={{ padding: 10, width: "100%" }}>
            <div style={{ width: "50%" }}>
              <span style={{ width: "100%", fontSize: 20 }}>
                <div>op name: {op.operationName}</div>
                <div>plannedDuration: {op.plannedDuration}</div>
                <div>postConfiguration: {op.postConfiguration}</div>
                <div>preConfiguration: {op.preConfiguration}</div>
              </span>
            </div>

            <div style={{ width: "50%" }}>
              {wo.param.steps.map((el, i) => {
                // console.log(el)
                return(
                  <div key={i} style={{ width: "100%", paddingBottom: 20 }}>
                    <Button
                      style={{ width: "100%", height: 100, fontSize: 30 }}
                      ghost={true}
                      type="primary"
                      onClick={() => this.handleClick(el, _id)}>
                      {el.name}
                    </Button>
                  </div>

                )
              })}
            </div>
    </Row>
    )
  }else return  <div>no props !!!</div>
  }
}

export default OpDetail;
