import React, { useContext, useState, useEffect, useRef } from 'react'
// import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd'
import { Card, Col, Row } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DrawerComp, InfoCardPo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { MainContext } from 'src/Context';
import { allinPut, checkPromisStatus } from "src/utils/functions/filterFunction"
// import { spinning } from "src/layouts/widgets/CustomLayout/index"
// import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
import Spinner from "src/layouts/components/spinner"

const PoBoardView = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { undefObjHandler, secFormat, userConfig, authToken } = context.state
  const { user } = userConfig
  const [ board, setBoard ] = useState(props?.dataProps?.board)
  const [ items, setItems ] = useState()
  const [ _count, _setCount ] = useState(0)
  const [ prop, setProp ] = useState()
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // const [ isLoading, setIsLoading ] = useState(<Spinner />)
  // const [ children, setChildren ] = useState(<Spinner />)

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    return () => { mounted.current = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board])

  const callback = () => {}

  const closeDrawer = (e) => {
    console.log(e)
  }

  const openOp = (item, el) => {
    // console.log({item, el})
    // console.log(props)
    setProp({
      placement: "bottom",
      open: true,
      headerStyle: { display: "none" },
      height: "85%",
      destroyOnClose: true,
      comp: "OperationManipulatePo",
      onClose: () => {
        setProp({
          placement: "bottom",
          open: false,
          headerStyle: { display: "none" },
          height: "85%",
          destroyOnClose: true,
        })
        // setIsLoading(false)
        // setIsLoading(true)
        // setTimeout(() => {
        //   setIsLoading(false)
        //   props.callback("telkčlkj")
        // }, 500)
      },
      item,
      status: el,
      ...props.dataProps,
      children: props?.children,
      // board: props.dataProps.board,
      // _WorkflowCatalogPB: props.dataProps._WorkflowCatalogPB
    })
    // () => props.callback("telkčlkj")
    // this.setState({ showModal: true, drawerComp: "OperationManipulateMo", item, status: el })
  }

  const filterCards = (item, index, el) => {
    return (
      <div key={index} style={{ borderRadius: 0 }} onClick={() => (item.param && item.param.completed) ? null : openOp(item, el)}>
        <InfoCardPo callback={callback} dataProps={{ ...item, ...props.dataProps, children: props?.children }} />
      </div>
    )
  }

  return (
    <Row style={{ overflowY: "scroll", height: (windowHeight - 110) }}>
        {board.map((el, ind) => {
          const wf = props?.dataProps?._WorkflowCatalog
          const count = wf ? wf.param.maxCount : false
          const lengthInd = board.length - 1
          const numberOfCol = ((100 / board?.length) + "%").toString()
          const limit = el?.limit
          return (
            <Col key={ind} style={{ color: "", padding: 0, borderRight: ind < lengthInd && "1px solid #e7e7e7", maxWidth: numberOfCol }}>
              <Card style={{ border: 0, borderRadius: 0 }}>
                <center>
                  <Card.Header style={{ border: 0, borderRadius: 0, background: undefObjHandler(el, "color") ? el.kpi.color : "", color: "rgb(255, 255, 255)" }}>
                    {el ? el.name : "props missing !!!"}
                    <span style={{ float: "right" }}>
                      {(limit && limit > 0) ? (el.items ? (((limit > el.items.length) ? el.items.length : limit) + "/" + el.items.length) : "") : el.items ? el.items.length : ""}
                    </span>
                  </Card.Header>
                </center>
              </Card>
              <div style={{ overflowY: "auto", height: (windowHeight - 220) }}>
                {el.items.map((item, index) => {
                  if (index < limit) {
                    return filterCards(item, index, el)
                  }else if (limit === 0 || !limit) {
                    return filterCards(item, index, el)
                  }
                })}
              </div>
            </Col>
          )
        })}
        {prop?.open && <DrawerComp dataProps={prop}/>}
    </Row>
  )
}

export default PoBoardView;
