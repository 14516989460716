/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import { MainContext } from 'src/Context';
import { setColorEventType } from "src/utils/functions/core"
import { Column } from '@ant-design/plots';
import { dateTimeFormatter } from "src/utils/functions/formater"

const App = (props) => {
  const context = useContext(MainContext);
  const { lang } = context.state
  // const idleTime = props?.idleTime
  const data = props?.data.map((el, i) => {
    const e = props?.events.filter((f) => (f?.startDate >= el.timestampFrom) && (f?.endDate <= el.timestampTo))
    const type = props?.eventTypes?.filter((f) => e?.find((fi) => f?._id === fi?.eventType?.$ref))
    const t = (type && type?.length > 0) ? type?.[0]?.parameters?.eventType_name : ""
    return ({
      ...el,
      timestamp: dateTimeFormatter("date", el?.timestampFrom, "hh:mm"),
      value: e?.length,
      events: e,
      type: t
    })
  })

  const [state, setState] = useState({
    details: false,
    findEvents: [],
    events: props?.events,
    config: {
      data: data ? data : [],
      xField: "timestamp",
      yField: "value",
      seriesField: 'type',
      label: {
        position: 'middle',
        // 'top', 'bottom', 'middle',
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
        layout: []
      },
      legend: {
        position: 'bottom',
        layout: 'horizontal',
        style: {
          fill: "#7A8FAC",
          fontSize: 12,
        }
      },
      tooltip: {
        customContent: (_title, items) => {
          let events = []
          for (var i = 0; i < items.length; i++) {
            if (items[i]?.data.events?.length > 0) {
              items[i]?.data.events.forEach((el, i) => {
                events.push(el)
              });
            }
          }
          const title = (events?.length > 1) ? (lang?.["events"] ?? "events") : (lang?.["event"] ?? "event")
          if (events?.length > 0) {
            return (
              <>
                <div>
                  <h5 style={{ marginTop: 16 }}>{title} - {lang?.["subject"] ?? "subject"}: {_title}</h5>
                  <ul style={{ paddingLeft: 0 }}>
                    {events?.map((el, i) => {
                      const num = i + 1
                      return (
                        <li
                          key={el?._id}
                          className="g2-tooltip-list-item"
                          data-index={i}
                          style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}>
                          <span className="g2-tooltip-marker" style={{ backgroundColor: "color" }}></span>
                          <span
                            style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                            <span style={{ marginRight: 16 }}>{num}:{'\xa0'}{dateTimeFormatter("date", el?.startDate, "hh:mm")}</span>
                            <span className="g2-tooltip-list-item-value">{el?.subject}</span>
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </>
            )
          } else { }
        }
      },
      color: ({ type }) => {
        return setColorEventType(type)
      },
      height: 150,
    }
  })

  const itemHandle = (el) => {
    const t = props?.timestamp
    const e = el?.[0]?.data.events ?? []
    props?.openIdleTime({ drawer: true, t, findEvents: e })
  }
  return (
    <Column
      onReady={(plot) => {
        plot.on('plot:click', (evt) => {
          const { x, y } = evt;
          const { xField } = plot.options;
          const tooltipData = plot.chart.getTooltipItems({ x, y });
          itemHandle(tooltipData)
        });
      }}
      {...state?.config} />
  )
}
export default App;