import { Tooltip, Badge, Spin } from 'antd';
// import * as icons from 'react-icons/all'
import { Row } from 'react-bootstrap';
import { renderIcon } from "src/utils/functions/filterFunction.js"
import { SyncOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
// const queryFetch = require("src/utils/functions/queryFetch");
// const formater = require("src/utils/functions/formater");

const singleRow = (e) => {
  return (
    <Row>
      <span style={{ ...e.left }}>
        <Tooltip placement="left" title={e?.tooltip ? e?.tooltip : e?.leftTitle}>
          <span style={{ ...e.left.span }}>
            {e.leftTitle}:
          </span>
        </Tooltip>
      </span>
      <span style={{ ...e.right }}>
        <Tooltip placement="left" title={e?.tooltipValue ? e?.tooltipValue : e?.rightTitle}>
          <span style={{ ...e.right.span }}>
            {e.rightTitle}
          </span>
        </Tooltip>
      </span>
    </Row>
  )
}

const singleValue = (e) => {
  return (
    <Row>
      <span>
        <Tooltip placement="left" title={e?.title}>
          <span style={{ ...e?.style }}>
            {e?.title}
          </span>
        </Tooltip>
      </span>
    </Row>
  )
}

const singleRowColorIcon = (e, icon) => {
  return (
    <Row>
      <span style={{ ...e.left }}>
        <span style={{ ...e.left.span }}>
          {e.leftTitle}:
        </span>
      </span>
      <span style={{ ...e.right }}>
        <span style={{ ...e.right.span }}>
          {icon}
        </span>
      </span>
    </Row>
  )
}

const inProcess = (e) => {
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Document</title>
      </head>
      <body>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10 main-title">
              <h2>under construction</h2>
              <h1>{e}</h1>
            </div>
          </div>
        </div>
      </body>
    </html>
  )
}

const stickyHeader = (header, body) => {
  return (
    <div className="placeholder-content">
      <section className="sticky-content">
        {header}
      </section>
      <div className="placeholder-title">
        <Row style={{ minHeight: 100, padding: 0 }}>
          {body}
        </Row>
      </div>
    </div>
  )
}

const spinning = (size) => {
  return (
    <div style={{ position: "fixed", left: 0, top: 0, background: "rgba(255, 255, 255, 0)", width: "100%", height: "100%", zIndex: 9999 }}>
      <div className="container-fluid">
        <div className="position-fixed top-40 start-50 bottom-50 translate-middle spinnerCustom">
          <span>
            <SyncOutlined style={{ fontSize: size }} spin />
          </span>
        </div>
      </div>
    </div>
  )
}

const workProgress = (e) => {
  return (
    <div style={{ textAlign: "center" }}>
      <h3>Work in progress ... ({e})</h3>
    </div>
  )
}

const rightText = (e, t) => {
  return <span>{e}{'\xa0'}{t}</span>
}

const colorPrio = (e, size, text) => {
  switch (e) {
    case "low": return rightText(renderIcon("IcNavArrowDown", (size ? size : 20), "#008000"), (text ? text : ""));
    case "medium": return rightText(renderIcon("BiMinus", (size ? size : 20), "#FFA500"), (text ? text : ""));
    case "high": return rightText(renderIcon("IcNavArrowUp", (size ? size : 20), "#FF0000"), (text ? text : ""));
    default: return null;
  }
}

const colorCircle = (e, size, text) => {
  switch (e) {
    case "late": return rightText(renderIcon("BsCircleFill", (size ? size : 15), "#ff0000"), (text ? text : ""));
    case "on-track": return rightText(renderIcon("BsCircleFill", (size ? size : 15), "#0b6f02"), (text ? text : ""));
    case "early": return rightText(renderIcon("BsCircleFill", (size ? size : 15), "#12da00"), (text ? text : ""));
    default: return null;
  }
}

const colorCircleNoSize = (e, size, text) => {
  const s = {
    height: 15,
    width: 15,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    marginBottom: 4
  }

  const late = {
    ...s,
    backgroundColor: "#ff0000",
  }

  const track = {
    ...s,
    backgroundColor: "#0b6f02"
  }

  const early = {
    ...s,
    backgroundColor: "#12da00"
  }

  const _default = {
    ...s,
    backgroundColor: "#c2c2c2"
  }
  switch (e) {
    case "late": return <span style={late}></span>;
    case "on-track": return <span style={track}></span>;
    case "early": return <span style={early}></span>;
    default: return <span style={_default} title={"Missing"}></span>;
  }
}

const onlineStatus = (e, size, text) => {
  switch (e) {
    case 1: return <Badge color="#00c202" count="online" />;
    // case 1: return rightText(renderIconV2("BsCircleFill", (size ? size : 15), "#00c202"), (text ? text : ""));
    case 0: return <Badge color="#ff4d4f" count="offline" />;
    // case 0: return rightText(renderIconV2("BsCircleFill", (size ? size : 15), "#ff4d4f"), (text ? text : ""));
    default: return null;
  }
}

const statusCircle = (color, size) => {
  const s = {
    height: size,
    width: size,
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    // marginRight: 5
    marginBottom: 4
  }

  const style = {
    ...s,
    backgroundColor: color,
  }
  return <span style={style}></span>
}

const spining = () => {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "rgb(255, 255, 255)" }} spin />} />
}

const colorRender = (e) => {
  switch (e) {
    case "a": return "#8141fa";
    case "b": return "#e8ff2d";
    case "c": return "#22baec";
    case "d": return "#14d6a2";
    case "e": return "#fe4c17";
    case "f": return "#5ff409";
    case "g": return "#f066d5";
    case "h": return "#f27852";
    case "i": return "#967282";
    case "j": return "#a8a669";
    case "k": return "#2a10de";
    case "l": return "#2525b1";
    case "m": return "#90089e";
    case "n": return "#79517c";
    case "o": return "#3375f5";
    case "p": return "#6c817a";
    case "r": return "#1ad747";
    case "s": return "#dc40e6";
    case "š": return "#cd711f";
    case "t": return "#15b024";
    case "u": return "#e6db9c";
    case "v": return "#32a850";
    case "z": return "#6f80b6";
    case "Ž": return "#63ad98";
    case "x": return "#82e024";
    case "y": return "#6f69b3";
    case "q": return "#faed7a";
    default: return "#87d068";

  }
}

const autoStatus = (e) => {
  if (e) {
    return <span>AUTO status: <b style={{ color: "#0b6f02" }}>ON</b></span>
  } else {
    return <span>AUTO status: <b style={{ color: "#ff0000" }}>OFF</b></span>
  }
}
// const statusMaterialColor = (e) => {
//   switch (e) {
//     case "notReady": return <>{" "}{statusCircle("#ff0000", 13)}</>;
//     case "ready": return <>{" "}{statusCircle("#0b6f02", 13)}</>;
//     case "inPreparation": return <>{" "}{statusCircle("#50c4fa", 13)}</>;
//     default: return ""
//   }
// }

const statusMaterialColor = (e) => {
  switch (e) {
    case "notReady": return "#ff0000";
    case "ready": return "#0b6f02";
    case "inPreparation": return "#50c4fa";
    default: return "#000000"
  }
}

export {
  singleRow,
  singleRowColorIcon,
  colorCircle,
  inProcess,
  stickyHeader,
  spinning,
  colorPrio,
  onlineStatus,
  singleValue,
  colorCircleNoSize,
  workProgress,
  statusCircle,
  spining,
  colorRender,
  statusMaterialColor,
  autoStatus
}
