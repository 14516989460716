import React from 'react';
import { Redirect, withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';

class BodyCard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    // this.props.callback("lkhčjkhklj")
    console.log()
    return(
      <div onClick={() => this.props.callback("BodyCard", this.props.dataProps)}>
        BodyCard
      </div>
    )
  }
}

export default withRouter(BodyCard);
// <> {this.props.dataProps.arr.map((el, i) => { return <div>{el.label}: {el.value}</div> })} </>
