import React, { useContext, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';
import { allinOne, allinPut } from "src/utils/functions/filterFunction"

const App = (props) => {
  const mounted = useRef(true);
const [content, setContent] = useState()

const getData = async () => {
  // if (mounted.current) {
  //   const result = await allinOne("preparePantheonNotes", { assetId })
  //   if (result?.success) {
  //     return result?.notes
  //   }
  // }
}

const f = async() => {
  const d = await getData()
  console.log(d)
  if (d) {
  }
}

useEffect(() => {
  f()
  return () => { mounted.current = false }
}, [])

  return <>{content}</>
}
export default App;
