
const filterHeader = {
  fontSize: 16,
  padding: "5px 0px 5px 10px",
  border: 0,
  background: "#f6f7f8",
  borderBottom: "1px solid #e7e7e7",
  marginBottom: 5
}

const filterHeaderCard = {
  fontSize: 16,
  padding: "5px 0px 5px 10px",
  border: 0,
  background: "#f6f7f8",
  // borderBottom: "1px solid #e7e7e7",
  marginBottom: 0
}

const formProps = {
  name: "basic",
  autoComplete: "off",
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
  // style: { maxWidth: 800 }
}


const cardStyle = {
  height: 130,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 50
}

const cardStyleGauge = {
  height: 130,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 16
}

const cardStyleGraph = {
  borderRadius: 0,
  border: "1px solid #e7e7e7"
  // height: 125,
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  // fontSize: 16
}

const textArea = {
  height: 350,
}

const pagination = {
  position: ["bottomCenter"],
  defaultPageSize: 15,
  pageSizeOptions: ["15", "25", "50"],
  showSizeChanger: true,
  locale: { items_per_page: "" },
}

export { filterHeader, formProps, textArea, cardStyle, filterHeaderCard, cardStyleGauge, cardStyleGraph, pagination }
