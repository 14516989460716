/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';



const App = ({ text, children }) => {
  // const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // console.log({
  //   text,
  //   children
  // })
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {ReactDOM.createPortal(
        <div className="position-fixed top-50 start-50 bottom-50 translate-middle "
          style={{
            position: 'absolute',
            left: position.x + 10,
            top: position.y + 10,
            // background: 'rgb(255, 255, 255)',
            color: '#fff',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 80,
                }}
                spin
              />
            }
          />
        </div>,
        document.body
      )}
    </div>
  );
};

export default App;
