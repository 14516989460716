import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
import { singleRow, colorCircle } from "src/layouts/widgets/CustomLayout/index"

const getValues = (v) => {
  return(
     <>
       {v.map((el, i) => {
         const _i = i + 1
         return <div key={JSON.stringify(i)}>{_i}: {el?.operationName}</div>
       })}
     </>
  )
}


const Infos = (props) => {
  const context = useContext(MainContext);
  const [ info, setInfo ] = useState(props?.dataProps)
  const { lang, queryPut, authToken, dateTime, layout, user } = context.state
  const left = props.style ? props?.style.left : { width: "50%", span: { float: "right", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "", maxWidth: "100%", fontWeight: "bold" }  }
  const right = props.style ? props?.style.right : { width: "50%", span: { float: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" } }
  const _lang = (e) => {
    return lang?.[e] ?? e
  }
  return (
    <div>
      {info.map((el, i) => {
        if (!el.values) {
          return(
            <Fragment key={i}>
              {singleRow({ left, right, leftTitle: _lang(el.label), rightTitle: _lang(el.value), tooltip: _lang(el?.tooltip), tooltipValue: _lang(el?.tooltipValue) })}
            </Fragment>
          )
        }else {
          const values = el?.values ? JSON.parse(el?.values) : []
          if (Array.isArray(values)) {
            return(
              <Fragment key={i}>
                {singleRow({ left, right, leftTitle: _lang(el?.label), rightTitle: getValues(values) }) }
              </Fragment>
            )
          }
          return <div></div>
        }
      })}
    </div>
  )
}
// {singleRow({ left, right, leftTitle: lang?.[el?.label] ?? el?.label, rightTitle: getValues([...values, ...values, ...values]) }) }

export default Infos;
