/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext, useEffect } from 'react'
import { MainContext } from 'src/Context';
import { useHistory } from "react-router-dom";
import { Drawer, Empty, Row, Col } from 'antd';
import { allinOne, allinPut, renderIconV2, msg } from "src/utils/functions/filterFunction"
import { content, findShift } from "src/utils/functions/core"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { DrawerContent } from "src/layouts/widgets/Companies/i4c_V2/components/layouts"
import IdleTime from './IdleTime';
import { spinning } from "src/layouts/widgets/CustomLayout";
import TimelineChart from './TimelineChart';
import EventsChart from './EventsChart';
import IdleTimeEvents from './IdleTimeEvents';

const formataData = (d, events) => {
  const times = [
    { value: 0, label: "stopTime" },
    { value: 1, label: "productionTime" },
    { value: 2, label: "idleTime" },
    { value: 3, label: "undefinedTime" }
  ]
  let productionInterval = []

  times.forEach(i => {
    let dataFilter = d.length === 0 ? [] : d.filter(el => el.value === i.value)
    const x = dataFilter.map(el => {
      const _e = events.filter((f) => (f?.startDate >= el.timestampFrom) && (f?.endDate <= el.timestampTo))
      return {
        y: [el.timestampFrom, el.timestampTo],
        x: "time",
        e: (events.filter((f) => (f?.startDate >= el.timestampFrom) && (f?.endDate <= el.timestampTo))?.length) ? (events.filter((f) => (f?.startDate >= el.timestampFrom) && (f?.endDate <= el.timestampTo))?.length) : ""
      }
    })
    productionInterval.push({
      name: i.label,
      data: x
    })
  })
  return productionInterval
}

const App = (props) => {
  const { user, layout } = content()
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const history = useHistory()
  const now = Date.now()
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  //const start = roundDate10Min(now - (28800000))
  //const end = roundDate10Min(now)

  const _now = new Date(now)
  // console.log(content())

  const [isLoading, setIsLoading] = useState()
  const { lang } = context.state
  // console.log(context.state)
  const shift = findShift(_now, layout?.shift)
  const start = shift.start
  const end = shift.end

  const [state, setState] = useState({
    ...props,
    tempEvents: [],
    series: [],
    seriesSelected: [],
    options: {},
    optionsSelected: {},
    _data: [],
    zoom: false,
    start,
    end,
    filter: false,
    drawer: false,
    idleTimeDrawer: false
  })


  const getData = async () => {
    const { auth_token } = content()
    const id = props?.asset?._id
    const start = state?.start //? state?.start : (now - (28800000))
    const end = state?.end - 10//? state?.end-10 : now

    setIsLoading(true)
    if (mounted.current) {
      const result = await allinOne("assetDashboard", { id, start, end }, auth_token)
      // console.log(result)
      if (result.success) {
        // history.push({ search: ""})
        return result
      }
    }
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      if (!state?.filter) {
        setState({
          ...d,
          ...state,
          series: (d?.data?.length) ? formataData(d?.data, d?.events) : [],
          seriesSelected: formataData(d?.data, d?.events),
          data: d?.data,
          _data: d?.data,
          drawer: false,
          events: d?.events,
          kartemCodeGroup: d?.defaultValues?.param?.kartemCodeGroup
        })
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    mounted.current = true
    f()
    return () => { mounted.current = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.start])


  const reformat = (props, e) => {
    const start = props?.timestamp?.start
    const end = props?.timestamp?.end
    const f = (end - start) * (e / 100)
    const startP = (start + f)
    return startP //dateTimeFormatter("date", startP, "hh:mm")
  }

  const onChange = (e) => {
    const _newStart = (end - start) * (e?.[0] / 100)
    const _newEnd = (end - start) * (e?.[1] / 100)
    const newStart = (start + _newStart)
    const newEnd = (start + _newEnd)
    const newStartR = dateTimeFormatter("date", newStart, "hh:mm")
    const newEndR = dateTimeFormatter("date", newEnd, "hh:mm")
    const d = state?._data.filter((f) => (f?.timestampFrom >= newStart) && (f?.timestampTo <= newEnd))
    setState({
      ...state,
      series: formataData(d, state?.events),
      filter: true
      // options:
    })
  };

  const switchZoom = () => {
    setState({
      ...state,
      zoom: !state?.zoom
    })
  }

  const handleClick = (el, drawer) => {
    const content = (el?.value?.type === "cancelPause") ? {} : el?.value
    setState({ ...state, drawer, event: content })
  }
  // console.log(state)
  const prepareEvents = async (e) => {
    const { auth_token } = content()
    const start = state?.timestamp?.start
    const end = state?.timestamp?.end
    const id = props?.asset?._id

    const _keys = Object.keys(e?.marks)
    const keys = _keys.slice(1)
    let events = []
    keys.forEach((el, i) => {
      events.push({
        ...e?.marks[el],
        start: e?.marks[el]?.start,
        end: e?.marks[el]?.end,
        _start: e?.marks[el]?._start,
        _end: e?.marks[el]?._end,
        marks: e?.marks[el]?.value
      })
    })
    setIsLoading(true)
    const result = await allinPut("upsertDocumentEvent", { id, events: events, type: "Event", user, asset: props?.asset, eventTypes: state?.eventTypes }, auth_token)
    if (result?.success) {
      setState({
        ...state,
        ...e,
        events,
        _events: events,
        drawer: false
      })

      const range = [state?.start, state?.end]

      console.log([dateTimeFormatter("date", state?.start, "dd.mm hh:mm"), dateTimeFormatter("date", state?.end, "dd.mm hh:mm")])
      setTimeout(() => {
        f()
      }, 2000)
    }
  }

  const handleIdleTime = (e) => {
    setIsLoading(true)
    setState({ ...state, ...e })
  }

  const openIdleTime = (e) => {
    if (e?.findEvents?.length > 0) {
      setState({
        ...state,
        drawer: true,
        idleTimeDrawer: true,
        tempEvents: e?.findEvents
      })
    }
  }

  const callbackidleTimeEvents = (e) => {
    setState({
      ...state,
      drawer: e,
      idleTimeDrawer: e
    })
  }

  const _idleTime = state?.series?.find((f) => f?.name === "idleTime")
  const idleTime = _idleTime?.data
  const events = state?.events
  const eventTypes = state?.eventTypes
  const idleTimeSteps = idleTime ? idleTime.map((el) => ({ ...el, status: events.find((f) => f?.startDate === el?.y[0]) ? "finish" : "wait" })) : []

  const shiftCol = {
    background: "#e7e7e7",
    position: "relative",
    cursor: "pointer",
    div: {
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  }

  const iconProps = { size: 30, color: "" }
  const handleShift = (e) => {
    if (e === "right") {
      if (state?.end >= now) {
        // console.log("right-not")
        msg("info", (lang?.["lastShift"] ?? "lastShift"), 1)
      } else {
        setState({
          ...state,
          start: state.end,
          end: state.end + 28800000
        })
      }
    } else {
      // console.log(e)
      setState({
        ...state,
        start: state.start - 28800000,
        end: state.start
      })
    }
  }

  function handleResize() {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const series = (state?.series?.length > 0) ? state?.series : false

  return (
    <div style={{ width: "100%" }}>
      {isLoading && spinning(50)}
      <Row>
        <Col onClick={() => !isLoading && handleShift("left")} style={shiftCol} flex="100px"><div style={shiftCol?.div}>{renderIconV2("BiSolidLeftArrow", iconProps?.size, iconProps?.color)}</div></Col>
        <Col flex="auto">
          {series ? <TimelineChart
            key={JSON.stringify(series)}
            series={series}
            openIdleTime={openIdleTime}
            handleIdleTime={handleIdleTime}
            idleTime={idleTime}
            events={events}
            eventTypes={eventTypes}
            timestamp={[state?.start, state?.end]}
            data={state?.data}
          />
            : <Empty />}
        </Col>
        <Col onClick={() => !isLoading && handleShift("right")} style={{ ...shiftCol, marginLeft: 15 }} flex="100px"><div style={shiftCol?.div}>{renderIconV2("BiSolidRightArrow", iconProps?.size, iconProps?.color)}</div></Col>
      </Row>
      <div style={{ padding: "10px 10px 10px 26px", width: "100%" }}>
        {series ? <EventsChart
          key={JSON.stringify(series)}
          openIdleTime={openIdleTime}
          handleIdleTime={handleIdleTime}
          idleTime={idleTime}
          events={events}
          eventTypes={eventTypes}
          timestamp={[state?.start, state?.end]}
          data={state?.data}
        />
          : <Empty />}
      </div>
      {series ? <IdleTime
        openIdleTime={openIdleTime}
        handleIdleTime={handleIdleTime}
        idleTime={idleTime}
        events={events}
        timestamp={[state?.start, state?.end]}
      />
        : <Empty />}
      <Drawer
        // key={JSON.stringify(state?.buttons)}
        {...state?.drawerProps}
        afterOpenChange={(open) => open && setIsLoading(false)}
        open={state?.drawer}>
        <div>
          <h5>{state?.content?.subject}</h5>
          {state?.idleTimeDrawer ?
            <IdleTimeEvents key={JSON.stringify({ ...state })} callbackidleTimeEvents={callbackidleTimeEvents} events={state?.tempEvents} /> :
            <DrawerContent key={JSON.stringify({ ...state?.drawerProps, ...state?.timestamp })} prepareEvents={prepareEvents} handleClick={handleClick} {...state} />}
        </div>
      </Drawer>
    </div>
  )
}
export default App;
