const body = {
  // marginTop: 80,
  // maxHeight: "auto",
}

const all = {
  emptySpace: {
    padding: 5
  }
}

const html = {
  background: ""
}

const content = {
  paddingTop: 15,
  singleCard: {
    paddingTop: 90,
  }
}

const cardUserButton = {
  padding: 10,
  card: {
    width: 400,
    fontSize: 30,
    justifyContent: "center",
    cursor: "pointer",
    textAlign: "center",
    height: 200,
  },
  singleCard: {
    // width: "100%",
    // marginLeft: "25%",
    justifyContent: "center",
    textAlign: "center",
    button: {
      width: 650,
      height: 350,
      fontSize: 40,
      background: "rgb(255, 255, 255)",
      border: "1px solid grey",
      borderRadius: 4
    }
  }
}

const login = {
  position: "",
  backgroundColor: "rgb(255, 255, 255)",
  marginTop: 0,
  padding: 0,
  paddingTop: 10,
  marginBottom: 0,
  marginLeft: "auto",
  marginRight: "auto",
  width: 700,
  height: "auto",
  textAlign: "center",
  // border: "0.1em solid rgba(0, 0, 0, 0.05)",
  borderRadius: 1,
  rowNumPad: {
    full: {
      fontSize: 10,
      height: 70,
      width: 80,
    },
    input: {
      textAlign: "center",
      fontSize: 20,
    },
    backgroundColor: "",
    col: {
      cursor: "pointer",
    },
    Button: {
      fontSize: 40,
      height: 100,
      width: "100%",
      paddingTop: 0,
      border: "0.1em solid rgba(0, 0, 0, 0.05)",
      hidden: {},
      invert: {
        backgroundColor: "",
      },
      nul: {
        width: "100%",
        height: 100,
        fontSize: 40,
        border: "0.1em solid rgba(0, 0, 0, 0.05)",
      },
      enter: {
        width: "100%",
        fontSize: 30
      },
      form: {
        textAlign: "center"
      }
    }
  }
}

// main content
const userInterface = {
  body: {
    // marginTop: 10,
  },
  first: {
    position: "relative",
    textAlign: "center",
  },
  second: {
    position: "relative",
    textAlign: "center",
  },
  row: {
    marginTop: "3%",
    textAlign: "center",
    logout: {
      float: "right"
    },
    config: {
      float: "right"
    },
    footer: {
      position: "relative",
      marginBottom: "5%",
    }
  },
  col: {},
  card: {
    // border: "3px solid rgb(1, 1, 1)",
    cursor: "pointer",
    borderRadius: 10,
    width: 400,
    height: 250,
    marginLeft: "10%",
    logout: {
      width: 70,
      height: 50,
    },
    config: {
      width: 70,
      height: 50,
    },
  },
  span: {
    fontSize: 30,
    marginTop: "30%",
    // marginLeft: "20%",
    logout: {
      cursor: "pointer",
      textAlign: "center",
      marginTop: "20%",
      // marginLeft: "30%"
    },
    config: {
      cursor: "pointer",
      textAlign: "center",
      marginTop: "20%",
    },
  },
  header: {},
  footer: {},
  about: {
    textAlign: "left"
  }
}

const card = {
  fontSize: 30,
  textAlign: "center",
  justifyContent: "center",
}

// select dropdownLang
const dropdownLang = {
  input: () => ({
    textAlign: "left",
    minWidth: 500
  }),
  control: (base) => ({
    ...base,
    minHeight: 60,
    maxHeight: 28,
    borderRadius: 0,
    maxWidth: 400,
    borderStyle: "none",
    marginTop: 5
  }),
  option: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: state.isSelected ? "" :  "rgb(214, 224, 255)",
    },
    active: "red",
    textAlign: "center",
    //color: "grey",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    minHeight: 25,
    maxHeight: 25,
  }),
  valueContainer: (base, state) => ({
    ...base,
    fontSize: 25,
    padding: "0 0 0 0",
    marginLeft: 30,
  }),
  container: (base) => ({
    ...base,
    borderStyle: "1px solid rgb(255, 255, 255)",
  }),
  menuList: (base) => ({
    ...base,
    maxWidth: 400,
  }),
  menuPortal: (base) => ({
    ...base,
    maxWidth: 400,
    textAlign: "center",
  }),
  menu: (base) => ({
    ...base,
    maxWidth: 400,
  })
}

const workOrder = {
  marginTop: "5%",
  card: {
    backgroundColor: "",
    padding: 0,
  },
  cardHeader: {
    textAlign: "center",
    fontSize: 30,
    // width: 400,
    // justifyContent: "center",
    // cursor: "pointer",
    // height: 200,
  },
  buttonBig_Prev: {
    background: "rgba(255, 237, 0, 0.42)",
    color: "Black",
    border: "none",
    height: 50,
    borderRadius: 5
  },
  buttonBig_Operation: {
    background: "rgba(0, 255, 10, 0.29)",
    color: "Black",
    border: "none",
    height: 50,
    borderRadius: 5
  },
  buttonBig_Next: {
    background: "rgba(0, 56, 255, 0.24)",
    color: "Black",
    border: "none",
    height: 50,
    borderRadius: 5
  },
  buttonBig_Start: {
    background: "rgb(117, 255, 123)",
    color: "black",
    border: "none",
    height: 50,
    borderRadius: 5
  },
  buttonDone: {
    background: "rgba(255, 0, 0, 0.49)",
    color: "black",
    border: "none",
    height: 35,
    borderRadius: 5
  },
  buttonEdit: {
    background: "rgba(0, 0, 0, 0.14)",
    color: "black",
    border: "none",
    height: 35,
    borderRadius: 5
  },
  buttonClose: {
    background: "rgba(0, 0, 0, 0.26)",
    color: "white",
    border: "none",
    height: 50,
    borderRadius: 5,
    width: "100%"
  },
  select: {
    input: () => ({
      // textAlign: "left",
      // minWidth: 500
    }),
    control: (base) => ({
      ...base,
    }),
    option: (base, state) => ({
      ...base,
      fontSize: 25
    }),
    indicatorsContainer: (base) => ({
      ...base,
    }),
    valueContainer: (base, state) => ({
      ...base,
      fontSize: 25
    }),
    container: (base) => ({
      ...base,
    }),
    menuList: (base) => ({
      ...base,
    }),
    menuPortal: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
      maxWidth: "95%",
    })
  }
}

const header = {
  //position: "fixed",
  left: 0,
  top: 5,
  width: "100%",
  backgroundColor: "white",
  color: "grey",
  textAlign: "center",
  // fontSize: 30,
  title: {
    fontWeight: "bold"
  },
  button: {
    // border: "2px solid rgb(240, 14, 14)",
    fontSize: 30,
    float: "right",
    width: 100,
    borderRadius: 10,
    height: 70,
    marginLeft: 10,
    // zIndex: 9999
  },
  buttonInterrupt: {
    border: "2px solid rgb(255, 0, 0)",
    fontSize: 30,
    float: "right",
    width: 100,
    borderRadius: 10,
    height: 70,
    marginLeft: 10,

    // zIndex: 9999
  },
  buttonInterruptAsPage: {
    border: "2px solid rgb(255, 0, 0)",
    fontSize: 30,
    // float: "right",
    width: 100,
    borderRadius: 10,
    height: 70,
    marginLeft: 10,

    // zIndex: 9999
  },
  buttonCustomHeader: {
    // border: "2px solid rgb(240, 14, 14)",
    fontSize: 30,
    // float: "right",
    width: 100,
    borderRadius: 10,
    height: 70,
    // marginLeft: 10,
    // zIndex: 9999
  },
  icon: {
    // backgroundColor: "red",
    marginBottom: 10,
    // color: "rgb(0, 3, 255)"
  },
  reactIcon: {
    fontSize: 18,
    marginBottom: 3,
    color: "rgb(0, 0, 0)"
  },
  span: {
    float: "right",
    fontWeight: "bold",
  },
  col: {
    marginTop: 20,
    paddingRight: 10
    // marginRight: 10
  },
  license: {
    backgroundColor: "yellow",
    height: 50,
    fontSize: 20
  }
}

const footer = {
  position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%",
  backgroundColor: "white",
  color: "grey",
  textAlign: "center",
  fontSize: 16,
  button: {
    float: "right",
    marginBottom: 5,
    marginLeft: 10
  },
  col: {
    marginTop: -10
  },
  span:{
    float: "right"
  }
}

// table
const antDTable = {
  fontWeight: "bold",
  fontSize: 18
}

// spinner
const alert = {
  fontSize: 20
}

// SELECT dropdown LOGIN
const dropdown = {
  input: () => ({
    textAlign: "left",
    minWidth: 160,
    fontSize: 20
  }),
  singleValue: (base) => ({
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = 'opacity 300ms';
  }),
  control: (base) => ({
    ...base,
    minHeight: 40,
    maxHeight: 40,
    borderRadius: 0,
    maxWidth: 200
  }),
  option: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: state.isSelected ? "" :  "rgb(214, 224, 255)",
    },
    active: "red",
    textAlign: "left",
    //color: "grey",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    minHeight: 25,
    maxHeight: 25,
  }),
  valueContainer: (base, state) => ({
    ...base,
    fontSize: 20,
    padding: "0 0 0 0",
  }),
  container: (base) => ({
    ...base
  }),
  menuList: (base) => ({
    ...base,
    maxWidth: 160
  }),
  menuPortal: (base) => ({
    ...base,
    maxWidth: 160
  }),
  menu: (base) => ({
    ...base,
    maxWidth: 160
  })
}

const switchStyle = {
  fontSize: 50
}

const buttonUser = {
  color: "",
  fontSize: 25,
  height: 70,
  width: 120,
}

const ReactSelectStyle = {
  input: () => ({
    textAlign: "left",
    minWidth: 200,
  }),
  singleValue: (base) => ({
    fontSize: 25,
    fontWeight: "normal"
  }),
  control: (base) => ({
    ...base,
    // minHeight: 30,
    // maxHeight: 100,
    height: 50,
    borderRadius: 0,
    width: 300,
    fontWeight: "normal"
  }),
  option: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: state.isSelected ? "" :  "rgb(214, 224, 255)",
    },
    active: "red",
    textAlign: "left",
    // color: "grey",
    fontWeight: "normal",
    zIndex: 999,
    fontSize: 25,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    minHeight: 28,
    maxHeight: 28,
    padding: 0
  }),
  valueContainer: (base, state) => ({
    ...base,
    fontSize: 25,
    padding: "0 0 0 0",
  }),
  container: (base) => ({
    ...base,
  }),
  menuList: (base) => ({
    ...base,
    // maxWidth: 200,
    width: 300
  }),
  menuPortal: (base) => ({
    ...base,
  }),
  menu: (base) => ({
    ...base,
    width: 300
    // maxWidth: 200
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 20
  }),
  dropdownIndicator: () => ({
    color: "white"
  }),
  indicatorSeparator: () => null
}


const fullscreenStyle =  {
  paddingTop: 8,
  overflow: "scroll",
  backgroundColor: "rgb(255, 255, 255)"
}

const productSelect = {
  // display: "flex",
  // position: "absolute",
  //
  // left: "25%",
  // height: "100%",
  // width: "100%",
  step: {
    textAlign: "left",
    fontSize: 28
  },
  msgWarn: {
    color: "red"
  },
  isFinished: {
    color: "rgb(30, 168, 1)",
    fontSize: 28
  },
  isFinishedDropDown: {
    color: "rgb(30, 168, 1)",
    fontSize: 25,
    marginBottom: 5
  },

  notFinished: {
    color: "rgb(205, 33, 33)",
    fontSize: 28
  },
  fcNext: {
    color: "rgb(0, 0, 0)",
  },
  stickyRow: {
    position: "fixed",
    left: 0,
    bottom: 60,
    width: "100%",
    backgroundColor: "white",
    textAlign: "center",
  },
  title: {
    paddingBottom: 20,
    textAlign: "center",
    fontSize: 20
  },
  row: {},
  col: {
    paddingLeft: 0
  },
  span: {
    float: "right",
    fontSize: 25
  },
  inputForm: {
    // minHeight: 30,
    // maxHeight: 100,
    // borderRadius: 0,
    // border: "1px solid rgb(180, 175, 174)",
    fontSize: 25,
    height: 50,
    width: 300,
    textAlign: "center"
  },
  addButton: {
    fontSize: 80,
    height: 50,
    border: 0,
    color: "black",
    // float: "right",
    cursor: "pointer",
    // float: "left",
    isDisabled: {
      fontSize: 50,
      height: 50,
      border: 0,
      color: "grey",
      float: "right",
      cursor: "pointer",
      // float: "left",
    }
  },
  nextButton: {
    fontSize: 22,
    height: 70,
    width: 300
  },
  list: {
    fontSize: 25,
  },
  radio: {
    fontSize: 22,
  },
  table: {
    width: "100%",
    thead: {
      fontWeight: "bold",
      fontSize: 20
    },
    body: {
      fontWeight: "normal",
      fontSize: 20
    },
    tr: {},
    tbodySelect: {
      padding: 15,
      textAlign: "center"
    },
    trSelect: {},
    thSelect: {
      padding: 15,
      fontSize: 25,
      paddingLeft: 10,
      paddingRight: 10,
      fontWeight: "normal"
    },
    th: {
      padding: 5,
      paddingTop: 20
    },
    button: {
      fontSize: 15,
      height: 40,
      width: "100%"
    }
  },
  button: {
    fontSize: 15,
    height: 40,
    width: "100%"
  },
  hidden: {
    color: "red",
    fontSize: 20
  }
}

const hftesting = {
  inputForm: {
    minHeight: 30,
    maxHeight: 100,
    height: 50,
    borderRadius: 0,
    border: "1px solid rgb(180, 175, 174)",
    width: "100%"
  },
  inputFormVersion: {
    minHeight: 30,
    maxHeight: 100,
    height: 50,
    borderRadius: 0,
    border: "1px solid rgb(180, 175, 174)",
    width: "50%"
  },
  tableSpanRow: {
    fontSize: 20,
    cursor: "pointer"
  },
  isTestedIcon: {
    color: "rgb(30, 168, 1)",
    fontSize: 28,
    cursor: "pointer"
  },
  notTestedIcon: {
    color: "rgb(205, 33, 33)",
    fontSize: 20
  },
  showMsg: {
    color: "red",
    fontSize: 20
  },
  span: {
    float: "right",
    fontSize: 25
  },
  stickyRow: {
    position: "fixed",
    left: 0,
    bottom: 60,
    width: "100%",
    backgroundColor: "white",
    textAlign: "center",
  },
  buttons: {
    fontSize: 22,
    height: 70,
    width: 300,
    textAlign: "center",
  },
  colButton: {
    textAlign: "center",
    button: {
      backgroundColor: "rgb(212, 43, 43)",
      height: 50,
      width: 200,
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
    nextButton: {
      backgroundColor: "rgb(66, 232, 45)",
      height: 50,
      width: "auto",
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    }
  },
  colLeft: {
    textAlign: "right",
    button: {
      backgroundColor: "rgb(80, 198, 113)",
      width: 200,
      height: 70,
      fontSize: 25,
      border: "none",
      borderRadius: 4,
      color: "white",
    },
  },
  colRight: {
    textAlign: "left",
    button: {
      backgroundColor: "rgba(71, 0, 254, 0.53)",
      width: 200,
      height: 70,
      fontSize: 25,
      border: "none",
      borderRadius: 4,
      color: "white",
    },
  },
  cardsInfo: {
    textAlign: "center",
    width: 20,
    card: {},
    in: {
      // backgroundColor: "rgba(255, 0, 0, 0.37)",
      justifyContent: "center",
      fontSize: 40,
      marginTop: 10,
      marginLeft: 30,
      marginRight: 30,
      marginBottom: 10,
      height: 100,
      fontWeight: "bold"
    },
    input: {
      border: "none",
      fontSize: 40,
    }
  },
  select: {
    input: () => ({
      textAlign: "left",
      minWidth: 200,
    }),
    singleValue: (base) => ({
      fontSize: 25
    }),
    control: (base) => ({
      ...base,
      minHeight: 30,
      maxHeight: 100,
      height: 50,
      borderRadius: 0,
      width: 200
    }),
    option: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: state.isSelected ? "" :  "rgb(214, 224, 255)",
      },
      active: "red",
      textAlign: "left",
      // color: "grey",
      zIndex: 999,
      fontSize: 25,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      minHeight: 28,
      maxHeight: 28,
    }),
    valueContainer: (base, state) => ({
      ...base,
      fontSize: 14,
      padding: "0 0 0 0",
    }),
    container: (base) => ({
      ...base,
    }),
    menuList: (base) => ({
      ...base,
      // maxWidth: 200,
      width: 200
    }),
    menuPortal: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
      width: 200
      // maxWidth: 200
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 25
    }),
    dropdownIndicator: () => ({
      color: "white"
    }),
    indicatorSeparator: () => null
  },
  tableCol: {
    textAlign: "center",
    table: {
      height: 600,
      overflow: "scroll",
    },
    button: {
      backgroundColor: "rgba(0, 0, 0, 0.53)",
      height: 70,
      width: 170,
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
    buttonfinished: {
      backgroundColor: "rgb(13, 175, 12)",
      height: 40,
      width: "auto",
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
    buttonfinishedRed: {
      backgroundColor: "red",
      height: 40,
      width: "auto",
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    }
  }
}

const hfwelding = {
  cardIsPassed: {
    background: "rgba(43, 255, 0, 0.48)"
  },
  cardIsNOTPassed: {
    background: "rgba(255, 0, 0, 0.63)",
    color: "#ffffff",
    fontWeight: "bold"
  },
  body: {
    marginTop: 180,
  },
  rowInfo: {
    fontSize: 20
  },
  step: {
    fontSize: 20
  },
  valuesTool: {
    left: 0,
    width: "100%",
    fontSize: 20,
    textAlign: "center",
  },
  cardWelding: {
    left: 0,
    width: "100%",
    backgroundColor: "white",
    textAlign: "center",
  },
  card: {
    background: "#737373",
    color: "#ffffff"
  },
  cardExclude: {
    background: "#f06b6b",
    color: "#ffffff"
  },
  inputForm: {
    minHeight: 100,
    height: 100,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    borderRadius: 0,
    justifyContent: "center",
    fontSize: 38,
    fontWeight: "bold",
    border: "1px solid rgb(180, 175, 174)",
    color: "#000000"
  },
  inputFormSpecial: {
    height: 100,
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 10,
    borderRadius: 0,
    justifyContent: "center",
    fontSize: 25,
    fontWeight: "bold",
    border: "1px solid rgb(180, 175, 174)",
    color: "#000000"
  },
  span: {
    float: "right",
    fontSize: 25
  },
  stickyRowUp: {
    position: "fixed",
    left: 0,
    top: 100,
    width: "100%",
    backgroundColor: "white",
    textAlign: "center",
  },
  stickyRow: {
    position: "fixed",
    left: 0,
    bottom: 60,
    width: "100%",
    backgroundColor: "white",
    textAlign: "center",
  },
  cardOne: {
    // position: "absolute",
    left: "25%",
    width: "50%",
    textAlign: "center",
  },
  buttonsRow: {
    // position: "fixed",
    left: 0,
    // bottom: 60,
    width: "100%",
    // backgroundColor: "white",
    textAlign: "center",
  },
  buttons: {
    fontSize: 22,
    height: 70,
    width: 300,
    textAlign: "center",
  },
  colButton: {
    textAlign: "center",
    button: {
      backgroundColor: "rgba(255, 0, 0, 0.37)",
      height: 50,
      width: 200,
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
  },
  colLeft: {
    textAlign: "right",
    button: {
      backgroundColor: "rgb(80, 198, 113)",
      width: 200,
      height: 100,
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
  },
  colRight: {
    textAlign: "left",
    button: {
      backgroundColor: "rgba(71, 0, 254, 0.53)",
      width: 200,
      height: 100,
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
  },
  cardMain: {
    cursor: "pointer",
    height: 160,
    justifyContent: "center",
  },
  cardMainHighlight: {
    cursor: "pointer",
    backgroundColor: "rgba(255, 247, 45, 0.82)",
    height: 160,
    justifyContent: "center",
  },
  cardMainHighlightFinish: {
    cursor: "pointer",
    backgroundColor: "rgb(122, 217, 168)",
    height: 160,
    justifyContent: "center",
  },
  cardsInfo: {
    textAlign: "center",
    width: 20,
    card: {},
    inWarn: {
      backgroundColor: "rgba(255, 0, 0, 0.37)",
      justifyContent: "center",
      fontSize: 40,
      marginTop: 10,
      marginLeft: 30,
      marginRight: 30,
      marginBottom: 10,
      height: 100,
      fontWeight: "bold"
    },
    inSuccees: {
      backgroundColor: "rgba(72, 255, 0, 0.43)",
      justifyContent: "center",
      fontSize: 40,
      marginTop: 10,
      marginLeft: 30,
      marginRight: 30,
      marginBottom: 10,
      height: 100,
      fontWeight: "bold"
    },
  },
  select: {
    input: () => ({
      textAlign: "left",
      minWidth: 200
    }),
    singleValue: (base) => ({
      fontSize: 25
    }),
    control: (base) => ({
      ...base,
      minHeight: 30,
      maxHeight: 100,
      height: 50,
      borderRadius: 0,
      width: 200
    }),
    option: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: state.isSelected ? "" :  "rgb(214, 224, 255)",
      },
      active: "red",
      textAlign: "left",
      // color: "grey",
      zIndex: 999,
      fontSize: 25,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      minHeight: 28,
      maxHeight: 28,
    }),
    valueContainer: (base, state) => ({
      ...base,
      fontSize: 14,
      padding: "0 0 0 0",
    }),
    container: (base) => ({
      ...base,
    }),
    menuList: (base) => ({
      ...base,
      // maxWidth: 200,
      width: 200
    }),
    menuPortal: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
      width: 200
      // maxWidth: 200
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 25
    }),
    dropdownIndicator: () => ({
      color: "white"
    }),
    indicatorSeparator: () => null
  },
  tableCol: {
    textAlign: "center",
    table: {
      height: 400,
      overflow: "scroll",
    },
    button: {
      backgroundColor: "rgba(0, 0, 0, 0.53)",
      height: 50,
      width: 200,
      border: "none",
      borderRadius: 4,
      color: "white",
      fontSize: 20
    },
  }
}

const about = {
  fontSize: 20,
  button: {
    backgroundColor: "rgba(0, 0, 0, 0.53)",
    height: 50,
    width: 200,
    border: "none",
    borderRadius: 4,
    color: "white",
    fontSize: 20
  }
}

const filter = {
  button: {
    // border: "2px solid rgb(240, 14, 14)",
    float: "right",
    background: "rgb(255, 255, 255)",
    fontSize: 15,
    width: "auto",
    borderRadius: 10,
    height: 70,
    marginLeft: 10
  },
  drawer: {
    backgroundColor: ""
  }
}

const timeRange = {
  rangePicker: {
    width: 800,
    height: 50
  },
  popup: {
    fontSize: 20,
    // padding: 50
    // width: 800,
    // height: 50
  }
}

const button = {
  danger: {
    backgroundColor: "rgb(255, 1, 1)",
    width: 200,
    height: 70,
    fontSize: 25,
    border: "none",
    borderRadius: 4,
    color: "white",
  },
  success: {
    backgroundColor: "rgb(36, 187, 74)",
    width: 200,
    height: 70,
    fontSize: 25,
    border: "none",
    borderRadius: 0,
    color: "white",
  },
  repeat: {
    backgroundColor: "rgb(191, 174, 18)",
    width: 200,
    height: 70,
    fontSize: 25,
    border: "none",
    borderRadius: 4,
    color: "white",
  },
  finish: {
    backgroundColor: "rgb(52, 54, 59)",
    width: 200,
    height: 70,
    fontSize: 25,
    border: "none",
    borderRadius: 4,
    color: "white",
  },
  export: {
    backgroundColor: "rgb(14, 45, 255)",
    width: 200,
    height: 70,
    fontSize: 25,
    border: "none",
    borderRadius: 4,
    color: "white",
  },
}

const piskarDashboard = {
  row: {
    height: 200,
  },
  card_1: {
    height: 150,
    textAlign: "center",
    fontSize: 20,
    background: "rgba(0, 0, 0, 0.02)",
    // width: 200
  },
  card_2: {
    // height: "100%",
    // textAlign: "center",
    // fontSize: 20,
    vhod: {
      height: "100%",
      textAlign: "center",
      fontSize: 20,
      background: "rgba(33, 0, 255, 0.13)"
    },
    izhod: {
      height: "100%",
      textAlign: "center",
      fontSize: 20,
      background: "rgba(0, 255, 10, 0.14)"
    },
    odpad: {
      height: "100%",
      textAlign: "center",
      fontSize: 20,
      background: "rgba(255, 0, 0, 0.19)"
    },
    hitrostPorabe: {
      height: "100%",
      textAlign: "center",
      fontSize: 20,
      background: "rgba(0, 0, 0, 0.02)"
    },
    // width: 200
  },
  graf: {
    height: "auto",
  },
  body: {
    fontSize: 30
  }
}

const inputStyle = {}

const OperationManipulateMo = {
  // background: "red",
  paddingTop: 10,
  Steps: {

  },
  TriggerButton: {
    width: "100%",
    height: 180,
    fontSize: 35,
    border: "1px solid #e7e7e7",
    color: "#000000",
    float: "right",
    textAlign: "center"
  },
  textAreaOperation: {
    borderRadius: 0,
    width: "100%",
    height: 180,
    fontSize: 35,
    border: "1px solid #e7e7e7",
    color: "#000000"
  }
}

const style = {
  all,
  login,
  userInterface,
  content,
  dropdownLang,
  antDTable,
  alert,
  workOrder,
  header,
  footer,
  switchStyle,
  dropdown,
  body,
  buttonUser,
  html,
  card,
  cardUserButton,
  ReactSelectStyle,
  fullscreenStyle,
  productSelect,
  hftesting,
  hfwelding,
  about,
  filter,
  timeRange,
  button,
  piskarDashboard,
  inputStyle,
  OperationManipulateMo
};

export default style;
