/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Drawer } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { content } from "src/utils/functions/core"
import Spinning from "src/layouts/widgets/Core/Spinning"
import Spinner from "src/layouts/widgets/Core/Spinner"
import { MainContext } from 'src/Context';
import { renderIconV2 } from "src/utils/functions/filterFunction"
import { socket } from 'src/utils/socket';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

const App = (props) => {
  // console.log(content())
  const mounted = useRef(true);
  const { assets } = content()
  const context = useContext(MainContext);
  const { assetId } = context.state
  const { style } = context
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [divWidth, setDivWidth] = useState(window.innerWidth);
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [divHeight, setDivHeight] = useState(window.innerHeight);
  // const [ trigger, setTrigger ] = useState(false)
  const [statusPrehodi, setStatusPrehodi] = useState()
  const [open, setOpen] = useState(true)
  const [clock, setClock] = useState()
  const [asset, setAsset] = useState()
  const [one, setOne] = useState(<Spinning />)
  const [two, setTwo] = useState(<Spinning />)
  const [three, setThree] = useState(<Spinning />)

  const placement = "bottom"

  const getData = async () => {
    if (mounted.current) {
      // const result = await allinOne("getSpecOperations", { assetId })
      // const result = await getFetch("", {})
    }
  }

  // const handleResize = () => {
  //   setWindowHeight(window.innerHeight);
  //   setDivHeight(window.innerHeight);
  // };
  //
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const rowStyle = {
    // overflowY: "scroll",
    margin: 0,
  }
  const colStyle = {
    // overflowY: "scroll"
    padding: 0,
    minWidth: 250,
    // maxWidth: "auto"
  }
  const f = async () => {
    // if (mounted.current) {
    //
    // }

    const o = await import("src/layouts/widgets/Companies/i4c_V2/components/DD/basicColumns/one")
    const One = o?.default
    const s = await import("src/layouts/widgets/Companies/i4c_V2/components/DD/basicColumns/second")
    const Second = s?.default
    const t = await import("src/layouts/widgets/Companies/i4c_V2/components/DD/basicColumns/thirth")
    const Thirth = t?.default
    const ic = await import("src/layouts/widgets/Companies/i4c_V2/components/DD/infoCard")
    const InfoCard = ic?.default
    const cl = await import("src/layouts/widgets/Core/clockV2")
    const Clock = cl?.default
    const sp = await import("src/layouts/widgets/Companies/i4c_V2/components/DD/automatisation/statusPrehodi")
    const StatusPrehodi = sp?.default
    const columnsStyle = {
      // borderRight: "1px solid #E7E7E7",
      height: "auto",
      margin: 0
    }

    const _props = {
      cardProps: {
        // title: "fdg",
        style: {
          padding: 10
        }
      },
      divProps: {
        style: {
          margin: 0
        }
      },
    }

    // materialQuality
    // clientBuyerNote
    // opcNote
    // clientRecipientNote
    // Operation.requiredMaterials.materialNote

    // const qualityControl = {
    //   infoCard: "materialQuality",
    //   prefix: "tooltip_",
    //   ..._props
    // }
    // const pantheonNotes = {
    //   infoCard: "clientBuyerNote",
    //   // infoCard: ["clientBuyerNote", "opcNote", "clientRecipientNote"],
    //   ..._props
    // }
    // const mesNotes = {
    //   infoCard: "opcNote",
    //   // infoCard: ["clientBuyerNote", "opcNote", "clientRecipientNote"],
    //   ..._props
    // }
    // const d = await getData()
    // if (d) {
    //   if (mounted.current) {
    //     // const result = await getFetch("", {})
    //   }
    // }
    const customStyle = {
      ...columnsStyle,
      padding: "0px 0px 10px 0px",
      borderLeft: "1px solid #E7E7E7",
      overflowY: "auto",
      height: (divHeight - 50)
    }
    const a = assets.find((f) => f?._id === assetId)
    // console.log(a?.asset_name)
    setAsset(a?.asset_name)
    setClock(<Clock />)
    // <InfoCard {...qualityControl}/>
    // <InfoCard {...pantheonNotes}/>
    // <InfoCard {...mesNotes}/>
    setOne(
      <div style={customStyle}>
        <One {...props} />
      </div>
    )
    // console.log(divHeight)
    setTwo(
      <div style={customStyle}>
        <Second {...props} />
      </div>
    )
    setThree(
      <div style={customStyle}>
        <Thirth {...props} />
      </div>
    )
    // console.log(props)

    setStatusPrehodi(<StatusPrehodi />)
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [])

  // useEffect(() => {
  //   if (trigger) {
  //     setTrigger(false)
  //   }
  // }, [trigger])
  // console.log(content())
  const onClose = (a) => {
    setOpen(false);
    context.contextCall("statusOpId", null)
    context.contextCall("operationId", null)
    const _assetId = (assetId ? assetId : a)
    context.contextCall("assetId", _assetId)
    const { assets } = content()
    const asset = assets.find((f) => f?._id === _assetId)
    // console.log([assetId, a, _assetId])
    setTimeout(() => {
      props?.callback({
        redirect: "4f0a19bc-3414-4cd4-bcc0-51dc44d09478",
        sequence: 3,
        ...asset
      })
    }, 500)
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const titleAsset = {
    // fontSize: 20,
    position: "relative",
    left: "50%",
    // top: "50%",
    transform: "translate(-50%, -50%)"
  }

  useEffect(() => {
    socket.on("triggerDDModal", (res) => {
      props?.callback()
    })
  }, [props])

  return (
    <div>
      <Drawer
        // key={JSON.stringify(divHeight)}
        placement={placement}
        title={
          <span onClick={onClose} style={{ display: "block", width: "100%", height: 40 }}>
            <span style={{ float: "right", zIndex: 1 }}>{renderIconV2("MdOutlineKeyboardDoubleArrowDown", 40, "", "")}</span>
            <span style={{ float: "left" }} hidden>
              {clock}
            </span>
            <span style={titleAsset}>
              {asset}
            </span>
          </span>
        }
        height={"100%"}
        destroyOnClose={true}
        closable={false}
        onClose={onClose}
        open={open}>
        <Row>
          <Col style={colStyle} md={2}>{one}</Col>
          <Col style={colStyle} md={5}>{two}</Col>
          <Col style={colStyle} md={5}>{three}</Col>
        </Row>
        {statusPrehodi}
      </Drawer>
  </div>
  )
}
export default App;
// <LoadingOutlined style={{ fontSize: 80, position: "relative", top: "50%", left: "50%", color: "#1677ff", zIndex: 99999, transform: "translate(-50%,)" }} spin />
// <Row>
//   <Col md={2}>{one}</Col>
//   <Col md={5}>{two}</Col>
//   <Col md={5}>{three}</Col>
// </Row>

// <div className="wrapper">
//   <div className="one">{one}</div>
//   <div className="two">{two}</div>
//   <div className="three">{three}</div>
// </div>
