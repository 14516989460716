/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import { MainContext } from 'src/Context';
import { Row } from 'react-bootstrap';
import { content } from "src/utils/functions/core"
import { Slider, Button, Card } from 'antd';
import { dateTimeFormatter } from "src/utils/functions/formater"

const percent = (x, y) => {
  return (x / y) * 100;
}

const App = (props) => {
  const context = useContext(MainContext);
  const { lang } = context.state
  const keys = props.events
  const start = dateTimeFormatter("date", keys?.[0]?.startDate, "hh:mm")
  const last = keys[keys.length - 1];
  const end = dateTimeFormatter("date", last?.endDate, "hh:mm")
  return (
    <div style={{ padding: 10 }}>
      <h5>{(keys && keys.length > 2) && (lang?.["lbl_events"] ?? "lbl_events")}</h5>
      <Row style={{ width: "100%" }}>
        <span>
          <Card style={{
              float: "inline-end",
              background: "#de9595",
              border: "none",
              fontSize: 30,
              fontWeight: "bold",
              margin: "auto",
              padding: 10,
              textAlign: "center",
              cursor: "pointer",
              width: 200
            }}
            onClick={() => props?.callbackidleTimeEvents(false)}>
            {lang?.["close"] ?? "close"}
          </Card>
        </span>
      </Row>
      <div style={{ padding: 20 }}>
        {keys.map((el, i) => {
          const subject = el?.subject
          const code = el?.code
          const subCode = el?.subCode
          const _start = el?._start
          const _end = el?.end
          const startDate = el?.startDate
          const endDate = el?.endDate
          const _ = '\xa0\xa0\xa0'
          const marks = {
            0: start,
            [el?.marks?.[0]]: dateTimeFormatter("date", startDate, "hh:mm"),
            [el?.marks?.[1]]: dateTimeFormatter("date", endDate, "hh:mm"),
            100: {
              style: {
                color: '#f50',
              },
              label: end,
            },
          }
          if (subject) {
            const _a = keys.slice(1, -2).pop()
            const a = _a ? Number(_a) : 0
            const b = Number(el)
            const number = i + 1
            return (
              <div key={JSON.stringify(i)} style={{ fontSize: 20, padding: 10 }}>
                <span style={{ fontWeight: "bold" }}>{number}</span>: {subject}
                  <span style={{ textAlign: "justify" }}>
                    {_}<span style={{ fontWeight: "bold" }}>{lang?.["code"] ?? "code"}</span> : <span>{code}</span>
                  {_}<span style={{ fontWeight: "bold" }}>{lang?.["subCode"] ?? "subCode"}</span> : <span>{subCode}</span>
              </span>
              <Slider range marks={marks} defaultValue={el?.marks} disabled={true} />
            </div>
          )
        }
      })}
      </div>
    </div>
  )
}
export default App;
