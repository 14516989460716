/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react'
// import { content } from "src/utils/functions/core"
import { MainContext } from 'src/Context';
import { dateTimeFormatter } from "src/utils/functions/formater"
import { Row, Col } from 'react-bootstrap';

const App = (props) => {
  const context = useContext(MainContext);
  const { lang } = context.state
  const idleTime = props?.idleTime
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [state, setState] = useState({
    details: false,
    findEvents: [],
    events: props?.events
  })
  const events = props?.events

  const itemHandle = (el, findEvents) => {
    const timestamp = {
      start: Number(el?.y[0]),
      end: Number(el?.y[1])
    }
    if (findEvents && findEvents.length === 0) {
      props?.handleIdleTime({ drawer: true, timestamp, findEvents: state?.findEvents })
    }
  }
  function handleResize() {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  return (
    <Row>
      <Col md={6} style={{ height: (windowHeight - 600), overflowY: "scroll" }}>
        <h5>{(idleTime && idleTime.length > 0) && (lang?.["lbl_idleTime"] ?? "lbl_idleTime")}</h5>
        {idleTime?.map((el, i) => {
          const findEvents = events.filter((f) => (f?.startDate >= el?.y[0]) && (f?.endDate <= el?.y[1]))
          const timestamp = {
            start: el?.y[0],
            end: el?.y[1]
          }
          const length = (findEvents && findEvents.length > 0)
          const num = (i + 1)
          return (
            <div
              style={{ fontSize: 20, padding: 5, cursor: "pointer" }}
              onClick={() => itemHandle(el, findEvents)}>
              <span style={{ fontWeight: "bold" }}>{num}</span>: {'\xa0'}
              <span
                style={{ color: length ? "#ff0000" : "" }}
                onClick={() => length && props?.openIdleTime({ drawer: true, timestamp, findEvents })}>
                {dateTimeFormatter("date", el?.y[0], "hh:mm")} - {dateTimeFormatter("date", el?.y[1], "hh:mm")}
                {length ? (" - " + (lang?.["events"] ?? "events") + " - " + findEvents.length) : ""}
              </span>
            </div>
          )
        })}
      </Col>
    </Row>
  )
}
export default App;
