/* eslint-disable no-unused-vars */
import React from 'react'
import { content } from "src/utils/functions/core"
import { Slider } from 'antd';

const App = (props) => {
  const { lang } = content()
  const keys = Object.keys(props)
  return (
    <div>
      <h5>{(keys && keys.length > 2) && (lang?.["customEvents"] ?? "customEvents")}</h5>
      {keys.map((el, i) => {
        const subject = props?.[el]?.event?.subject
        const code = props?.[el]?.event?.code
        const subCode = props?.[el]?.event?.subCode
        const start = props?.[el]?._start
        const end = props?.[el]?._end
        const _ = '\xa0\xa0\xa0'
        // const s = Number(findValue(keys, el))
        if (subject) {
          const _a = keys.slice(1, -2).pop()
          const a = _a ? Number(_a) : 0
          const b = Number(el)
          const number = i //+ 1
          return (
            <div key={JSON.stringify(i)} style={{ fontSize: 20, padding: 10 }}>
              <span style={{ fontWeight: "bold" }}>{number}</span>: {subject}
              <span style={{ textAlign: "justify" }}>
                {_}<span style={{ fontWeight: "bold" }}>{lang?.["code"] ?? "code"}</span> : <span>{code}</span>
                {_}<span style={{ fontWeight: "bold" }}>{lang?.["subCode"] ?? "subCode"}</span> : <span>{subCode}</span>
              </span>
              <Slider range marks={props} defaultValue={props?.[el]?.value} disabled={true} />
            </div>
          )
        }
      })}
    </div>
  )
}
export default App;
