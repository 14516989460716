import React from "react";
import { MainContext } from './Context';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Header, Footer, Login, Error } from './layouts/layouts';
import ContentLayout from './layouts/layoutComponents/ContentLayout';
import { FullScreen } from "@chiragrupani/fullscreen-react";

import { SocketPage } from './layouts/layouts';

const history = createBrowserHistory();
class App extends React.Component {
  _isMounted = false;
  static contextType = MainContext;
  constructor(props) {
    super(props)
    this.state = {}
    this.ref = React.createRef();
  }

  componentDidMount(){
    this.logOut()
    this._isMounted = true
  }

  componentDidUpdate(){
    const { isFull } = this.context.state
    const elem = document.documentElement
    this.logOut()
    window.addEventListener('keydown', (event) => {
      if (event.key === "Escape" && !this._isMounted) {
        this._isMounted = true
        this.context.contextCall("isFull",  false)
      }
    });

    if (isFull && this._isMounted) {
      elem.requestFullscreen()
      this._isMounted = false
    }
  }

  fullScreen = (e) => { this.context.fullScreen(e) };

  logOut = () => {
    const { logOut } = this.context.state;
    if (!localStorage.getItem("userConfig") || logOut) { history.push('/Login') }
  }

  routesDinamicly = () => {

    if (history.location.pathname === "/") { return <Redirect to={'/dashboard'}/>}
      const { pages } = this.context.state.userConfig.pages;
      const { style, isFull, socketConn } = this.context.state

      // console.log(document.documentElement)

      return(
        <div>
          {/*<FullScreen isFullScreen={isFull} onChange={isFull => this.fullScreen(isFull)}>*/}
            <div style={{ background: "white" }}>
              {/*
                <Header data={this.state}/>
                */}
              <div style={style.content}>
                <Switch>
                  {pages.map(el => {
                    return(
                      <Route
                        key={el.route}
                        path={el.route + "/:SQ?"}
                        render={props => {return(<ContentLayout {...props} layoutData={el}/>)}}/>)
                      }
                    )}
                    <Route exact path="*" component={Error} />
                  </Switch>
                </div>
                <Footer data={this.state}/>
                <SocketPage />
              </div>
            {/*</FullScreen>*/}
          </div>
        )
      }

      render() {
        const { userConfig, switchTheme, style, socketConn } = this.context.state
        switchTheme ? require("./assets/css/light.css") : require("./assets/css/dark.css")
        return (
          <div style={style.body}>
            <Router history={history}>
              <Route path="/Login" history={this.props.history} component={Login} exact />
              {userConfig && socketConn ? this.routesDinamicly() : null}
            </Router>
          </div>
        )
      }
    }

    export default App;
