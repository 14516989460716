import React from 'react'
import { MainContext } from '../../../Context';

class Clock extends React.Component {
  static contextType = MainContext

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.liveClock(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  liveClock = () => {
    this.setState({
      time: this.clockFormat()
    });
  }

  clockFormat = () => {
    var d = new Date();
    var s = d.getSeconds();
    var m = d.getMinutes();
    var h = d.getHours();
    // return (h +':' + m + ':' + s)
    return ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2)
  }

  dateFormat = () => {
    var d = new Date();
    var day = String(d.getDate()).padStart(2, '0');
    var month = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var year = d.getFullYear();
    return (day + '.' + month + '.' + year)
  }

  render() {
    return (
      <span>{this.dateFormat()}{" - "}{this.clockFormat()}</span>
    )
  }
}

export default Clock;
