import React from 'react'
import { MainContext } from 'src/Context';
import { Card, Row, Col } from 'react-bootstrap';
import { Tooltip, Image } from 'antd';
import { Spinner } from "src/layouts/layouts"
import { Infos } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { formatValue } from "src/utils/functions/filterFunction"
import { singleRow, colorCircle, colorPrio } from "src/layouts/widgets/CustomLayout/index"
import { allinOne, promiseToast, checkPromisStatus } from "src/utils/functions/filterFunction"
import { content } from "src/utils/functions/core"

class InfoCard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
  }

  componentDidMount() {
    this._isMounted = true
    this.getData()
  }

  getData = async () => {
    this._isMounted = true
    // const { querySelect, authToken } = this.context.state
    // const { dataProps } = this.state

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { lang, undefObjHandler, dateTimeFormatter } = this.context.state
    const { clientUrl, layout } = content()
    // const { startDate, user } = this.state.dataProps.param
    const { dataProps } = this.state
    if (dataProps) {
      // console.log(dataProps)
      const keys = undefObjHandler(dataProps, "selectedProduct") ? Object.keys(dataProps) : []
      // console.log(keys)
      // const keys = undefObjHandler(dataProps, "operation") && Object.keys(dataProps.param.operation)
      // console.log(keys)
      const left = { width: "40%", span: { float: "right", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%", fontWeight: "bold" } }
      const right = { width: "60%", span: { float: "left", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" } }
      const showKeys = ["amount", "description"]
      const info = [
        { label: lang["amount"] ? lang["amount"] : "amount", value: dataProps?.amount },
        { label: lang["dueDate"] ? lang["dueDate"] : "dueDate", value: dateTimeFormatter("date", dataProps?.businessOrder?.param?.dueDate, "hh:mm dd.mm.yyyy") },
        { label: lang["salesPerson"] ? lang["salesPerson"] : "salesPerson", value: dataProps?.businessOrder?.param?.person?.label },
        { label: lang["responsiblePerson"] ? lang["responsiblePerson"] : "responsiblePerson", value: dataProps?.workOrder?.param?.person?.label },
        { label: lang["boPrio"] ? lang["boPrio"] : "boPrio", value: colorPrio(dataProps?.businessOrder?.param?.prio?.label, 18) },
        // { label: lang["orderNumber"] ? lang["orderNumber"] : "orderNumber", value: item?.param?.orderNumber },
      ]
      // console.log(clientUrl)
      return (
        <Card style={{ margin: 0, marginTop: 10 }}>
          <Card.Header style={{ borderRadius: 0 }}>
            <Tooltip placement="left" title={dataProps.selectedProduct && dataProps.selectedProduct.deliveryStatus.label}>
              <span style={{ float: "left" }}>
                {dataProps.selectedProduct && colorCircle(dataProps.selectedProduct.deliveryStatus.label)}
              </span>
            </Tooltip>
            <span>
              {undefObjHandler(dataProps, "product") && dataProps.selectedProduct.product}
            </span>
          </Card.Header>
          <Card.Body style={{ position: "relative" }}>
            <Row>
              <Col md={4}>
                <Image
                  width={50}
                  fallback={window.location.origin + "/media/users/" + layout.defaultImg}
                  src={clientUrl + "/media/users/" + dataProps?.image}
                  />
              </Col>
              <Col md={8}>
                <Infos dataProps={info} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )
    } else return <Spinner data={{ spin: true, noData: "", text: lang["RetrievingData"], sizeSpinner: 20, sizeText: 20 }} />
  }
}

export default InfoCard;

// {keys.map((el, i) => {
//   const _showKeys = showKeys.find((f) => f === el)
//   console.log([_showKeys, el])
//   return(
//     <div key={i}>
//       {_showKeys && singleRow({ left, right, leftTitle: lang[el] ? lang[el] : el, rightTitle: formatValue(dataProps[el]) })}
//     </div>
//   )
// })}

// {undefObjHandler(dataProps, "operationName") && dataProps.param.operation.operationName}
// {singleRow({ left, right, leftTitle: "PS", rightTitle: formatValue(startDate) })}
