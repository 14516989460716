import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip, Image } from 'antd';
import { Row, Col, Card } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"
import { singleRow, colorCircle } from "src/layouts/widgets/CustomLayout/index"
import { content } from "src/utils/functions/core"

const ProductInfo = (props) => {
  const context = useContext(MainContext);
  const [ info, setInfo ] = useState(props?.dataProps)
  const { lang } = context.state
  const dataTable = info.map((el, i) => ({ ...el, ...el.productCatalog }))
  const { layout, user, clientUrl } = content()
  const columns = [
    {
      title: "#",
      dataIndex: 'label',
      key: 'key',
      width: 30,
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: lang["product"] ? lang["product"] : "product",
      dataIndex: 'value',
      key: 'key',
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: lang["description"] ? lang["description"] : "description",
      dataIndex: 'description',
      key: 'key',
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: lang["image"] ? lang["image"] : "image",
      dataIndex: 'image',
      key: 'key',
      width: 30,
      render: (text, record) => {
        return (
          <Image
            width={40}
            fallback={clientUrl + "/media/users/" + layout.defaultImg}
            src={clientUrl + "/media/users/" + text}
            />
        )
      },
    }
  ]
  // <span>{el.label}</span>

  return (
    <Row>
      <Table
        style={{ padding: 0 }}
        className="table-striped-rows"
        columns={columns}
        dataSource={dataTable}
        // rowSelection={rowSelection}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
          pageSizeOptions: ["10", "20", "30"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
        }}
        />
    </Row>
  )
}

export default ProductInfo;
// <Row>
//   {info.map((el, i) => {
//     console.log(el)
//     return(
//       <p>
//       )
//     })}
//   </Row>
