import React, { useContext, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd';

const App = (props) => {
  const mounted = useRef(true);

  return (
     <div>Palletization</div>
  )
}
export default App;
