/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react'
import { MainContext } from 'src/Context';
import { withRouter } from "react-router-dom";
import { Button, Form, Input, Row, Tooltip } from 'antd';
import { SketchPicker } from 'react-color';
import { ItemCatalog } from "src/utils/functions/filterFunction.js"

class UserSettings extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      // initialValues: {
      //   backgroundColor: { r: 26, g: 14, b: 85, a: 1 }
      // }
    }
  }


  componentDidMount = async () => {
    // const { dataProps } = this.props
    const { authToken, userConfig } = this.context.state
    const { user } = userConfig
    // const { group, tool } = this.state
    const _user = await ItemCatalog("User", authToken, "i4c.*", user.userId)
    console.log(_user.param)
    this.setState({
      _user,
      initialValues: _user.param,
      // ..._user.param
    })
  }

  componentWillUnmount() {
    // clearInterval(this.intervalID);
  }

  confirmData = () => {

  }

  onFinish = async (values) => {
    this._isMounted = true
    // const { dataProps } = this.props
    const { queryPut, authToken, userConfig } = this.context.state
    const { user } = userConfig
    // const { group, tool } = this.state
    const _user = await ItemCatalog("User", authToken, "i4c.*", user.userId)
    if (_user) {
      // console.log(_user.param)
      const param = {
        ...values,
        ..._user.param,
      }
      // console.log(param)
      queryPut({
        url: "/updateCatalogItem",
        token: authToken,
        type: "User",
        param,
        id: user.userId,
      }).then(res => {
        if (this._isMounted && res) {
          const _user = ItemCatalog("User", authToken, "i4c.*", user.userId)
          // this.context.contextCall("spinnerCustom", { spin: true, text: lang["RetrievingData"], sizeSpinner: 20, sizeText: 20 })
          setTimeout(() => {
            console.log(_user)
            // this.props.callback({ type: "getData", value: "2", data: { ...values, _id: dataProps.props._id } })
            // this.context.contextCall("spinnerCustom", false)
          }, 2000);
        }
      })
    }
    // const meta = {
    //   user: {
    //     _id: user.userId,
    //     user: user.firstName + " " + user.lastName,
    //   },
    //   _createTimestamp: Date.now()
    // }

    // if (dataProps.type === "edit") {
    // } else if (dataProps.type === "new" && dataProps._state) {
    //   queryPut({
    //     url: "/addNewCatalogItem",
    //     token: authToken,
    //     type: dataProps._state.catalog,
    //     param,
    //     meta
    //   }).then(res => {
    //     if (this._isMounted && res) {
    //       this.context.contextCall("spinnerCustom", { spin: true, text: lang["RetrievingData"], sizeSpinner: 20, sizeText: 20 })
    //       this.props.callback({ type: "modal", value: false })
    //       setTimeout(() => {
    //         this.props.callback({ type: "form", value: true })
    //         this.context.contextCall("spinnerCustom", false)
    //       }, 2000);
    //     }
    //   })
    // }
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  handleChangeComplete = (color, event) => {
    const { lang, clipboard } = this.context.state
    this.setState({ Colorpicker: color.hex });
    clipboard(color.hex)
  };

  render() {
    const { lang, clipboard } = this.context.state
    const { callback, indexKey, edit, initialValues, backgroundButtonColor, Colorpicker, userIcon } = this.state
    const { history } = this.props
    // const { showModal } = this.state.dataProps

    const backgroundColors = [
      {
        label: "red",
        value: "red",
      }
    ]
    const highlightColors = [
      {
        label: "red",
        value: "red",
      }
    ]
    const sampleIcons = ["AiOutlineUser", "FiUser", "FaUserAlt", "FaUserTie"]

    return (
      <div style={{ position: "fixed", top: 110 }}>
        <Row style={{ width: "100%" }}>
          <span style={{ width: "50%" }}>
            header left buttons
            <Form
              key={indexKey}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              autoComplete="off"
              initialValues={{ ...initialValues, remember: true }}>


              <Form.Item
                label={lang["backgroundButtonColor"] ? lang["backgroundButtonColor"] : "backgroundButtonColor"}
                name="backgroundButtonColor"
                rules={[{ message: 'Please input your backgroundButtonColor!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label={lang["highlightButtontColor"] ? lang["highlightButtontColor"] : "highlightButtontColor"}
                name="highlightButtontColor"
                rules={[{ message: 'Please input your highlightButtontColor!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label={lang["textColor"] ? lang["textColor"] : "textColor"}
                name="textColor"
                rules={[{ message: 'Please input your textColor!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label={lang["userIcon"] ? lang["userIcon"] : "userIcon"}
                name="userIcon"
                rules={[{ message: 'Please input your userIcon!' }]}>
                <Input
                  value={userIcon}
                  addonAfter={(
                    <Form.Item name="suffix" noStyle>
                      <Tooltip placement="left" title={sampleIcons.map((el, i) => { return (<div key={i} onClick={() => clipboard(el)}>{el}</div>) })}>
                        <span>
                          icons
                        </span>
                      </Tooltip>
                    </Form.Item>
                  )}
                />
              </Form.Item>
              <Form.Item
                label={lang["userIconColor"] ? lang["userIconColor"] : "userIconColor"}
                name="userIconColor"
                rules={[{ message: 'Please input your userIconColor!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="Colorpicker">
                <SketchPicker value={Colorpicker} onChange={this.handleChangeComplete} />
              </Form.Item>
              {/*
                <Form.Item
                label={lang["colors"] ? lang["colors"] : "colors"}
                name="backgroundColor"
                rules={[{ required: true, message: 'Please input your group!' }]}>
                <Select
                showSearch
                options={backgroundColors}
                style={{ borderRadius: 0 }}
                onChange={(e, i) => this.setState({ backgroundColor: i })}
                filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              } />
            </Form.Item>
            <Form.Item
            label={lang["colors"] ? lang["colors"] : "colors"}
            name="highlightColor"
            rules={[{ required: true, message: 'Please input your group!' }]}>
            <Select
            showSearch
            options={highlightColors}
            style={{ borderRadius: 0 }}
            onChange={(e, i) => this.setState({ highlightColor: i })}
            filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          } />
        </Form.Item>
        */}
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button ghost type="primary" htmlType="submit"
                  onClick={() => history.push("/dashboard")}>
                  {lang["close"] ? lang["close"] : "close"}
                </Button>
                {'\xa0\xa0'}
                <Button ghost type="primary" htmlType="submit">
                  {lang["btn_save"] ? lang["btn_save"] : "btn_save"}
                </Button>
              </Form.Item>
            </Form>
          </span>

        </Row>
      </div>
    )
  }
}

export default withRouter(UserSettings)
