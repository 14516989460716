/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Button, Steps, Divider, message, Image } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus, renderIconV2, calculateSum, allinPut, msg } from "src/utils/functions/filterFunction"
// import { BasicMoInfo, OpInfoMo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { dateTimeFormatter } from "src/utils/functions/formater"
import { TitleInfo, ProductInfo, InfoColumns, EditorInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { colorPrio, colorCircle } from "src/layouts/widgets/CustomLayout/index"
import { LoadingOutlined } from '@ant-design/icons';
import { content } from "src/utils/functions/core"
import { prepareInfo, prepareColumns, paramDestruct } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"

const { TextArea } = Input;

const OperationManipulatePo = (props) => {
  // console.log(props.dataProps.item.param)
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { board, status } = props?.dataProps ?? []
  const [spinner, setSpinner] = useState(false)
  const [pauseStatus, setPauseStatus] = useState(false)
  // const [buttonFill, setButtonFill] = useState()
  const [items, setItems] = useState()
  const [getStatus, setGetStatus] = useState()
  const [colorStatus, setColorStatus] = useState()
  const [activeKey, setActiveKey] = useState()
  const [item, setItem] = useState(props?.dataProps?.item)
  // const [buttonLabel, setButtonLabel] = useState()
  const [quickNotes, setQuickNotes] = useState(props?.dataProps?.item?.param?.quickNotes)
  const [param, setParam] = useState(props?.dataProps?.item?.param)
  const [ product, setProduct ] = useState()
  const [ result, setResult ] = useState()
  const { lang, authToken, userConfig, secFormat } = context.state
  const { style } = context
  const { user } = userConfig

  // const getData = async (_item) => {
  //   const [Product, WorkOrder, ToolCatalog] = await Promise.allSettled([
  //     // allinOne("Product", { id: _item?.param?.productId }, authToken),
  //     // allinOne("WorkOrder", { id: item?.param?.workOrderId }, authToken),
  //     // allinOne("ToolCatalog", { id: item?.param?.operation?.tool?._id }, authToken),
  //     // allinOne("Product", { id: "040f2ebc-286b-41ba-b8f1-629fcc127044" }, authToken),
  //     // allinOne("WorkOrder", { id: "061ef303-33c8-496d-b371-cdf0451b29ec" }, authToken),
  //     // allinOne("ToolCatalog", { id: "1223ea1c-b37d-44e0-8aea-1a27c09c5613" }, authToken),
  //   ])
  //   if (Product && WorkOrder && ToolCatalog) {
  //     const { assets } = content()
  //     const all = {
  //       product: checkPromisStatus(Product),
  //       workorder: checkPromisStatus(WorkOrder),
  //       tool: checkPromisStatus(ToolCatalog),
  //       asset: assets.find((f) => f?._id === _item?.param?.assetId)
  //     }
  //     return all
  //   }
  // }

  useEffect(() => {
    // setData(board, item)
    // const fetchData = async () => {
    //   const d = await getData(item)
    //   if (d) {
    //     setResult(d)
    //   }
    // }
    // fetchData()
    return () => { mounted.current = false }
  }, [item])

const deliveryStatus = colorCircle(item?.param?.deliveryStatus?.label, false, item?.param?.deliveryStatus?.label)
const prio = colorPrio(item?.param?.prio?.label, 20, item?.param?.prio?.label)


// const cols = props?.children?.tabs?.[props?.tabName]
const cols = props?.dataProps?.children?.tabs?.["basicInfo"] // NOTE: "!!!" only one for now
const _param = props?.dataProps?.item?.param
// console.log(props?.dataProps.children.tabs)
// _param.prevOperations = result?.prevOperations
const columns = prepareColumns(cols, _param, lang)

  // const columns = [
  //   {
  //     key: 1,
  //     title: lang["info"] ? lang["info"] : "info",
  //     comp: 'Infos',
  //     style: { fontSize: 20 },
  //     info: [
  //       { label: lang["create"] ? lang["create"] : "create", value: item?.param?.person?.label },
  //       { label: lang["type"] ? lang["type"] : "type", value: "workorder" },
  //       { label: lang["deliveryStatus"] ? lang["deliveryStatus"] : "deliveryStatus", value: deliveryStatus },
  //       { label: lang["prio"] ? lang["prio"] : "prio", value: prio },
  //     ]
  //   },
  //   {
  //     key: 2,
  //     title: lang["info"] ? lang["info"] : "info",
  //     comp: 'Infos',
  //     style: { fontSize: 20 },
  //     info: [
  //       { label: lang["orderNumber"] ? lang["orderNumber"] : "orderNumber", value: item?.param?.orderNumber },
  //       { label: lang["description"] ? lang["description"] : "description", value: item?.param?.description },
  //       { label: lang["dueDate"] ? lang["dueDate"] : "dueDate", value: dateTimeFormatter("date", item?.param?.dueDate, "dd.mm.yyyy hh:mm") },
  //       // { label: lang["dueDate"] ? lang["dueDate"] : "dueDate", value: dateTimeFormatter("date", item?.param?.dueDate, "dd.mm.yyyy hh:mm") },
  //     ]
  //   },
  // ]
  const getDetailsPC = (e) => {
    return props?.dataProps?._ProductCatalogMinimal?.find((f) => f._id === e)
  }

// console.log(item)
const info = item?.param?.products.map((el, i) => ({ key: i + 1, label: i + 1, value: el?.productCatalog?.product, productCatalog: getDetailsPC(el.productCatalog._id) }))
  const _item = { name: item?.param?.name }
  // const _info = info
  // console.log(info)
  const styleTitleInfo = props?.dataProps?.children?.styles?.TitleInfo
  const styleInfoColumns = props?.dataProps?.children?.styles?.InfoColumns
  return (
    <div style={{ ...style.OperationManipulateMo, padding: 10 }}>
      <TitleInfo styles={styleTitleInfo} dataProps={_item} />
      <Divider />
      <div>
        <InfoColumns styles={styleInfoColumns} key={JSON.stringify({ ...columns })} dataProps={columns} />
        <Divider />
        {/*
          <InfoColumns dataProps={columns}/>
          <TitleInfo dataProps={{ name: lang["products"] ? lang["products"] : "products" }} />
          */}
      </div>
      <div>
        <ProductInfo dataProps={info}/>
      </div>
    </div>
  )
}

export default OperationManipulatePo;
// <Image
//   preview={false}
//   style={{ maxHeight: 200, maxWidth: 200 }}
//   fallback={"/media/users/default.png"}
//   src={"/media/users/" + info?.productCatalog?.image}
//   />
