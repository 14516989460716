import React from "react";
import { ToastContainer, toast } from 'react-toastify';
// import fetchData from './utils/functions/fetchData';
// import SessionExpired from "./utils/functions/SessionExpired";
import styleLight from './assets/Styling/styleLight';
import styleDark from './assets/Styling/styleDark';
// import userConfig from './utils/userConfig/userConfig.json';
import developVer from './utils/config/developVer.json';
// import * as icons from 'react-icons-ng/all'
import Socket from "./utils/Socket.io";
import { querySelect, queryPut, queryPost, queryDelete } from "./utils/functions/queryFetch";
import { time, date, dateTime, getTimestamp, fontSizeDynamic, months, destructUrl, clipboard, secFormat, dateTimeFormatter } from "./utils/functions/formater";

export const MainContext = React.createContext();
class MainProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      querySelect, queryPut, queryPost, queryDelete, secFormat, dateTimeFormatter,
      time, date, dateTime, getTimestamp, fontSizeDynamic, months, destructUrl, clipboard,
      userConfig: JSON.parse(localStorage.getItem("userConfig")),
      authToken: localStorage.getItem("authToken"),
      socket: JSON.parse(localStorage.getItem('socket')),
      // company: userConfig.map(el => el.company)[0].name,
      undefObjHandler: (object, _obj) => {
        if (typeof object === 'object') {
          if (JSON.stringify(object).indexOf(_obj) > -1) {
            return true
          } else {
            return false
          }
        }else {
          return false
        }
      },
      dateFormat: (x) => {
        var d = new Date(x);
        var day = String(d.getDate()).padStart(2, '0');
        var month = String(d.getMonth() + 1).padStart(2, '0');
        var year = d.getFullYear();
        return (day + '.' + month + '.' + year)
      },
      dateTimeFormat: (x) => {
        var d = new Date(x);
        var day = String(d.getDate()).padStart(2, '0');
        var month = String(d.getMonth() + 1).padStart(2, '0');
        var year = d.getFullYear();
        var s = d.getSeconds();
        var m = d.getMinutes();
        var h = d.getHours();
        return (day + '.' + month + '.' + year + ' - ' + h + ':' + m + ':' + s)
      },
      toTimestamp: (date) => {
        var timeStamp = Date.parse(date);
        return timeStamp;
      },
      // renderIcon: (icon, size, color, text) => {
      //   const Component = icons[icon]
      //   return (<> <Component style={{ fontSize: size, color: color }} /> {text} </>)
      // },
      developVer,
      lang: "",
      defaultRouteNoAuth: "/Login",
      homeRoute: "/dashboard",
      stateLogout: false,
      onLoad: false,
      style: styleLight,
      switchTheme: true,
      themeState: false,
      isFull: false,
      list: [],
      licenseIndex: true,
      sessionRoom: "kafka_sobica",
      showHeaderFooter: false,
      showDrawer: false,
      stateReload: false,
      dataContextSocket: [],
      userDataContextSocket: [],
      hftestingDemo: {},
      spinnerLoad: false,
      socketDisconect: false,
      spinnerCustom: false,
      tempTrigger: false,
      // subscribe: ["test", "hftesting", "hfwelding"],
      multiTrigger: ["triggerContext", "modalWo"],
      timeRange: {
        start: Date.parse(new Date(new Date().getTime() - (24 * 60 * 60 * 1000))),
        end: Date.parse(new Date())
      }
    }
    this.Socket = new Socket()
  }

  componentDidMount() {
    const { userConfig } = this.state
    // console.log(userConfig)
    if (userConfig) {
      const { terminalData } = userConfig
      this.startSocket(userConfig.auth_token)
      this.setState({
        userIdTemp: (userConfig.user.userId + "-" + localStorage.getItem('timeNow')).toString(),
        switchTheme: terminalData ? terminalData.switchTheme : true,
        // showHeaderFooter: terminalData && terminalData.showHeaderFooter
      })
      this.setUserSettings(userConfig)
      // this.fetchUserTimeRange(userConfig.user.userId)
    }
  }

  startSocket = (authToken) => {
    const endPoint = window.origin
    this.Socket.connect(endPoint, authToken)
    this.setState({ socketConn: this.Socket })
  }

  setUserSettings = (data) => {
    // const { range } = data?.user?.userConfig?.timeRange;
    const langPrefix = localStorage.getItem("langPrefix") ? localStorage.getItem("langPrefix") : data?.user?.userConfig?.language
    this.setState({
      // custom,
      // range,
      lang: data?.languages[langPrefix],
      logOut: false
    })
  }

  componentDidUpdate(_, prevState) {
    // const { switchTheme, userConfig, userState } = this.state
    // // if (userConfig && userState) {
    // //   this.setState({ userState: false })
    // // }
    // if (prevState.switchTheme !== switchTheme) {
    //   this.setState({
    //     style: switchTheme ? styleLight : styleDark
    //   })
    //   if (!switchTheme) {
    //     document.body.setAttribute('style', 'background: rgb(64, 68, 64);')
    //   } else {
    //     document.body.setAttribute('style', 'background: none;')
    //   }
    // }

  }

  // fetchUserTimeRange = (id) => {
  //   fetch("/usersQueryRange/" + id)
  //     .then((res) => res.json())
  //     .then(rt => {
  //       const myDate = new Date();
  //       const newDate = new Date(myDate);
  //       newDate.setHours(newDate.getHours() - rt.el[0].queryRange.time);
  //       const setTime = [newDate.getTime(), new Date().getTime()];
  //       const custom = rt.el[0].queryRange.custom
  //       this.setState({
  //         onLoad: true,
  //         rangeTime: custom ? rt.el[0].queryRange.range : setTime,
  //         timeDifference: rt.el[0].queryRange.time,
  //         custom: rt.el[0].queryRange.custom,
  //       })
  //     })
  //     .catch((error) => console.log(error))
  // }

  contextCall = (state, value) => { this.setState({ [state]: value }) }
  toastify = (i, x, y) => { toast[i](x, y) }
  setLang = (set) => {
    this.setState({
      lang: this.state.userConfig.languages[set]
    })
    localStorage.setItem("langPrefix", set)
  }

  logOut = (stateLogout) => {
    this.setState({ socketDisconect: true })
  }

  fetchData = (url, method, body) => {

    if (method === "GET") {
      return fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        // .then(el => console.log(el))
        .catch(err => console.log(err))
    } else if (method === "POST") {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
        .then(res => res.json())
        .catch(err => {
          console.error("HTTP error while fetching", err)
          return err
        })
    } else if (method === "PUT") {
      return fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
        .then(res => res.json())
        .catch(err => {
          console.error("HTTP error while fetching", err)
          return err
        })
    } else if (method === "DELETE") {
      return fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8' // Indicates the content
        },
        body: JSON.stringify(body)
      })
        .then(res => res.json())
        .catch(err => {
          console.error("HTTP error while fetching", err)
          return err
        })
    } else {
      console.log("Invalid method parameter!")
      return false
    }
  }

  render() {
    const { children } = this.props;
    const { state, contextCall, logOut, setUserSettings, setLang, toastify, fetchData } = this;
    const { style } = this.state;
    return (
      <MainContext.Provider value={{ style, state, contextCall, fetchData, logOut, setUserSettings, setLang, toastify }}>
        {children}
        <ToastContainer />
      </MainContext.Provider>
    )
  }
}

export { MainProvider };
