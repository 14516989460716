/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Checkbox, Button } from 'antd';
import { MainContext } from 'src/Context';
// import { sortNumAlpObj } from "src/utils/functions/filterFunction"
// import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { allinOne, allinPut, msg } from "src/utils/functions/filterFunction"
// import Spinner from "src/layouts/components/spinner"
// import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';

const { TextArea } = Input
const App = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const loadingEmpty = <span className="loading"><span>.</span><span>.</span><span>.</span></span>
  const [children, setChildren] = useState(loadingEmpty)
  // const [infoCard, setInfoCard] = useState(loadingEmpty)
  const [checked, setChecked] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [description, setDescription] = useState("")
  const { lang } = context.state
  const { style } = context
  const id = props?.item?._id
  const getData = async () => {
    if (mounted.current) {
      const result = await allinOne("getDocument", { type: "Operation", id })
      if (result.success) {
        return result?.r
      }
    }
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      const iAgree = d?.param.iAgree
      const description = d?.param.description
      setChecked(iAgree)
      setDescription(description)

    }
  }

  const buttonPropsSave = {
    type: "primary",
    danger: false,
    style: {
      float: "right",
      // marginRight: 10
    },
    onClick: (e) => setConfirm(true)
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    if (checked) {
      console.log(checked)
    }
  }, [checked])

  useEffect(() => {
    if (confirm) {
      setConfirm(false)
      const u = async () => {
        const p = {
          id,
          iAgree: checked,
          description
        }
        // console.log(p)
        const result = await allinPut("updateAgreeOp", p)
        if (result.success) {
          msg("success", (lang?.["saved"] ?? "saved"), 2)
        }
      }
      u()
    }
  }, [confirm])
  // iAgree
  return (
    <div>
      <div className="divSummary">
         <div>{lang?.["notes"] ?? "notes"}</div>
        <TextArea value={description} onChange={(e) => setDescription(e.target.value)} />
      </div>
      <div className="divSummary">
        <Checkbox
          title={lang?.["iAgreeText"] ?? "iAgreeText"}
          checked={checked} onChange={(e) => setChecked(e.target.checked)}>
          {lang?.["iAgreeText"] ?? "iAgreeText"}
        </Checkbox>
      </div>
      <div className="divSummary">
        <Button {...buttonPropsSave}>
          {lang?.["btn_save"] ?? "btn_save"}
        </Button>
      </div>
    </div>
  )
}

export default App;
