/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
import { Image, Input, Badge, Modal } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { content } from "src/utils/functions/core"
import { allinOne, allinPut } from "src/utils/functions/filterFunction"
import { UserOutlined } from '@ant-design/icons';
import { socket } from 'src/utils/socket';
import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';
import { updateUserAssetMgmt, updateSpecSelectedOperations } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"
// import Spinner from "src/layouts/components/spinner"
import Spinning from "src/layouts/widgets/Core/Spinning"

class Assets extends React.Component {
  static contextType = MainContext;
  _isMounted = true;
  constructor(props) {
    super(props)
    this.state = {
      ...this.props,
      itemsPage: 6,
      mount: false,
      count: 1,
      assetUsers: {},
      windowHeight: window.innerHeight,
      isLoading: false
    }
    this.handleResize = this.handleResize.bind(this)
    this.confirmOperation = this.confirmOperation.bind(this)
  }

  componentDidMount() {
    this.getData()
    window.addEventListener('resize', this.handleResize);
    socket.connect()
    socket.on('usersOn', (el) => {
      if (this._isMounted) {
        this.setState({ assetUsers: el }, () => {
          this.getData()
          this.props.setNewUsersOnAsset(el)
        })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false
    // socket.off('usersOn')
  }

  handleResize() {
    this.setState({
      windowHeight: window.innerHeight
    })
  }

  getData = async () => {
    const { querySelect, authToken, userConfig, assetId } = this.context.state
    const { user } = userConfig
    const { usersAsset } = this.state.dataProps
    const { count } = this.state
    const { layout } = content()
    if (this._isMounted) {
      const r = await allinOne("getSpecDocument", {})
      querySelect({
        url: "/getAssetsByUser",
        token: authToken,
        id: user.userId,
        workforce: layout?.workforce,
      }).then(res => {

        // console.log(res)
        if (res && this._isMounted) {
          const assets = res.assetsTree.map((el, i) => {
            // console.log(el)
            const currentAssetId = assetId === el?._id
            const users = currentAssetId ? r?.assets?.[el?._id]?.length : 0
            // const users = r?.assets?.[el?._id]?.length ?? 0
            const _users = r?.assets?.[el?._id] ?? 0
            // console.log(users)
            if (el._id === (usersAsset && usersAsset._id)) {
              return ({
                ...usersAsset,
                value: usersAsset._id,
                label: usersAsset.asset_name
              })
            } else {
              return ({
                ...el,
                value: el._id,
                label: el.asset_name,
                users,
                _users
              })
            }
          })
          this.setState({ count: count + 1, assets, locations: res.locations })
        }
      })
    }
  }

  // getStats = () => {
  //   const { socketConn } = this.context.state
  //   const { assets } = this.state
  //   socketConn.emit("getUserStatusWork", {
  //     assets
  //   })
  // }

  mergeAssets = (arr1, arr2) => {
    let r = [];
    arr1.forEach(el => {
      let arr = arr2.filter(f => f.asset._id === el._id);
      if (arr.length > 0) {
        const _arr = arr.map((m) => ({ ...el, ...m }))
        r = [...r, ..._arr];
      } else {
        r.push(el)
      }
    })
    return r
  }

  confirmOperation = () => {
    const { ids } = this.state
    // console.log(ids)
  }


  onUserResponse = async (e, el, i) => {
    const { assets } = this.state
    const { layout, user } = content()
    // const { hightLight, lang } = this.context.state
    const setUserToAsset = this?.props?.setUserToAsset
    const callback = this?.props?.callback
    // const now = Date.now()
    // console.log(this.state)
    //  console.log(e)
    // this.setState({ isLoading: true })
    if (!e) {
      Modal.destroyAll();
    } else if (e && e?.length === 0) {
      // this.setState({ hightLight: i }, () => {
      //   // this.context.contextCall("hightLight", i)
      // })
      const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
      const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
      const p = { ...el, sequence, redirect, assets }
      callback(p)
      setUserToAsset({
        assetId: el?._id,
        labelAsset: el?.label,
        userId: user?.userId,
        labelUser: user?.firstName + " " + user?.lastName,
      }, "updateUserAssetMgmt")
      await allinPut("addUserOnAsset", {
        assetId: el?._id,
        // operationId,
        userId: user?.userId,
        userLabel: user?.firstName + " " + user?.lastName,
        // startDate: Date.now(),
        endDate: null
      })
      Modal.destroyAll();
    } else if (e && e?.length > 0) {
      // const { layout, clientUrl, user } = content()
      // {
      //   "assetId": "1d615d0c-cbf6-4dfa-856c-e9fa6c086ef5",
      //   "labelAsset": "Stanca A0",
      //   "labelUser": "Nejc Senčar",
      //   "erp_userId": "00062",
      //   "userId": "10fc9049-6af4-4126-bf79-6321c9cc4175"
      // }
      // console.log(user)
      // const result = await allinPut("removeUserAssetMgmt", { ...e })
      // assetIds => ids => from operations
      const f = async (e) => { }
      // console.log({
      //   e,
      //   user
      // })
      this.setState({ isLoading: true })
      // console.log([
      // ])
      await allinPut("updateOperationsWorkers001", {
        assetIds: e,
        userId: user?.userId,
        assetId: el?._id,
        labelAsset: el?.label,
        // userId: user?.userId,
        labelUser: user?.firstName + " " + user?.lastName,
      })
      const _e = {
        assetId: el?._id,
        labelAsset: el?.label,
        userId: user?.userId,
        labelUser: user?.firstName + " " + user?.lastName,
        erp_userId: user.erp_userId,
        operationIds:e,
        operationId: e?.length === 1 ? e[0] :""
      }
      // console.log(_e)
      await updateUserAssetMgmt(_e, f, "updateUserAssetMgmt")
      //await updateSpecOperation(_e)
      await updateSpecSelectedOperations(_e)
      // this.setState({ isLoading: false })
      const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
      const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
      const p = { ...el, sequence, redirect, assets }
      callback(p)
      setUserToAsset({
        assetId: el?._id,
        labelAsset: el?.label,
        userId: user?.userId,
        labelUser: user?.firstName + " " + user?.lastName,
      }, "updateUserAssetMgmt")
      await allinPut("addUserOnAsset", {
        assetId: el?._id,
        // operationId,
        userId: user?.userId,
        userLabel: user?.firstName + " " + user?.lastName,
        // startDate: Date.now(),
        endDate: null
      })
      Modal.destroyAll();
      this.setState({ isLoading: false })

      // this.setState({ hightLight: i }, () => {
      //   // this.context.contextCall("hightLight", i)
      //   const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
      //   const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
      //   const p = { ...el, sequence, redirect, assets }
      //   callback(p)
      //   setUserToAsset({
      //     assetId: el?._id,
      //     labelAsset: el?.label,
      //     userId: user?.userId,
      //     labelUser: user?.firstName + " " + user?.lastName,
      //   }, "updateUserAssetMgmt")
      // })
      // console.log({
      //   userId: user?.userId,
      //   operations: e
      // })
    }
    // this.setState({
    //   ids: e
    // })
    // if (e === "none") {
    //   // this.setState({ hightLight: i }, () => {
    //   //   // this.context.contextCall("hightLight", i)
    //   //   const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
    //   //   const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
    //   //   const p = { ...el, sequence, redirect, assets }
    //   //   callback(p)
    //   //   setUserToAsset({
    //   //     assetId: el?._id,
    //   //     labelAsset: el?.label,
    //   //     userId: user?.userId,
    //   //     labelUser: user?.firstName + " " + user?.lastName,
    //   //   }, "updateUserAssetMgmt")
    //   // })
    // }
  }

  showConfirm = async (el, i) => {
    const { assets, callback } = this.state
    const { layout, user } = content()
    const { lang } = this.context.state
    const setUserToAsset = this?.props?.setUserToAsset
    const { confirm } = Modal;
    this.setState({ isLoading: true })
    const result = await allinOne("prepareSpecOperationsV3", { assetId: el?._id })
    if (result.success) {
      if (result?.result?.length === 0) {
        // console.log("none")
        this.setState({ hightLight: i }, async () => {
          // this.context.contextCall("hightLight", i)
          const result = await allinPut("removeAndStopTimeOnAsset", { userId: user?.userId })
          this.setState({ isLoading: false })
          const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
          const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
          const p = { ...el, sequence, redirect, assets }
          callback(p)
          // setUserToAsset({
          //   assetId: el?._id,
          //   labelAsset: el?.label,
          //   userId: user?.userId,
          //   eventStartTimestamp: el?.eventStartTimestamp ? el?.eventStartTimestamp : null,
          //   labelUser: user?.firstName + " " + user?.lastName,
          // }, "updateUserAssetMgmt")
          await allinPut("addUserOnAsset", {
            assetId: el?._id,
            // operationId,
            userId: user?.userId,
            userLabel: user?.firstName + " " + user?.lastName,
            // startDate: Date.now(),
            endDate: null
          })
        })
      } else {

        const asset = result?.assets?.assets[el?._id]
        const _user = asset.find((f) => f?.userId === user?.userId)
        // console.log(_user)
        if (_user) {
          this.setState({ hightLight: i, isLoading: false }, () => {
            // this.context.contextCall("hightLight", i)
            const sequence = layout?.terminalRedirect?.sequence ? layout?.terminalRedirect?.sequence : null
            const redirect = layout?.terminalRedirect?.redirect ? layout?.terminalRedirect?.redirect : null
            const p = { ...el, sequence, redirect, assets }
            callback(p)
            // setUserToAsset({
            //   assetId: el?._id,
            //   labelAsset: el?.label,
            //   eventStartTimestamp: el?.eventStartTimestamp ? el?.eventStartTimestamp : null,
            //   userId: user?.userId,
            //   labelUser: user?.firstName + " " + user?.lastName,
            // }, "updateUserAssetMgmt")
          })
        } else {

          const _result = result?.result.map((el, i) => ({
            ...el,
            ...el?.param,
            operationName: el?.param?.operationName ? el?.param?.operationName : el?.param?.operation?.operationName,
            key: i + 1
          }))
          const selectedDefault = _result.map((el) => (el?.key))
          const setIdsDefault = _result.map((el) => (el?._id))
          const c = await import("./UserResponseAsset")
          const n = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/NoIcon")
          const Component = c?.default
          const NoIcon = n?.default
          const _style = {
            ...buttonStyle,
            // height: 30,
            // width: "auto",
            float: "right",
            marginLeft: 20,
            position: "relative",
            minWidth: 100
            // left: "50%",
            // transform: "translate(-50%)"
          }
          // console.log({
          //   selectedDefault,
          //   setIdsDefault
          // })
          this.setState({ isLoading: false })
          confirm({
            icon: <NoIcon />,
            style: {
              // display: "flex",
              // minWidth: "fit-content"
            },
            width: "60%",
            // width: 1000,
            // content: "fgdfrghdfh",
            content: <Component
              title={lang?.["operationOnPlaPro"] ?? "operationOnPlaPro"}
              text={lang?.["operationOnPlaProManual"] ?? "operationOnPlaProManual"}
              onClick={(e) => this.onUserResponse(e, el, i)}
              callbackData={this.callbackData}
              lang={lang}
              selectedDefault={selectedDefault}
              setIdsDefault={setIdsDefault}
              operations={_result}
              style={_style}
            />,
            // cancelText: lang?.["close"] ?? "close",
            // cancelButtonProps: { type: "primary", danger: true, ghost: false, style: _style },
            cancelButtonProps: { style: { display: 'none', marginTop: 0 } },
            // okButtonText: lang?.["confirm"] ?? "confirm",
            // okButtonProps: { type: "primary", danger: false, ghost: false, style: _style },
            okButtonProps: { style: { display: 'none', marginTop: 0 } },
            // content: <Button onClick={destroyAll}>Click to destroy all</Button>,
            // onOk() {
            //   console.log('Ok');
            // },
            // onCancel() {
            //   console.log('Cancel');
            // }
          })

        }
      }
    }

  }

  render() {
    const { assets, inputSearch, locations, windowHeight, isLoading } = this.state
    const { hightLight, lang } = this.context.state
    const { clientUrl, user } = content()
    const s = { width: "30%", borderRadius: 0, marginBottom: 10, height: 50, fontSize: 20 }
    // console.log(isLoading)
    if (assets && assets.length > 0) {
      // console.log(user)
      // const setUserToAsset = this?.props?.setUserToAsset
      localStorage.setItem("assets", JSON.stringify(assets))
      const c = { ...content(), assets }
      localStorage.setItem("body", JSON.stringify(c))
      const _assets = inputSearch ? assets.filter((el) => el.asset_name.toLowerCase().includes(inputSearch.toLowerCase())) : assets
      return (
        <div>
          {assets.length > 5 &&
            <center>
              <Input value={inputSearch}
                style={s}
                placeholder={lang?.["searchAsset"] ?? "searchAsset"}
                onChange={(e) => this.setState({ inputSearch: e.target.value })}
              />
            </center>}
          <Row style={{ overflowY: "scroll", height: (windowHeight - 220) }}>
            {_assets.map((el, i) => {
              const location = locations.find((f) => el.locationId === f.locationId)
              const u = el?.users
              const iam = Array.isArray(el?._users) ? el?._users?.find((f) => f?.userId === user?.userId) : false
              // console.log({user, el, iam})
              const color = iam ? "#52c41a" : ""
              return (
                <Col
                  key={i}
                  md={3}
                  style={{
                    maxWidth: 350,
                    minWidth: 250,
                    margin: 10,
                    padding: 0,
                  }}
                  onClick={() => this.showConfirm(el, i)}>
                  <div style={{ border: "2px solid #E5E6E7", height: 325, borderRadius: "10px 10px 10px 10px", }}>
                    <div
                      className="card-header"
                      style={{
                        padding: 0,
                        maxHeight: 100,
                        border: 0,
                        borderRadius: "8px 8px 0px 0px",
                        // backgroundColor: "#e5e6e7",
                        background: (hightLight === i) ? "rgba(25, 0, 124, 0.20)" : "#e5e6e7"
                      }}>
                      <div className="row" style={{ width: "100%" }}>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            padding: 0
                          }}>
                          <div
                            style={{
                              cursor: "pointer",
                              fontSize: 22,
                              padding: "5px 5px 0px 5px"
                            }}>
                            {el.label}
                            <b style={{ float: "right", marginRight: 10 }}>
                              {u ? <Badge count={u} color={color}><UserOutlined style={{ fontSize: 20 }} /></Badge> : ""}
                            </b>
                          </div>
                          <div
                            style={{
                              cursor: "pointer",
                              fontSize: 18,
                              padding: "5px 5px 0px 5px"
                            }}>
                            {location.locationName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        textAlign: "center"
                      }}>
                      <Image
                        preview={false}
                        style={{
                          maxHeight: 220,
                          width: "100%",
                          cursor: "pointer",
                          padding: 10
                        }}
                        alt={""}
                        fallback={"/media/users/defaultMachine.jpg"}
                        src={clientUrl + "/media/users/" + el?.param?.asset_image}
                        />
                      {/*
                      <div>icon</div>
                      */}
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
          {isLoading && <Spinning />}
        </div>
      )
    } else return <div>no assets</div>
  }
}

export default withRouter(Assets);
