import React, { useContext, useState, useEffect, useRef } from 'react'
// import { Form, Input, InputNumber, Table, Button, Collapse, Select, Tooltip } from 'antd'
import { Card, Col, Row } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DrawerComp, InfoCardMo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { MainContext } from 'src/Context';
import { allinPut, checkPromisStatus } from "src/utils/functions/filterFunction"
import { spinning } from "src/layouts/widgets/CustomLayout/index"
// import { allinOne, checkPromisStatus } from "src/utils/functions/filterFunction"

const MoBoardView = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { undefObjHandler, secFormat, userConfig } = context.state
  const { user } = userConfig
  const [board, setBoard] = useState([])
  const [items, setItems] = useState()
  const [_count, _setCount] = useState(0)
  const [prop, setProp] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const board = props.dataProps.board
    setBoard(board)
    let count = 0
    board.forEach((el, i) => {
      // console.log(el.items.length)
      count = (count < el.items.length) ? el.items.length : count
      _setCount(count)
      // console.log(count)
    });
    return () => { mounted.current = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board])

  // NOTE temporary solution
  const checkKeyValue = (e) => {
    if (e?.mos_statusHistory) {
      return e?.mos_statusHistory
    } else {
      return e?.mos_StatusHistory
    }
  }

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const getStatusHistory = (r, el, status) => {
    const _user = {
      _id: user.userId,
      user: user.firstName + " " + user.lastName
    }
    if (r.destination.index === r.source.index && r.destination.droppableId === r.source.droppableId) {
      console.log("same position")
    } else {
      let _statusHistory = []
      if (checkKeyValue(el?.parameters) && checkKeyValue(el?.parameters).length > 0) {
        // if (el.parameters.mos_statusHistory && el.parameters.mos_statusHistory.length > 0) {
        _statusHistory.push({
          startDate: Date.now(),
          _id: status.id,
          name: status.name,
          user: _user,
          statusType: status.kpi.value,
          duration: 0
        })
        checkKeyValue(el?.parameters).forEach((el, i) => {
          // el.parameters.mos_statusHistory.forEach((el, i) => {
          _statusHistory.push({
            ...el,
            endDate: (i === 0) ? el.startDate : el.endDate,
            duration: (i === 0) ? secFormat(Date.now() - el.startDate) : el.duration
          })
        })
      } else {
        _statusHistory.push({
          startDate: Date.now(),
          _id: status.id,
          name: status.name,
          user: _user,
          statusType: status.kpi.value,
          duration: 0
        })
      }
      return _statusHistory
    }
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    // console.log({source, destination, droppableSource, droppableDestination})
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const onDragEnd = (result) => {
    // console.log(result)
    const { source, destination } = result;

    if (!destination) { return }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    if (sInd === dInd) {
      const items = reorder(board[sInd].items, source.index, destination.index);
      const newBoard = [...board];
      const reorder1 = items.map((el, i) => {
        return ({ ...el, parameters: { ...el.parameters } })
      })
      newBoard[sInd].items = reorder1;
      if (mounted) { setBoard(newBoard) }
      // const { queryPost, authToken } = this.context.state
      setDrag(reorder1)
    } else {
      const _result = move(board[sInd].items, board[dInd].items, source, destination);
      let newBoard = [...board];
      // eslint-disable-next-line
      if (newBoard[sInd].allowBackStep === true || sInd <= dInd && _result) {
        newBoard[sInd].items = _result[sInd].map((el, i) => {
          return ({
            ...el,
            parameters: {
              ...el.parameters,
              mos_taskStatus: Number(board[sInd].id)
            },
          })
        });
        newBoard[dInd].items = _result[dInd].map((el, i) => {
          const now = Date.now()
          const last = dInd + 1
          const findId = result?.draggableId === el?._id
          return ({
            ...el,
            parameters: {
              ...el.parameters,
              mos_taskStatus: Number(board[dInd].id),
              mos_statusHistory: (el?._id === result?.draggableId) ? getStatusHistory(result, el, board[dInd]) : checkKeyValue(el?.parameters),
              // mos_statusHistory: (el._id === result.draggableId) ? getStatusHistory(result, el, board[dInd]) : el.parameters.mos_statusHistory,
              endDate: (last === board?.length && findId) ? now : false,
              duration: (last === board?.length && findId) ? (now - el?.param?.startDate) : false,
            },
          })
        });
        if (mounted) { setBoard(newBoard) }
        let _items = []
        newBoard.forEach((el, i) => {
          if (el?.items?.length > 0) {
            el?.items.forEach((item, j) => {
              _items.push(item)
            })
          }
        })
        if (mounted) {
          setItems(_items)
          setDrag(_items)
        }
      }
    }
  }

  const setDrag = async (items) => {
    const { queryPost, authToken } = context.state
    const [UpdateItemsBoard] = await Promise.allSettled([
      allinPut("UpdateItemsBoard", { body: items }, authToken),
    ])
    if (UpdateItemsBoard) {
      console.log(checkPromisStatus(UpdateItemsBoard))
      callback({ type: "MoCalendarView", value: Date.now() })
    }
  }

  const callback = () => { }

  const getListStyle = (isDraggingOver, count) => {
    const wf = props?.dataProps?._WorkflowCatalogPB
    const c = wf ? wf.param.maxCount : false
    return ({
      background: isDraggingOver ? "lightblue" : "",
      width: "auto",
      height: (windowHeight - 220),//(element?.style?.height),
      // height: (200 * _count) + 10,
      overflowY: "auto"
    })
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    marginBottom: 5,
    background: isDragging ? "lightgreen" : "white",
    // styles we need to apply on draggables
    ...draggableStyle
  });
  const filterCards = (item, index, el) => {
    // console.log(props)
    return (
      <Draggable
        key={item._id}
        draggableId={item._id}
        index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}>
            <div key={index} style={{ borderRadius: 0 }} onClick={() => (item.param && item.param.completed) ? null : openOp(item, el)}>
              <InfoCardMo callback={callback} dataProps={{ ...item, ...props.dataProps }} />
            </div>
          </div>
        )}
      </Draggable>
    )
  }

  const closeDrawer = (e) => {
    console.log(e)
  }

  const openOp = (item, el) => {
    // console.log({item, el})
    // console.log(props)
    setProp({
      placement: "bottom",
      open: true,
      headerStyle: { display: "none" },
      height: "85%",
      destroyOnClose: true,
      comp: "OperationManipulateMo",
      onClose: () => {
        setProp({
          placement: "bottom",
          open: false,
          headerStyle: { display: "none" },
          height: "85%",
          destroyOnClose: true,
        })
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
          props.callback("telkčlkj")
        }, 500)
      },
      item,
      status: el,
      board: props.dataProps.board,
      _WorkflowCatalogPB: props.dataProps._WorkflowCatalogPB
    })
    // () => props.callback("telkčlkj")
    // this.setState({ showModal: true, drawerComp: "OperationManipulateMo", item, status: el })
  }

  return (
    <Row>
      {isLoading && spinning(40)}
      <DragDropContext onDragEnd={onDragEnd}>
        {(board?.length > 0) && board.map((el, ind) => {
          const wf = props?.dataProps?._WorkflowCatalogPB
          const count = wf ? wf.param.maxCount : false
          const lengthInd = board.length - 1
          return (
            <Col key={ind} style={{ color: "", padding: 0, borderRight: ind < lengthInd && "1px solid #e7e7e7", maxWidth: "25%" }}>
              <Card style={{ border: 0, borderRadius: 0 }}>
                <center>
                  <Card.Header style={{ border: 0, borderRadius: 0, background: undefObjHandler(el, "color") ? el.kpi.color : "", color: "rgb(255, 255, 255)" }}>
                    {el ? el.name : "props missing !!!"}
                    <span style={{ float: "right" }}>
                      {(count && count > 0) ? (el.items ? (((count > el.items.length) ? el.items.length : count) + "/" + el.items.length) : "") : el.items ? el.items.length : ""}
                    </span>
                  </Card.Header>
                </center>
              </Card>
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver, el.items.length)}
                    {...provided.droppableProps}>
                    {el.items.map((item, index) => {
                      if (index < count) {
                        return filterCards(item, index, el)
                      } else if (count === 0 || !count) {
                        return filterCards(item, index, el)
                      }
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Col>
          )
        })}
      </DragDropContext>
      <DrawerComp dataProps={prop} />
    </Row>
  )
}

export default MoBoardView;
