import React from 'react'
import { MainContext } from 'src/Context';
import { Row, Col, Card } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

class MultiColPOB extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { ...this.props }
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const { dataProps } = this.state
    this.getData()
  }

  componentWillUnmount() { }

  getData = (props) => {
    const { querySelect, authToken } = this.context.state
    const { dataProps } = this.state
    if (dataProps && dataProps.children && dataProps.children.wofId) {
      // console.log(dataProps)
      const OPWorkflow = querySelect({
        url: "/select_item_catalog",
        token: authToken,
        type: "WorkflowCatalog",
        keys: "i4c.*",
        id: dataProps.children.wofId
        // extraQuery: 'AND _id = ' + '"' + dataProps.children.wofId + '"'
      }).then(res => { return { OPWorkflow: res } });

      const WorkOrder = querySelect({
        url: "/select_catalog",
        token: authToken,
        type: "WorkOrder",
        keys: "i4c.*"
      }).then(res => {
        if (res) { return { WorkOrder: res } }
      });

      // const Operation = querySelect({
      //   url: "/select_catalog",
      //   token: authToken,
      //   type: "Operation",
      //   keys: "i4c.*",
      //   extraQuery: 'ORDER BY param.status._i ASC '
      // }).then(res => {
      //   if (res) { return { Operation: res } }
      // });

      Promise.all([OPWorkflow, WorkOrder]).then((res) => {
        if (res.includes(undefined)) {
          console.log("undefined")
        } else {
          if (res) {
            // eslint-disable-next-line
            res.map((el, i) => {
              const key = Object.keys(el)[0]
              // console.log(key)
              if (key) {
                this.setState({
                  [key]: el[key],
                }, () => this.setData())
              }
            })
          }
        }
      })
    } else {
      this.setState({ board: "Missing" })
    }
    // if (dataProps && dataProps.dataProps && dataProps.dataProps.props) {
    //   // const assetId = dataProps.dataProps.props._id
    //
    // } else {
    //   console.log("missing worklow id")
    // }
  }

  setData = () => {
    const { OPWorkflow, WorkOrder } = this.state
    const { undefObjHandler } = this.context.state
    if (OPWorkflow && WorkOrder) {
      console.log(WorkOrder)
      // const _Operation = Operation.filter((f, i) => f.param.assetId === assetId)
      // console.log(_Operation)
      let _board = []
      undefObjHandler(OPWorkflow, "steps") && OPWorkflow.param.steps.forEach((el, i) => {
        _board.push(el)
        // const findStatusOp = _Operation.filter((f, j) => (f.param.status && f.param.status._id) === el.id)
        // if (findStatusOp) { _board.push({ ...el, items: findStatusOp }) } else { _board.push(el) }
      });
      // console.log(_board)
      this.setState({
        _board,
        // indexKey: Date.now(),
        // assetId
      })
    } else {
      console.log("missing worklow")
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  move = (source, destination, droppableSource, droppableDestination) => {
    // console.log({source, destination, droppableSource, droppableDestination})
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  onDragEnd = (result) => {
    const { source, destination } = result;
    const { _board } = this.state
    if (!destination) { return }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    // console.log(operation)
    if (sInd === dInd) {
      const items = this.reorder(_board[sInd].items, source.index, destination.index);
      const newBoard = [..._board];
      const reorder = items.map((el, i) => {
        return ({ ...el, param: { ...el.param, status: { ...el.param.status, _i: i } }, })
      })
      newBoard[sInd].items = reorder;
      if (this._isMounted) { this.setState({ _board: newBoard }) }
      // const { queryPost, authToken } = this.context.state
      this.setDrag(reorder)
    } else {
      const result = this.move(_board[sInd].items, _board[dInd].items, source, destination);
      let newBoard = [..._board];
      if (newBoard[sInd].allowBackStep === true || sInd <= dInd) {
        newBoard[sInd].items = result[sInd].map((el, i) => { return ({ ...el, param: { ...el.param, status: { ...el.param.status, _id: _board[sInd].id } } }) });
        newBoard[dInd].items = result[dInd].map((el, i) => { return ({ ...el, param: { ...el.param, status: { ...el.param.status, _id: _board[dInd].id } } }) });
        if (this._isMounted) { this.setState({ _board: newBoard }) }
        // const { queryPost, authToken } = this.context.state
        let _items = []
        newBoard.forEach((el, i) => {
          if (el.items.length > 0) {
            el.items.forEach((item, j) => {
              _items.push(item)
            })
          }
        })
        this.setDrag(_items)
      }
    }
  }

  render() {
    const { lang, undefObjHandler } = this.context.state
    const { _board } = this.state
    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      // userSelect: "none",
      // padding: 16,
      marginBottom: 5,
      // width: 200,
      // margin: `0 0 ${8}px 0`,
      // borderStyle: "solid",
      // borderWidth: "1px",
      // change background colour if dragging
      background: isDragging ? "lightgreen" : "white",
      // styles we need to apply on draggables
      ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "lightblue" : "",
      // padding: 8,
      // marginRight: 8,
      width: "auto",
      // minHeight: 690
    });
    // console.log(_board)
    if (_board && _board.length > 0) {
      return (
        <Row>
          <DragDropContext onDragEnd={this.onDragEnd}>
            {_board.map((el, ind) => {
              // console.log(el)
              const lengthInd = _board.length - 1
              return (
                <Col key={ind} style={{ color: "", padding: 0, borderRight: ind < lengthInd && "1px solid #e7e7e7" }}>
                  <Card style={{ border: 0, borderRadius: 0 }}>
                    <center>
                      <Card.Header style={{ border: 0, borderRadius: 0, background: undefObjHandler(el, "color") ? el.kpi.color : "", color: "rgb(255, 255, 255)" }}>
                        {el ? el.name : "props missing !!!"}
                        <span style={{ float: "right" }}>
                          {el.items ? el.items.length : ""}
                        </span>
                      </Card.Header>
                    </center>
                  </Card>
                </Col>
              )
            })}
          </DragDropContext>
        </Row>
      )
    } else return <div></div>
  }
}

export default MultiColPOB;
// <Droppable key={ind} droppableId={`${ind}`}>
//   {(provided, snapshot) => (
//     <div
//       ref={provided.innerRef}
//       style={getListStyle(snapshot.isDraggingOver, el.items.length)}
//       {...provided.droppableProps}>
//       {el.items.map((item, index) => {
//         // console.log(item)
//         return (
//           <Draggable
//             key={item._id}
//             draggableId={item._id}
//             index={index}>
//             {(provided, snapshot) => (
//               <div
//                 ref={provided.innerRef}
//                 {...provided.draggableProps}
//                 {...provided.dragHandleProps}
//                 style={getItemStyle(
//                   snapshot.isDragging,
//                   provided.draggableProps.style
//                 )}>
//                 <Card key={index} style={{ borderRadius: 0 }} onClick={() => item.param.completed ? null : this.openOp(item, el)}>
//                   <Card.Header style={{ borderRadius: 0 }}>
//                     {/*<Card.Header style={{ borderRadius: 0, background: (item.param && item.param.startWork) ? "rgb(10, 0, 208, 0.20)" : (item.param.completed ? "rgb(82, 195, 0)" : "") }}>*/}
//                     {item.param.operation.operationName}
//                   </Card.Header>
//                   <Card.Body>
//                     <div style={{ fontSize: 20 }}>
//                       {item.param.operation.operationName}
//                     </div>
//                     <div>
//                       {item._id}
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </div>
//             )}
//           </Draggable>
//         )
//       })}
//       {provided.placeholder}
//     </div>
//   )}
// </Droppable>
