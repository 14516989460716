/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
// import { Card } from 'react-bootstrap';
import { Table } from 'antd';
import { MainContext } from 'src/Context';
import { sortNumAlpObj } from "src/utils/functions/filterFunction"
// import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { allinOne } from "src/utils/functions/filterFunction"
import { pagination } from "src/utils/functions/specStyle"

// import { dateTimeFormatter } from "src/utils/functions/formater"
// import { workProgress } from "src/layouts/widgets/CustomLayout/index"
// import { prepareColumns } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"

const InfoCardOp = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const [state, setState] = useState({
    dataTable: [],
    columns: []
  })
  const { lang } = context.state
  const { style } = context
  // const [ result, setResult ] = useState()
  const [item, setItem] = useState(props?.item ?? [])

  const getData = async () => {
    const _ids = props?.item?.param?.operation?.tools?.map((el) => (el?._id))
    const obj = {
      type: "ToolsCatalog",
      ids: _ids
    }
    const result = await allinOne("getDocumentsById", obj)
    if (result.success) {
      return result?.r
    }
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      const _d = d.map((el, i) => ({
        ...el,
        // ...el.param,
        groupLabel: el?.param?.group?.label,
        name: el?.param?.name,
        key: i + 1
      }))
      // console.log(_d)
      setState({ dataTable: _d })
    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [item])


  const columns = [
    {
      title: "#",
      dataIndex: 'key',
      key: 'key',
      width: 30,
      align: "center",
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: lang?.["name"] ?? "name",
      dataIndex: 'name',
      key: 'name',
      // width: 30,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "name"),
    },
    {
      title: lang?.["group"] ?? "group",
      dataIndex: 'groupLabel',
      key: 'groupLabel',
      // width: 100,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "groupLabel"),
    }
  ]

  // console.log(props?.tabName)
  // const cols = props?.children?.tabs?.[props?.tabName]
  // console.log(props?.item.param)
  // const _param = props?.item.param
  // const _param = paramDestruct(result?.workorder?.param)
  // const columns = prepareColumns(cols, _param, lang)
  const title = lang?.[props?.tabName] ?? props?.tabName
  const _item = { name: item?.param?.operation?.operationName }
  const tableProps = {
    pagination
  }
  return (
    <div style={style.OperationManipulateMo}>
      {/*
      <TitleInfo styles={props?.children?.styles?.TitleInfo} dataProps={_item} />
      <Divider />
      */}
      <Table
        bordered
        style={{ padding: 0 }}
        className="table-striped-rows"
        columns={columns}
        dataSource={state?.dataTable}
        {...tableProps}
        // rowSelection={rowSelection}
        // pagination={pagination}
        />
    </div>
  )
}

export default InfoCardOp;
// <InfoColumns styles={props?.children?.styles?.InfoColumns} key={JSON.stringify(columns)} dataProps={columns} />
