import React from 'react';
import { MainContext } from 'src/Context.jsx';
import { Button, Input, Steps, Divider, Tabs, Image } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { catalog, ItemCatalog } from "src/utils/functions/filterFunction.js"
import { BasicMoInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import * as comp from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { LoadingOutlined } from '@ant-design/icons';
import { renderIconV2, calculateSum, allinOne, checkPromisStatus } from "src/utils/functions/filterFunction.js"
import { singleRow, stickyHeader } from "src/layouts/widgets/CustomLayout/index"
import { ProductInfo, ProductionProcedure, ValidationProcedure } from "./components/procedures"

const { TextArea } = Input;
class OperationManipulatePb extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { ...this.props, showDrawer: false }

  }

  componentDidMount() {
    this._isMounted = true
    this.getData()
  }

  getData = async () => {
    const { workOrderId } = this.state.dataProps.item
    const { authToken } = this.context.state
    const WorkOrder = await allinOne("WorkOrder", { id: workOrderId }, authToken)
    this.setState({ WorkOrder })
  }

  title = (item) => {
    // console.log(window.location.origin)
    return <h3>{item.selectedProduct.product}</h3>
  }

  body = (item, OPWorkflow) => {
    const { undefObjHandler, lang } = this.context.state
    const { WorkOrder } = this.state
    const onChange = (key) => {
      // this.setState({ stateTrigger: !stateTrigger })
    }
    // console.log(this.state)
    const items = [
      {
        label: "Basic info",
        children: ProductInfo(item, lang, WorkOrder),
        key: '1',
      },
      {
        label: "Operation flow",
        // children: this.ProductProductionProcedure(production, validation),
        children: ProductionProcedure(item, undefObjHandler, lang, OPWorkflow),
        key: '2',
        disabled: false
      },
      {
        label: "ValidationProcedure",
        // children: this.ProductProductionProcedure(production, validation),
        children: ValidationProcedure(item, undefObjHandler, lang),
        key: '3',
        disabled: false
      },
      // {
      //   label: "ValidationProcedure",
      //   // children: this.ProductProductionProcedure(production, validation),
      //   children: "ValidationProcedure",
      //   key: '4',
      //   disabled: false
      // },
    ]
    return (
      <Tabs
        size={"large"}
        // activeKey={tabHighlight}
        style={{ padding: 0 }}
        onChange={onChange}
        type="card"
        items={items}
        />
    )
  }

  render() {
    const { item, OPWorkflow } = this.state.dataProps
    return (
      <>
      {stickyHeader(this.title(item), this.body(item, OPWorkflow))}
      </>
  )
}
}

export default OperationManipulatePb;
