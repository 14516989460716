import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from '../../../Context';
import { Row, Col } from "react-bootstrap";

class Footer extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {}
  }

  handleButton = (x, path) => {
    this.props.history.push(path)
  }

  resolution = () => {
    return(
      <span>
        resolution: {'\xa0\xa0 \xa0\xa0'}
        width: <span style={{ fontWeight: "bold" }}>{window.screen.width}</span> {'\xa0\xa0 \xa0\xa0'}
        height: <span style={{ fontWeight: "bold" }}>{window.screen.height}</span>
    </span>
  )
}

render () {
  const { developVer, showHeaderFooter } = this.context.state
  const { style } = this.context

  return(
    <Row style={style.footer}>
      <Col sm={8}>
        {showHeaderFooter ?
          <span style={style.footer.span}>
            {'\xa0\xa0'}i4Cube - {new Date().getFullYear()}
            {'\xa0\xa0 \xa0\xa0'}
            {developVer.isDemo ? this.resolution() : ""}
          </span>
          : null}
        </Col>
        <Col style={style.footer.col} sm={4}>

        </Col>
      </Row>
    )
  }
}

export default withRouter(Footer);
