import React, { useState, useEffect, useRef } from 'react'

const App = (props) => {
  const mounted = useRef(true);
  const [children, setChildren] = useState()

  const f = async () => {
    const c = await import('src/layouts/widgets/Companies/i4c_V2/components/Wh/WhBoardView')
    const WhBoardView = c?.default
    setChildren(<WhBoardView children={props?.children} />)
  }
  useEffect(() => {
    f()
    return () => { mounted.current = false }
  }, [])

  return (<>{children}</>)
}

export default App;
