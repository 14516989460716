import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from '../../../Context';
import { Card, Row, Col } from "react-bootstrap";
import { DrawerCatalog } from 'src/layouts/widgets/Companies/i4c_client/components/Drawer/layouts'

class Dashboard extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleButton = (x, path) => {
    this.props.history.push({
      pathname: path,
      data: x
    })
  }

  callback = (e) => {
    const { toastify } = this.context
    const { lang } = this.context.state
    if (!e.type && !e.showModal) {
      toastify("warn", lang["pleaseSelectFirst"] ? lang["pleaseSelectFirst"] : "pleaseSelectFirst", { position: "top-center" })
    }
    // console.log(e)
    if (e && e.type) {
      switch (e.type) {
        // case "assets": this.setState({ definited: e.data }); break;
        // case "drawer": this.setState({ definited: false }, () => toastify("warn", lang["pleaseSelectFirst"] ? lang["pleaseSelectFirst"] : "pleaseSelectFirst", { position: "top-center" })); break;
        case "card": this.setState({ definited: e.data }, () => this.context.contextCall("definited", e.data)); break;
        default:

      }

    }
  }

  componentDidMount() {
    const { pages } = this.context.state.userConfig.pages
    const { contextCall } = this.context
    const obj = []
    pages.forEach(el => {
      if (el.activeStatus && el.published && !el.isProtected) {
        obj.push(el)
      }
    })
    this.setState({ obj })
    obj && obj.length <= 1 ? contextCall("singleCard", true) : contextCall("singleCard", false)
  }

  getMultiPage = () => {
    const { dataProps } = this.props
    const { definited } = this.state
    if (dataProps && dataProps.multiPage && !definited) {
      const _comp = (dataProps && dataProps.multiPage && dataProps.multiPage.comp) ? dataProps.multiPage : "Empty"
      // this.setState({ _comp })
      return _comp
    }else {
      return false
    }
  }

  render() {
    const { lang } = this.context.state
    const { style } = this.context
    const { pages } = this.context.state.userConfig.pages
    const { obj, showModal, props, definited, _comp } = this.state
    const { dataProps } = this.props
    return (
      <Row style={style.dashboard}>
        {/* eslint-disable-next-line */}
        {this.getMultiPage() ? <DrawerCatalog key={showModal} callback={this.callback} dataProps={{ showModal: true, comp: this.getMultiPage().comp, props: this.getMultiPage() }} /> : null}
        {pages.map((el, i) => {
          if (el.activeStatus && el.published && !el.isProtected) {
            if (obj && obj.length <= 1) {
              return (
                <div key={i} style={style.cardUserButton.singleCard}>
                  <button
                    style={style.cardUserButton.singleCard.button}
                    onClick={() => this.handleButton(el.widgets ? el.widgets[0][0][0].props : "", el.route)}>
                    {lang[el.title] ? lang[el.title] : el.title}
                  </button>
                </div>
              )
            } else {
              return (
                <Col md={4} key={i} style={{ minWidth: 400 }}>
                  <Card
                    style={style.cardUserButton.card}
                    onClick={() => this.handleButton(el.widgets ? el.widgets[0][0][0].props : "", el.route)}>
                    <span>{lang[el.title] ? lang[el.title] : el.title}</span>
                  </Card>
                </Col>
              )
            }
          } else if (el.activeStatus && el.isProtected && !el.published) { }
        })}
      </Row>
    )
  }
}

export default withRouter(Dashboard);
