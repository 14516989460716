import socketIOClient from "socket.io-client";

export default class Socket {
  socket;
  connect(endpoint, token) {
    this.socket = socketIOClient(endpoint, {
      query: "token=" + token,
      "reconnection": true,
      "reconnectionDelay": 1000,
      "reconnectionAttempts": 10,
      "timeout": false
     });
    // this.socket.on("cui_notification", (data) => console.log(data));
  }

  on(event, fun) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      this.socket.on(event, fun);
      resolve();
    });

  }

  once(event, fun) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      this.socket.once(event, fun);
      resolve();
    });
  }

  removeAllListeners(event, fun) {
    this.socket.removeAllListeners(event, fun)
  }

  removeListener(event, fun) {
    this.socket.removeListener(event, fun)
  }


  emit(room, fun) {
    if (!this.socket) {
      return ("No socket connection.");
    }
    this.socket.emit(room, fun);
  }

  off(event) {
    this.socket.off(event)
  }


  disconnect() {
    if (this.socket)
    this.socket.disconnect(() => {
      this.socket = null;
    });
  }
}
