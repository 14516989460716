import React, { useContext, useState, useEffect, useRef } from 'react'
import { Tabs } from 'antd';
// import { allinOne, checkPromisStatus, renderIconV2, calculateSum, allinPut, msg } from "src/utils/functions/filterFunction"
import { MainContext } from 'src/Context';
// import { content, setLocal, isLocal } from "src/utils/functions/core"
// import { spinning } from "src/layouts/widgets/CustomLayout/index"
import {
  OperationManipulateOp,
  OperationNote,
  Material,
  TechnicalInstructions,
  Coworkers,
  Tools,
  ProductFlow,
  Summary,
  Events,
  Palletization,
  Notes
 } from "src/layouts/widgets/Companies/i4c_V2/components/layouts"

const App = (props) => {
  // console.log(props)
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang } = context.state
  const [activeKey, setActiveKey] = useState("1")
  // const [item, setItem] = useState(props?.item)
  const [state, setState] = useState({
    props
  })
  const updateItem = (e) => {
    // in state?.props?.dataProps status is prev
    const board = state?.props?.dataProps?.board
    //  prev status
    const p = board.find((f) => f?.id === state?.props?.dataProps?.status.id)
    const prevIndex = board.findIndex((f) => f?.id === state?.props?.dataProps?.status.id)
    // new prev items
    const prev = p?.items.filter((f) => f?._id !== e?.props?._id)
    board[prevIndex].items = prev
    //  new status
    const n = board.find((f) => f?.id === e?.props?.param?.status?._id)
    const nextIndex = board.findIndex((f) => f?.id === e?.props?.param?.status?._id)
    const newItem = e?.props
    const newItems = [...n?.items, newItem]
    board[nextIndex].items = newItems
    setState({
      props: {
        dataProps: {
          ...state?.props?.dataProps,
          board,
          item: e?.props
        }
      }
    })
    setActiveKey(e?.activeKey)
  }

  const updateItemNote = (e) => {
    setActiveKey(e?.activeKey)
  }
  const updateItemMaterial = (e) => {
    setActiveKey(e?.activeKey)
  }
  const updateItemTechnicalInstructions = (e) => {
    setActiveKey(e?.activeKey)
  }
  const updateItemTools = (e) => {
    setActiveKey(e?.activeKey)
  }
  const updateItemCoworkers = (e) => {
    setActiveKey(e?.activeKey)
  }

  useEffect(() => {
    return () => { mounted.current = false }
  }, [])

  const onChange = (activeKey) => {
    setActiveKey(activeKey)
  };

  const updateItemSummary = (activeKey) => {
    setActiveKey(activeKey)
  };

  const updateItemProduct = (activeKey) => {
    setActiveKey(activeKey)
  };

  const updateItemEvents = (activeKey) => {
    setActiveKey(activeKey)
  };


  const items = [
    {
      key: '1',
      name: "basicInfo",
      label: lang?.["basicInfo"] ?? "basicInfo",
      children: <OperationManipulateOp key={JSON.stringify(state?.props?.dataProps)} tabName={"basicInfo"} operationM={updateItem} {...state?.props?.dataProps} />,
    },
    {
      key: '2',
      name: "feedback",
      label: lang?.["feedback"] ?? "feedback",
      children: <OperationNote key={JSON.stringify({ ...state?.props?.dataProps, ...activeKey })} tabName={"feedback"} operationN={updateItemNote} {...state?.props?.dataProps} />,
      // children: <OperationNote key={JSON.stringify(state?.props?.dataProps?.board)} tabName={"feedback"} operationN={updateItemNote} {...state?.props?.dataProps} />,
    },
    {
      key: '3',
      name: "material",
      label: lang?.["Material"] ?? "Material",
      children: <Material key={JSON.stringify({ ...state?.props?.dataProps, ...activeKey })} tabName={"material"} operationN={updateItemMaterial} {...state?.props?.dataProps} />,
    },
    {
      key: '4',
      name: "coworkers",
      label: lang?.["operators"] ?? "operators",
      children: <Coworkers tabName={"coworkers"} operationN={updateItemCoworkers} {...state?.props?.dataProps} />,
    },
    {
      key: '5',
      name: "tools",
      label: lang?.["tools"] ?? "tools",
      children: <Tools tabName={"tools"} operationN={updateItemTools} {...state?.props?.dataProps} />,
    },
    {
      key: '6',
      name: "technicalInstructions",
      label: lang?.["documentation"] ?? "documentation",
      children: <TechnicalInstructions tabName={"technicalInstructions"} operationN={updateItemTechnicalInstructions} {...state?.props?.dataProps} />,
    },
    {
      key: '7',
      name: "product",
      label: lang?.["product"] ?? "product",
      children: <ProductFlow tabName={"product"} operationN={updateItemProduct} {...state?.props?.dataProps} />,
    },
    {
      key: '8',
      name: "events",
      label: lang?.["events"] ?? "events",
      children: <Events tabName={"events"} operationN={updateItemEvents} {...state?.props?.dataProps} />,
    },
    {
      key: '9',
      name: "palletization",
      label: lang?.["palletization"] ?? "palletization",
      children: <Palletization />,
    },
    {
      key: '10',
      name: "notes",
      label: lang?.["notes"] ?? "notes",
      children: <Notes />,
    },
    {
      key: '11',
      name: "summary",
      label: lang?.["summary"] ?? "summary",
      children: <Summary tabName={"summary"} operationN={updateItemSummary} {...state?.props?.dataProps} />,
    },
  ];

  const _tabs = Object.keys(props?.dataProps?.children?.tabs)
  const _items = items.filter((f) => _tabs.find((fi) => fi === f?.name) === f?.name)

  return (
    <div style={{ padding: 20 }}>
      <Tabs
        // key={JSON.stringify(state?.props?.dataProps)}
        type="card"
        destroyInactiveTabPane={true}
        onChange={onChange}
        activeKey={activeKey}
        // type="editable-card"
        // onEdit={onEdit}
        items={_items}
      />
    </div>
  )
}
export default App;
