import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from '../../../Context';
import { Table } from "react-bootstrap";
import { Button } from 'antd';
import { dateFormat } from "../../../utils/functions/dateFormat";

class About extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {}
  }

  userLicences = () => {
    const { userConfig, toTimestamp } = this.context.state
    try {
      const licences = {
        daysRemain: userConfig.license.validInDays,
        expiryDate: dateFormat(toTimestamp(userConfig.license.expiryDate))
      }
      return licences
    } catch (e) { } finally { }
    return null;
  }


  render() {
    const { style, lang, company, userConfig, socketInfo } = this.context.state
    const { newVerson, firstName, lastName, email } = this.context.state.userConfig.user
    const buttonStyle = {
      width: 150,
      height: 50,
      fontSize: 20,
      margin: "0px 0px 10px 85px"
    }
    return (
      <div style={style.about}>
        <Button
          ghost={false}
          type="primary"
          danger={false}
          style={{ ...buttonStyle }}
          onClick={() => this.props.history.push({ pathname: "/dashboard" })}>{lang?.["Home"] ?? "Home"}
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>{lang["appVersion"] ? lang["appVersion"] : "App version"}</th>
              <th>{lang["dateOfLastReleased"] ? lang["dateOfLastReleased"] : "date of last released"}</th>
              <th>{lang["newVersion"] ? lang["newVersion"] : "New version"}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{company}</td>
              <td>{socketInfo && socketInfo.appVersion}</td>
              <td>{socketInfo && socketInfo.releseDateApp}</td>
              <td>{newVerson ? newVerson : "You have the latest version"}</td>
            </tr>
          </tbody>
        </Table>
        <h4>{lang["App license"]}</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{lang?.["licenceType"] ?? "licenceType"}</th>
              <th>{lang["DaysRemain"] ? lang["DaysRemain"] : "days remain"}</th>
              <th>{lang["ExpirationDate"] ? lang["ExpirationDate"] : "expiration date"}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userConfig.license.productEdition}</td>
              <td>{!this.userLicences() ? '*** please check backend ;) ***' : this.userLicences().daysRemain}</td>
              <td>{!this.userLicences() ? '*** please check backend ;) ***' : this.userLicences().expiryDate}</td>
            </tr>
          </tbody>
        </Table>
        <h4>{lang["User details"]}</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>{lang["firstName"]}</th>
              <th>{lang["lastName"]}</th>
              <th>{lang["email"]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{company}</td>
              <td>{firstName}</td>
              <td>{lastName}</td>
              <td>{email}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
}

export default withRouter(About);
