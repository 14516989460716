import React from 'react'
import { withRouter } from "react-router-dom";
import { SyncOutlined } from '@ant-design/icons';

class SpinnerCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    const { data } = this.props;
    return (
      <div style={{ position: "absolute", left: 0, top: 0, zIndex: 99999, background: "rgba(255, 255, 255, 0)", width: "100%", height: "100%" }}>
        <div className="container-fluid overRideLayer">
          <div className="position-fixed top-50 start-50 bottom-50 translate-middle spinnerCustom">
            <span>
              <SyncOutlined style={{ fontSize: data.sizeSpinner }}spin/>
              {'\xa0'}
            </span>
            <span style={{ fontSize: data.sizeText }}>
              {data.text}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SpinnerCustom);
