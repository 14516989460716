/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext, useEffect } from 'react'
import { MainContext } from 'src/Context';
import { allinOne } from "src/utils/functions/filterFunction"
// import { UserSwitchOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { content } from "src/utils/functions/core"
import { InterruptContent } from "src/layouts/widgets/Companies/i4c_V2/components/layouts"
import { spinning } from "src/layouts/widgets/CustomLayout";
// const { v4: uuidv4 } = require('uuid');

const App = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const [isLoading, setIsLoading] = useState()
  const { lang, assetId } = context.state
  const sliderProps = {
    // const sliderProps = props?.children?.sliderProps ?? {
    token: {
      // colorPrimary: "red"
      lineHeight: 3,
      fontSize: 18
    },
    components: {
      Slider: {
        controlSize: 1,
        railSize: 15,
        dotSize: 25,
        handleSize: 35,
        handleSizeHover: 25,
      },
    }
  }
  const eventConfigType = props?.children?.eventConfigType
  console.log(eventConfigType)
  const debug = props?.children?.debug
  const drawerProps = props?.children?.drawerProps ?? {
    headerStyle: { display: "none" },
    height: "80%",
    placement: "bottom"
  }
  // const function = props?.children?.function
  const cardStyle = props?.children?.cardStyle ?? {
    position: "relative",
    margin: "auto",
    top: "60px",
    left: "50%",
    transform: "translate(-50%,-50%)"
  }
  const listStyle = props?.children?.listStyle ?? {
    cursor: "pointer"
  }
  const buttonsStyle = props?.children?.buttonsStyle ?? {
    margin: "auto",
    fontSize: 30,
    minHeight: 150,
    minWeight: 200,
    background: "#acf15e",
    padding: 10
  }
  const extraButtonsStyle = props?.children?.extraButtonsStyle ?? {
    background: "#de9595",
    fontSize: 30,
    fontWeight: "bold",
  }
  const [state, setState] = useState({
    // ...props,
    sliderProps,
    drawerProps,
    asset: {
      ...props?.dataProps?.props,
      assets: [],
    },
    drawer: false,
    buttons: []
  })

  // console.log(props?.children?.function?.get)
  const getData = async () => {
    const { auth_token } = content()
    if (mounted.current) {
      setIsLoading(true)
      const result = await allinOne("getEventConfigUserInteruptsWithAsset", { id: eventConfigType, assetId })
      // console.log(result)
      if (debug) {
        console.log({ result })
      }
      if (result.success) {
        return result
      }
    }
  }

  const f = async () => {
    const d = await getData()
    if (assetId) {
      if (d !== "undefined") {
        // console.log(d)
        const a = d?.r.map((el, i) => ({
          name: el.subject,//limitText(el.subject, 20),
          props: {
            style: buttonsStyle,
          },
          value: { ...el, type: el._type }
        }))
        const b = [
          {
            name: lang?.["finish"] ?? "finish",
            props: {
              style: {
                ...extraButtonsStyle,
                background: "#e7e7e7",
                color: "rgba(0, 0, 0, 0.17)"
              },
            },
            value: { type: "finish" }
          },
          {
            name: lang?.["close"] ?? "close",
            props: {
              style: extraButtonsStyle,
            },
            value: { type: "close" }
          },
          {
            name: lang?.["deleteMarks"] ?? "deleteMarks",
            props: {
              style: {
                ...extraButtonsStyle,
                background: "#e7e7e7"
              },
            },
            value: { type: "deleteMarks" }
          }
        ]
        const buttons = [...a]
        setState({ ...state, buttons, extraButtons: b, asset: d?.asset })
        setIsLoading(false)
      }
    } else {
      setState({
        msgWarn: lang?.["FirstSelectAsset"] ?? "FirstSelectAsset"
      })
    }
  }

  useEffect(() => {
    f()
    return () => { mounted.current = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hanleDrawer = (e, content) => {
    setState({ ...state, drawer: e, event: content })
  }

  const callbackChart = (e) => {
    mounted.current = true
    setState({ ...state, buttons: [] })
    setTimeout(() => {
      f()
    }, 2000)
    // setState({ ...state, drawer, timestamp: e })
  }
  // console.log(state)
  return (
    <>
      {state?.msgWarn ? <div style={{ fontSize: 25, fontWeight: "bold", }}>{state?.msgWarn}</div> :
        <>
          <div>
            {(state?.buttons && state?.buttons?.length > 0) ?
              <InterruptContent key={JSON.stringify(state)} callbackChart={callbackChart} {...state} /> :
              <div>{spinning(50)}</div>}
          </div>
        </>
      }
    </>
  )
}
export default App;
