import React, { useContext, useState, useEffect, useRef } from 'react'
// import { dateTimeFormatter } from "src/utils/functions/core"
import { MainContext } from 'src/Context';
import Spinner from "src/layouts/components/spinner"

const App = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang, assetId } = context.state
  const [ events, setEvents ] = useState(<Spinner />)
  const { style } = context

  const f = async() => {
    const e = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/Events")
    const Events = e?.default
    setEvents(<Events item={props?.item}/>)
  }

  useEffect(() => {
    f()
  }, [])
  return (
    <div style={style?.OperationManipulateMo}>
      {events}
    </div>
  )
}

export default App;
