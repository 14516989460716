import React from 'react';
import { MainContext } from 'src/Context';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import { OpItems } from 'src/layouts/widgets/Companies/i4c_client/components/layouts'
import { DrawerCatalog, OperationManipulate } from 'src/layouts/widgets/Companies/i4c_client/components/Drawer/layouts'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class MultiCol extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props){
    super(props)
    this.state = {
      ...this.props.dataProps,
      showModal: false,
      operation: (this.props.dataProps && this.props.dataProps.boards2) ? this.props.dataProps.boards2.Operation : [],
      steps: this.props.dataProps.OPWorkflows[0].param.steps,
    }
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount(){
    this._isMounted = true;
    const { operation, steps } = this.state
    let _board = []
    steps.forEach((el, i) => {
      const findStatusOp = operation.filter((f, j) => (f.param.status && f.param.status._id) === el.id)
      if (findStatusOp) { _board.push({ ...el, items: findStatusOp }) }else { _board.push(el) }
    });
    this.setState({ _board })




    // const newArray = [];
    // for (var i = 0; i < assets.assets.length; i++) {
    //   if (user.userConfig.favoriteAssets[i]) {
    //     newArray.push({
    //       ...assets.assets[i],
    //       favorites: true
    //     })
    //   }else {
    //     newArray.push({
    //       ...assets.assets[i],
    //     })
    //   }
    // }
    //
    // console.log(newArray)


    // const { count } = this.state
    // this.setState({ count: count + 1})
    // const { dataProps } = this.props
    // if (dataProps && dataProps.OPWorkflows && dataProps.OPWorkflows.length > 0) {
    //   // console.log(dataProps)
    //
    //   this.setState({
    //     ...dataProps,
    //     wf: dataProps.OPWorkflows[0]
    //   })
    // }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      OPWorkflows: [],
      Operation: [],
    })
  }

  callback = (e) => {
    console.log(e)
    this.setState({
      showModal: false
    })
    // this.props.callback({ type: "drawer", value: {
    //   showModal: false,
    //   indexKey: Math.random()
    // } })
  }

  openOp = () => {

    this.setState({ showModal: true, comp: "OperationManipulate" })
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // getList = (id) => this.state[this.id2List[id]];

  move = (source, destination, droppableSource, droppableDestination) => {
    // console.log({source, destination, droppableSource, droppableDestination})
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  setDrag = (items) => {
    const { queryPost, authToken } = this.context.state
    queryPost({
      url: "/updateCatalogItemArr",
      token: authToken,
      type: "Operation",
      body: items
    }).then(res => {
      if (this._isMounted && res) {
        console.log(res)
      }
    })
    // const { socketConn } = this.context.state
    // socketConn.emit("triggerState", {
    //   data: "whatever to BE socket"
    // })
  }

  onDragEnd = (result) => {
    const { source, destination } = result;
    const { _board, operation } = this.state
    if (!destination) { return }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    // console.log(operation)
    if (sInd === dInd) {
      const items = this.reorder(_board[sInd].items, source.index, destination.index);
      const newBoard = [..._board];
      const reorder = items.map((el, i) => {
        return ( { ...el, param: { ...el.param, status: { ...el.param.status, _i: i }},  }) })
        newBoard[sInd].items = reorder;
        if (this._isMounted) { this.setState({ _board: newBoard }) }
        const { queryPost, authToken } = this.context.state
        this.setDrag(reorder)
      } else {
        const result = this.move(_board[sInd].items, _board[dInd].items, source, destination);
        let newBoard = [..._board];
        if (newBoard[sInd].allowBackStep === true || sInd <= dInd) {
          newBoard[sInd].items = result[sInd].map((el, i) => { return ({ ...el, param: { ...el.param, status: { ...el.param.status, _id: _board[sInd].id }} }) });
          newBoard[dInd].items = result[dInd].map((el, i) => { return ({ ...el, param: { ...el.param, status: { ...el.param.status, _id: _board[dInd].id }} }) });
          if (this._isMounted) { this.setState({ _board: newBoard }) }
          const { queryPost, authToken } = this.context.state
          let _items = []
          newBoard.forEach((el, i) => {
            if (el.items.length > 0) {
              el.items.forEach((item, j) => {
                _items.push(item)
              })
            }
          })
          this.setDrag(_items)
        }
      }
    }

    reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    render () {
      // let _Operation = []
      const { OPWorkflows, Operation, indexKey } = this.state.boards ? this.state.boards : this.state
      const { showModal, _board } = this.state
      // console.log(this.state)
      // console.log(Operation)
      const wo = OPWorkflows ? OPWorkflows.find((el) => el.param.op && el.param.isDefault && el.param.activeStatus ) : false
      const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        // userSelect: "none",
        // padding: 16,
        marginBottom: 5,
        // width: 200,
        // margin: `0 0 ${8}px 0`,
        // borderStyle: "solid",
        // borderWidth: "1px",
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        // styles we need to apply on draggables
        ...draggableStyle
      });
      const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "",
        // padding: 8,
        // marginRight: 8,
        width: "auto",
        // minHeight: 690
      });


      if (_board && _board.length > 0) {
        // console.log(_board)
        // Operation.map((el) => console.log(el.param.status))
        return(
          <Row>
            <DragDropContext onDragEnd={this.onDragEnd}>
              {_board.map((el, ind) => {
                const lengthInd = _board.length - 1
                return(
                  <Col key={ind} style={{ color: "", padding: 0, borderRight: ind < lengthInd && "1px solid #e7e7e7" }}>
                    <Card style={{ border: 0 }}>
                      <center>
                        <Card.Header style={{ border: 0 }}>
                          {el ? el.name : "props missing !!!"}
                        </Card.Header>
                      </center>
                    </Card>
                    <Droppable key={ind} droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}>
                          {el.items.map((item, index) => {
                            // console.log(item)
                            return(
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}>
                                    <Card key={index} onClick={() => this.openOp()}>
                                      <Card.Header>
                                        {item.param.operation.operationName}
                                      </Card.Header>
                                      <Card.Body>
                                        <div style={{ fontSize: 20 }}>
                                          {item.param.operation.operationName}
                                        </div>
                                        <div>
                                          {item._id}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                )
              })}
            </DragDropContext>
            <DrawerCatalog callback={this.callback} dataProps={{ ...this.state }}/>
          </Row>
        )
      }else return <div>loading ...</div>
      }
    }

    export default MultiCol;









    // <Draggable
    //   key={op._id}
    //   draggableId={op._id}
    //   index={k}>
    //   {console.log(op)}
    //   {(provided, snapshot) => (
    //     <div
    //       ref={provided.innerRef}
    //       {...provided.draggableProps}
    //       {...provided.dragHandleProps}>
    //       <Card style={{ border: "1px solid rgba(0, 0, 0, 0.30)", borderRadius: 0 }}>
    //         člklkč
    //       </Card>
    //     </div>
    //   )}
    // </Draggable>
    // <OpItems key={Math.random()} dataProps={{ findStatusOp }}/>
